import React, { useCallback, useState } from 'react';
import { RxDocument } from 'rxdb';
import { useTranslation } from 'react-i18next';

import { Block } from '../../../components/Block';
import { Modal } from '../../../components/Modal';
import { Col, Row } from '../../../components/Grid';
import { Heading } from '../../../components/Heading';
import { Button } from '../../../components/Button';
import { LessonDocType } from '../../../../database/schemas/lesson';

interface Props {
  lesson: RxDocument<LessonDocType> | null;
  onClose: () => void;
}

export function DeleteLesson(props: Props) {
  const { t } = useTranslation();
  const { lesson, onClose } = props;
  const [loading, setLoading] = useState(false);

  const onConfirm = useCallback(async () => {
    setLoading(true);
    await lesson?.remove();
    setLoading(false);
    onClose();
  }, [lesson?._data?._rev]);

  if (!lesson) return null;

  return (
    <Modal
      width={20 * 16}
      isOpen
      onBackgroundClick={onClose}
      onEscapeKeydown={onClose}
    >
      <Row justifyContent="center">
        <Col xs={12}>
          <Heading level={3}>
            <Block textCenter>
              {t('confirm.delete', { name: lesson?._data?.title })}
            </Block>
          </Heading>
        </Col>
        <Col xs={12} gutterY={1.5}>
          <Row justifyContent="center">
            <Col>
              <Button border onClick={onClose}>
                {t('button.cancel')}
              </Button>
            </Col>
            <Col>
              <Button red $loading={loading} onClick={onConfirm}>
                {t('button.delete')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
}
