import { replicateRxCollection } from 'rxdb/plugins/replication';
import axios from 'axios';

import { MediaDocType } from './schema';
import { api } from '../../../services/api';
import { ReplicationPullHandlerResult } from 'rxdb/dist/types/types/plugins/replication';
import { blobToBase64 } from '../../../utils/file-manipulatiion';

type Checkpoint = Pick<MediaDocType, 'updated_at'>;

export async function sharedMediaReplication(collection) {
  return replicateRxCollection<MediaDocType, Checkpoint>({
    collection,
    replicationIdentifier: `${process.env.REACT_APP_API_URL}/media-shared`,
    autoStart: true,
    live: true,
    deletedField: 'deleted_at',
    pull: {
      initialCheckpoint: {
        updated_at: new Date(0).toISOString(),
      },
      batchSize: 1,
      async handler(
        lastCheckpoint,
        batchSize,
      ): Promise<ReplicationPullHandlerResult<MediaDocType, Checkpoint>> {
        const minTimestamp = lastCheckpoint
          ? lastCheckpoint?.updated_at || new Date(0)
          : new Date(0);

        const response = await api.get(`/file/sync`, {
          params: {
            last_checkpoint: minTimestamp,
            batchSize,
            show_shared_entities: true,
          },
        });

        const document = response.data[0];

        if (!document) {
          return {
            documents: [],
            checkpoint: lastCheckpoint,
          };
        }

        const checkpoint = {
          updated_at: document.updated_at,
        };

        try {
          const file = await axios.get(document.path, {
            responseType: 'blob',
          });

          const base64Data = await blobToBase64(file.data);

          const documents = [
            {
              ...document,
              path: document.path,
              type: document.path.split(/[#?]/)[0].split('.').pop().trim(),
              data: base64Data,
            },
          ];

          return {
            documents,
            checkpoint,
          };
        } catch (error) {
          return {
            documents: [
              {
                ...document,
                deleted_at: new Date().toISOString(),
              },
            ],
            checkpoint,
          };
        }
      },
      modifier: document => ({
        ...document,
        shared: true,
      }),
    },
  });
}
