import { replicateRxCollection } from 'rxdb/plugins/replication';

import { FlashCardDocType } from './schema';
import { api } from '../../../services/api';
import { FlashCardDto } from '../../../types/FlashCard';
import { ReplicationPullHandlerResult } from 'rxdb/dist/types/types/plugins/replication';

type Checkpoint = Pick<FlashCardDocType, 'id' | 'updated_at'>;

export async function flashCardReplication(collection) {
  return replicateRxCollection<FlashCardDocType, Checkpoint>({
    collection,
    replicationIdentifier: `${process.env.REACT_APP_API_URL}/flashcard`,
    autoStart: true,
    live: true,
    deletedField: 'deleted_at',
    push: {
      batchSize: 1000,
      async handler(docs) {
        const conflicts = await api.post(`/flashcard/sync/`, docs);
        return conflicts.data;
      },
      modifier: ({ question_value, answer_value, shared, ...doc }) => {
        if (shared) return null;

        return {
          ...doc,
          question_value:
            typeof question_value === 'object'
              ? JSON.stringify(question_value)
              : question_value,
          answer_value:
            typeof answer_value === 'object'
              ? JSON.stringify(answer_value)
              : answer_value,
        };
      },
    },
    pull: {
      batchSize: 1000,
      async handler(
        lastCheckpoint,
        batchSize,
      ): Promise<ReplicationPullHandlerResult<FlashCardDto, Checkpoint>> {
        const minTimestamp = lastCheckpoint
          ? lastCheckpoint?.updated_at || new Date(0)
          : new Date(0);

        const response = await api.get(`/flashcard/sync`, {
          params: {
            last_checkpoint: minTimestamp,
            batchSize,
          },
        });

        const documents = response.data || [];
        const documentsLength = documents.length;
        const lastDocument = documents[documentsLength - 1];

        const checkpoint = documentsLength
          ? {
              id: lastDocument.id,
              updated_at: lastDocument.updated_at,
            }
          : lastCheckpoint;

        return {
          documents,
          checkpoint,
        };
      },
      modifier: doc => {
        let question_value = doc.question_value;
        let answer_value = doc.answer_value;

        try {
          question_value = JSON.parse(question_value);
        } catch (error) {}

        try {
          answer_value = JSON.parse(answer_value);
        } catch (error) {}

        return {
          ...doc,
          question_value,
          answer_value,
          shared: false,
        };
      },
    },
  });
}
