import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { media } from '../../../../styles/media';
import { Col, Row } from '../../../components/Grid';
import { Icon } from '../../../components/Icon';
import { Container } from '../../../components/Container';
import { Heading } from '../../../components/Heading';
import { BackButton } from '../../../components/BackButton';
import { Header } from '../../../components/Header';
import { Link } from '../../../components/Link';
import { Logo } from '../../../components/Logo';

export function AccountHeader() {
  const { t } = useTranslation();

  return (
    <Header>
      <Container>
        <Row
          justifyContent="space-between"
          alignItems="center"
          display={{ xs: 'flex', md: 'none' }}
        >
          <Col xs="3rem">
            <BackButton to=".." block withPadding>
              <Icon name="chevron-left-1" />
            </BackButton>
          </Col>
          <Col>
            <Heading level={3}>{t('title.myAccount')}</Heading>
          </Col>
          <Col xs="3rem"></Col>
        </Row>
        <Row display={{ xs: 'none', md: 'block' }}>
          <Col xs={12}>
            <Link to="..">
              <Logo />
            </Link>
          </Col>
        </Row>
      </Container>
    </Header>
  );
}

const Wrapper = styled.header`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  background: var(--white);
  padding: 1rem 0;
  box-shadow: 0 2px 4px 0 rgba(var(--black), 0.05);
  color: var(--grey-900);

  ${media.md} {
    padding: 1.5rem 0;
  }
`;
