import { useField } from 'formik';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';

import { headingStyles } from '../../../../../components/Heading';
import { media } from '../../../../../../styles/media';

export const TextAreaField = props => {
  const { label, name } = props;
  const [field, meta, helpers] = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef?.current) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 250);
    }
  }, []);

  return (
    <Textarea
      {...field}
      {...props}
      invalid={!!(meta.touched && meta.error)}
      level={3}
      ref={inputRef}
    />
  );
};

const Textarea = styled.textarea`
  ${headingStyles};
  padding: 1rem;
  border: none;
  width: 100%;
  height: 100%;
  color: var(--grey-500);
  font-weight: 600;
  resize: none;

  ${media.md} {
    padding: 1.5rem;
  }
`;
