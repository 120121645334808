import React from 'react';
import raw from 'raw.macro';
import styled, { css } from 'styled-components/macro';
import { ifProp, prop, switchProp } from 'styled-tools';

export type IconName =
  | 'pencil-8'
  | 'download'
  | 'cloud-download'
  | 'trash-4'
  | 'plus-1'
  | 'plus-solid'
  | 'settings-1'
  | 'chevron-left-1'
  | 'chevron-down'
  | 'chevron-right-1'
  | 'cross'
  | 'checkmark'
  | 'question-1'
  | 'stack'
  | 'microphone'
  | 'document-text'
  | 'photo'
  | 'web'
  | 'spelling-1'
  | 'choice-3'
  | 'share-1'
  | 'play'
  | 'play-solid'
  | 'rotate-device'
  | 'stop'
  | 'pause'
  | 'upload'
  | 'cloud-upload'
  | 'cloud-1'
  | 'copy'
  | 'dots'
  | 'cloud-upload-solid'
  | 'microphone-solid'
  | 'language-1'
  | 'person-2';

interface Props {
  name: IconName;
  $block?: boolean;
  $size?: number;
}

const fillIcons: IconName[] = [
  'spelling-1',
  'choice-3',
  'play-solid',
  'dots',
  'cloud-upload-solid',
  'microphone-solid',
];

export const Icon = (props: Props) => {
  const { name, $block } = props;

  const svg = raw(`./assets/${name}.svg`);
  const type = fillIcons.indexOf(name) !== -1 ? 'fill' : 'stroke';

  return (
    <IconWrapper
      type={type}
      $block={$block}
      {...props}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  );
};

interface WrapperProps {
  readonly type: string;
  $size?: number;
}

export const IconWrapper = styled.span<WrapperProps>`
  line-height: 0;
  vertical-align: top;
  display: ${ifProp('$block', 'block', 'inline-block')};

  svg {
    width: ${prop('$size', 24)}px;
    height: ${prop('$size', 24)}px;

    ${switchProp('type', {
      fill: css`
        fill: currentColor;
      `,
      stroke: css`
        stroke: currentColor;
      `,
    })}
  }
`;
