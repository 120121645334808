import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ifProp } from 'styled-tools';

import { Button as ButtonOrigin } from '../Button';
import { Col, Row } from '../Grid';
import { Icon } from '../Icon';

interface Props {
  disabled?: boolean;
  correct?: boolean;
  option: string;
}

export function AnswerOption(props: Props) {
  const { disabled, correct, option } = props;

  return (
    <Button block disabled={disabled}>
      <Row gutter={0.5}>
        <Col gutter={0.5}>
          <Circle correct={correct}>
            <Icon name="checkmark" />
          </Circle>
        </Col>
        <Col gutter={0.5} xs="auto">
          {option}
        </Col>
      </Row>
    </Button>
  );
}

const Button = styled(ButtonOrigin)<{ disabled?: boolean }>`
  white-space: normal;
  font-weight: 400;
  text-align: left;
  display: block;
  background: rgba(var(--blue-200-rgb), 0.1);
  color: var(--blue-200);

  ${ifProp(
    'disabled',
    css`
      pointer-events: none;
    `,
  )};
`;

export const Circle = styled.div<{ correct?: boolean }>`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 2px solid var(--blue-200);

  svg {
    height: 1.25rem;
    width: 1.25rem;
    display: ${ifProp('correct', 'block', 'none')};
  }
`;
