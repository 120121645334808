import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '../../../components/Modal';
import { Col, Row } from '../../../components/Grid';
import { Heading } from '../../../components/Heading';
import { LessonForm, LessonValues } from '../LessonForm';
import { LessonDocType } from '../../../../database/schemas/lesson';
import { RxDocument } from 'rxdb';
import { Block } from '../../../components/Block';

interface Props {
  lesson: RxDocument<LessonDocType> | null;
  onClose: () => void;
}

export function EditLesson(props: Props) {
  const { lesson, onClose } = props;
  const { t } = useTranslation();

  if (!lesson) return null;

  const initialValues: LessonValues = {
    id: lesson.id,
    title: lesson?.title || '',
  };

  const onSubmit = async (values: LessonValues) => {
    try {
      await lesson.update({
        $set: values,
      });
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal isOpen onBackgroundClick={onClose} onEscapeKeydown={onClose}>
      <Row justifyContent="center">
        <Col xs={12}>
          <Block textCenter>
            <Heading level={3}>{t('title.editLesson')}</Heading>
          </Block>
        </Col>
        <Col xs={12} gutterY={2}>
          <LessonForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            onCancel={onClose}
          />
        </Col>
      </Row>
    </Modal>
  );
}
