import React, { Component } from 'react';
import Star from './star';

interface Props {
  rating: number;
  numberOfStars: number;
  starRatedColor: string;
  starEmptyColor: string;
  starDimension: string;
  starSpacing: string;
  gradientPathName: string;
  ignoreInlineStyles: boolean;
  svgIconPath: string;
  svgIconViewBox: string;
  name?: string;
}

const defaultProps = {
  rating: 0,
  typeOfWidget: 'Star',
  numberOfStars: 5,
  starRatedColor: 'var(--yellow-100)',
  starEmptyColor: 'var(--grey-200)',
  starDimension: '20px',
  starSpacing: '1px',
  gradientPathName: '',
  ignoreInlineStyles: false,
  svgIconPath: 'm25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z',
  svgIconViewBox: '0 0 51 48',
};

class StarRatings extends Component<Props & typeof defaultProps> {
  fillId = `starGrad${Math.random().toFixed(15).slice(2)}`;

  static defaultProps = defaultProps;

  get starRatingsStyle() {
    const starRatingsStyle = {
      position: 'relative',
      boxSizing: 'border-box',
      lineHeight: 0,
      height: 20,
      display: 'block',
    };

    return this.props.ignoreInlineStyles ? {} : starRatingsStyle;
  }

  get starGradientStyle() {
    const starGradientStyle = {
      position: 'absolute',
      zIndex: '0',
      width: '0',
      height: '0',
      visibility: 'hidden',
    };
    return this.props.ignoreInlineStyles ? {} : starGradientStyle;
  }

  stopColorStyle(color) {
    const stopColorStyle = {
      stopColor: color,
      stopOpacity: '1',
    };
    return this.props.ignoreInlineStyles ? {} : stopColorStyle;
  }

  get titleText() {
    const { typeOfWidget, rating: selectedRating } = this.props;
    let formattedRating = parseFloat(selectedRating.toFixed(2)).toString();
    if (Number.isInteger(selectedRating)) {
      formattedRating = String(selectedRating);
    }
    let starText = `${typeOfWidget}s`;
    if (formattedRating === '1') {
      starText = typeOfWidget;
    }
    return `${formattedRating} ${starText}`;
  }

  get offsetValue() {
    const rating = this.props.rating;
    const ratingIsInteger = Number.isInteger(rating);
    let offsetValue = '0%';
    if (!ratingIsInteger) {
      const firstTwoDecimals = rating.toFixed(2).split('.')[1].slice(0, 2);
      offsetValue = `${firstTwoDecimals}%`;
    }
    return offsetValue;
  }

  get renderStars() {
    const {
      rating: selectedRating,
      numberOfStars,
      starDimension,
      starSpacing,
      starRatedColor,
      starEmptyColor,
      gradientPathName,
      ignoreInlineStyles,
      svgIconPath,
      svgIconViewBox,
    } = this.props;

    const numberOfStarsArray = Array.apply(null, Array(numberOfStars));

    return numberOfStarsArray.map((_, index) => {
      const starRating = index + 1;
      const isStarred = starRating <= selectedRating;

      // only matters when changeRating is false
      // given star 5 and rating 4.2:  5 > 4.2 && 4 < 4.2;
      const isPartiallyFullStar =
        starRating > selectedRating && starRating - 1 < selectedRating;

      const isFirstStar = starRating === 1;
      const isLastStar = starRating === numberOfStars;

      return (
        <Star
          key={starRating}
          fillId={this.fillId}
          isStarred={isStarred}
          isPartiallyFullStar={isPartiallyFullStar}
          isFirstStar={isFirstStar}
          isLastStar={isLastStar}
          starDimension={starDimension}
          starSpacing={starSpacing}
          starRatedColor={starRatedColor}
          starEmptyColor={starEmptyColor}
          gradientPathName={gradientPathName}
          ignoreInlineStyles={ignoreInlineStyles}
          svgIconPath={svgIconPath}
          svgIconViewBox={svgIconViewBox}
        />
      );
    });
  }

  render() {
    const { starRatedColor, starEmptyColor } = this.props;

    return (
      <div
        className="star-ratings"
        title={this.titleText}
        style={this.starRatingsStyle}
      >
        <svg className="star-grad" style={this.starGradientStyle}>
          <defs>
            <linearGradient id={this.fillId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop
                offset="0%"
                className="stop-color-first"
                style={this.stopColorStyle(starRatedColor)}
              />
              <stop
                offset={this.offsetValue}
                className="stop-color-first"
                style={this.stopColorStyle(starRatedColor)}
              />
              <stop
                offset={this.offsetValue}
                className="stop-color-final"
                style={this.stopColorStyle(starEmptyColor)}
              />
              <stop
                offset="100%"
                className="stop-color-final"
                style={this.stopColorStyle(starEmptyColor)}
              />
            </linearGradient>
          </defs>
        </svg>
        {this.renderStars}
      </div>
    );
  }
}

export default StarRatings;
