import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRxCollection } from 'rxdb-hooks';
import { v4 as uuidv4 } from 'uuid';

import { Icon } from '../../../components/Icon';
import { Button } from '../../../components/Button';
import { Modal } from '../../../components/Modal';
import { Col, Row } from '../../../components/Grid';
import { Heading } from '../../../components/Heading';
import { CollectionName } from '../../../../database/types';
import { LessonForm, LessonValues } from '../LessonForm';
import { Span } from '../../../components/Span';
import { Block } from '../../../components/Block';
import { useNavigate } from 'react-router-dom';

interface Props {
  classId: string;
  isFirst: boolean;
}

export function NewLesson(props: Props) {
  const { classId, isFirst } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(isFirst || false);
  const navigate = useNavigate();

  const collection = useRxCollection(CollectionName.Lessons);

  const initialValues: LessonValues = {
    title: '',
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const onCancel = () => {
    setIsOpen(false);
  };

  const onSubmit = async (values: LessonValues) => {
    try {
      const id = uuidv4();
      await collection?.insert({
        ...values,
        class_id: classId,
        id,
      });
      setIsOpen(false);
      isFirst && navigate(`/lesson/${id}/flashcards`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Button white onClick={openModal}>
        <Row gutter={0.5}>
          <Col gutter={0.5}>
            <Span color="yellow">
              <Icon name="plus-solid" />
            </Span>
          </Col>
          <Col gutter={0.5} display={{ xs: 'none', sm: 'block' }}>
            {t('button.newLesson')}
          </Col>
        </Row>
      </Button>
      <Modal
        isOpen={isOpen}
        onBackgroundClick={onCancel}
        onEscapeKeydown={onCancel}
      >
        <Row justifyContent="center">
          <Col xs={12}>
            <Block textCenter>
              <Heading level={3}>{t('title.newLesson')}</Heading>
            </Block>
          </Col>
          <Col xs={12} gutterY={2}>
            <LessonForm
              initialValues={initialValues}
              onSubmit={onSubmit}
              onCancel={onCancel}
              submitLabel={isFirst ? t('button.next') : undefined}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
}
