import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row } from '../Grid';
import { LessonCardContext } from './LessonCardContext';
import styled, { css } from 'styled-components/macro';
import { ifProp } from 'styled-tools';

interface Props {}

export function LessonFlipSwitcher(props: Props) {
  const { t } = useTranslation();
  const { onFlip, setFlip, flippable, flipped } = useContext(LessonCardContext);

  if (!flippable) return null;

  return (
    <Row justifyContent="center" alignItems="center" gutter={1}>
      <Col gutter={1}>
        <SwitchLabel active={!flipped}>{t('label.front')}</SwitchLabel>
      </Col>
      <Col gutter={1}>
        <Switch onClick={onFlip} active={flipped} />
      </Col>
      <Col gutter={1}>
        <SwitchLabel active={flipped}>{t('label.back')}</SwitchLabel>
      </Col>
    </Row>
  );
}

const Switch = styled.button<{ active: boolean }>`
  height: 44px;
  width: 4rem;
  border-radius: 22px;
  background: rgba(var(--black-rgb), 0.2);
  position: relative;
  border: none;
  cursor: pointer;

  &:before {
    content: '';
    width: 36px;
    height: 36px;
    position: absolute;
    left: 4px;
    top: 4px;
    border-radius: 50%;
    background: var(--white);
    transition: transform 0.2s;
  }

  ${ifProp(
    'active',
    css`
      &:before {
        transform: translateX(20px);
      }
    `,
  )}
`;

// styled text component with active prop
const SwitchLabel = styled.span<{ active: boolean }>`
  display: inline-block;
  line-height: 44px;
  color: ${ifProp('active', 'var(--white)', 'rgba(var(--black-rgb), 0.2)')};
  font-weight: 700;
  cursor: ${ifProp('active', 'default', 'pointer')};

  &:hover {
    color: var(--white);
  }
`;
