import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { Button } from '../../../../../components/Button';
import { LessonCardContext } from '../../../../../components/LessonCard';
import { Values } from '../index';
import { QuestionType } from '../../../../../../types/Lesson';

export function SubmitButton() {
  const { t } = useTranslation();
  const { isValid, submitForm, errors, values } = useFormikContext<Values>();
  const { onFlip, flipped } = useContext(LessonCardContext);

  const onNextClick = useCallback(onFlip, []);

  if (!isValid && !flipped && values?.question_type !== QuestionType.Spelling) {
    return (
      <Button yellow onClick={onNextClick} disabled={!!errors?.question_value}>
        {t('button.next')}
      </Button>
    );
  }

  return (
    <Button yellow onClick={submitForm} disabled={!isValid}>
      {t('button.save')}
    </Button>
  );
}
