import React, { useContext } from 'react';
import { useMount } from 'react-use';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../../../context/AuthContext';
import { PageWrapper } from '../../../components/PageWrapper';

export function Boarding() {
  const { t } = useTranslation();
  const { signIn } = useContext(AuthContext);

  useMount(async () => {
    await signIn(window.location.href);
  });

  return (
    <PageWrapper
      loading
      loadingMessage={t('message.gettingUserData')}
    ></PageWrapper>
  );
}
