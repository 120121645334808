import React from 'react';
import styled from 'styled-components';
import OriginAsyncSelect from 'react-select/async';

import { multiSelectStyles } from './styles';
import {
  ClearIndicator,
  DropdownIndicator,
  MultiValueRemove,
} from './components';
import { useTranslation } from 'react-i18next';

export const AsyncSelect = props => {
  const { t } = useTranslation();

  const {
    options = [],
    value,
    onChange,
    name,
    loadOptions,
    isLoading,
    components,
    placeholder,
    invalid,
    defaultOptions,
    autoFocus,
    openMenuOnFocus,
    isMulti,
  } = props;

  return (
    <StyledAsyncSelect
      classNamePrefix="react-select"
      options={options}
      value={value}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator: DropdownIndicator,
        ClearIndicator,
        MultiValueRemove,
        ...components,
      }}
      onChange={onChange}
      loadOptions={loadOptions}
      isLoading={isLoading}
      placeholder={placeholder}
      noOptionsMessage={({ inputValue }) => {
        return inputValue ? t('message.noOptions') : t('message.typeToSearch');
      }}
      isSearchable
      invalid={invalid}
      defaultOptions={defaultOptions}
      autoFocus={autoFocus}
      openMenuOnFocus={openMenuOnFocus}
      isMulti={isMulti}
    />
  );
};

const StyledAsyncSelect = styled(OriginAsyncSelect)<{
  border?: boolean;
  invalid?: boolean;
}>`
  ${multiSelectStyles}
`;
