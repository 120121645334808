import ModalOrigin, { BaseModalBackground } from 'styled-react-modal';
import styled, { keyframes } from 'styled-components/macro';
import { prop } from 'styled-tools';

import { cardStyles } from '../Card';

interface Props {
  isOpen?: boolean;
  children: any;
  width?: number;
  offset?: number;
  overflow?: 'hidden' | 'auto';
  padding?: number;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ModalBackground = styled(BaseModalBackground)`
  animation: ${fadeIn} 0.2s ease-out;
  z-index: 2;
  overflow: auto;
`;

export const Modal = ModalOrigin.styled<Props>`
  ${cardStyles};
  width: ${props => props.width || 400}px;
  overflow: ${prop('overflow', 'auto')};
  z-index: 3;
  max-height: calc(100vh - 1rem);
  max-width: calc(100% - 2rem);
`;

Modal.defaultProps = {
  padding: 2,
};
