import React from 'react';

import { ButtonIcon } from '../../../components/ButtonIcon';
import { Icon } from '../../../components/Icon';
import { Span } from '../../../components/Span';
import { Header } from '../../../components/Header';
import { Container } from '../../../components/Container';
import { BackButton } from '../../../components/BackButton';

type Props = {
  title?: string;
};

export function GamesHeader(props: Props) {
  return (
    <Header>
      <Container>
        <BackButton to=".." block withPadding>
          <Icon name="chevron-left-1" />
        </BackButton>
      </Container>
    </Header>
  );
}
