import React, { useCallback } from 'react';
import { components } from 'react-select';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { Icon, IconName } from '../Icon';
import { Select } from '../Select';
import { Col, Row } from '../Grid';
import { Span } from '../Span';

interface Option {
  icon?: IconName;
  label: string;
  value: string;
  callback?: () => void;
  color?: string;
}

interface Props {
  options: Option[];
  asButton?: boolean;
}

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Icon name="dots" />
  </components.DropdownIndicator>
);

const Option = props => {
  const { label, icon, color } = props.data;

  return (
    <components.Option {...props}>
      <Row flexWrap="nowrap">
        {!!icon && (
          <Col>
            <Span color={color}>
              <Icon name={icon} $block />
            </Span>
          </Col>
        )}
        <Col>
          <Span color={color}>{label}</Span>
        </Col>
      </Row>
    </components.Option>
  );
};

export function ContextMenu(props: Props) {
  const { options, asButton } = props;

  const onChange = ({ callback }: Option) => {
    callback && callback();
  };

  const onClick = useCallback(event => {
    event.preventDefault();
  }, []);

  return (
    <div onClick={onClick}>
      <ContextSelect
        options={options}
        components={{ DropdownIndicator, Option }}
        isSearchable={false}
        value={null}
        styles={{
          menu: provided => ({
            ...provided,
            transform: `translateX(-100%) translateY(-8px)`,
            left: 32,
          }),
        }}
        onChange={onChange}
        asButton={asButton}
      />
    </div>
  );
}

const ContextSelect = styled(Select)`
  .react-select__control {
    border: none !important;
    background: none !important;
    width: 24px;
    height: 24px;
    min-height: 0 !important;
    box-shadow: none;

    ${ifProp(
      'asButton',
      css`
        width: 44px;
        height: 44px;
        justify-content: center;
      `,
    )}

    &.react-select__control--menu-is-open .react-select__dropdown-indicator {
      transform: none !important;
      color: var(--blue-200) !important;
    }
  }

  .react-select__value-container {
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
    position: absolute;
    padding: 0;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator {
    padding: 0;
    color: var(--grey-300);

    &:hover {
      color: var(--blue-200) !important;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .react-select__menu {
    transform: translateX(-100%);
  }
`;
