import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row } from '../Grid';
import { Button } from '../Button';
import { LessonCardContext } from './LessonCardContext';
import { LessonCardFooter } from './LessonCardFooter';

interface Props {
  $hidden?: boolean;
}

export function LessonFlipButton(props: Props) {
  const { $hidden } = props;
  const { t } = useTranslation();
  const { onFlip, flippable } = useContext(LessonCardContext);

  if (!flippable) return null;

  return (
    <LessonCardFooter $hidden={$hidden}>
      <Row justifyContent="center">
        <Col>
          <Button onClick={onFlip} blue>
            {t('button.flip')}
          </Button>
        </Col>
      </Row>
    </LessonCardFooter>
  );
}
