import React from 'react';
import { useField } from 'formik';
import styled from 'styled-components/macro';

import { Select } from '../../../../components/Select';
import { Col, Row } from '../../../../components/Grid';

import { translationOptions } from '../../../../../locales/i18n';

interface Props {
  name: string;
  label: string;
}
export function LanguageSelect(props: Props) {
  const { name, label } = props;
  const [field, meta, helpers] = useField(name);
  const value = translationOptions.find(option => option.value === field.value);

  const handleLanguageChange = selectedOption => {
    helpers.setValue(selectedOption.value);
  };

  return (
    <Row alignItems="center" gutter={0.5}>
      <Col gutter={0.5}>
        <B>{label}</B>
      </Col>
      <Col gutter={0.5}>
        <Select
          border
          options={translationOptions}
          onChange={handleLanguageChange}
          value={value}
        />
      </Col>
    </Row>
  );
}

const B = styled.b`
  color: var(--white);
`;
