import React from 'react';
import { BoardDocType } from '../../../../database/schemas/board';
import { RxDocument } from 'rxdb';
import styled from 'styled-components/macro';
import { media } from '../../../../styles/media';
import Markdown from 'react-markdown';

interface Props {
  board: RxDocument<BoardDocType>;
}

export function OldBoard(props: Props) {
  return (
    <Wrapper>
      <Content>
        <Markdown>{props.board._data.data as string}</Markdown>
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
  background: var(--white);
  margin: 0 auto;
  overflow: auto;
  padding: 1rem 1.5rem;

  ${media.md} {
    padding: 2rem 1rem;
  }
`;

const Content = styled.div`
  margin: 0 auto;
  @media (min-width: 650px) {
    max-width: calc(100% - 100px);
  }
`;
