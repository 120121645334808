import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRxCollection } from 'rxdb-hooks';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

import { Icon } from '../../../components/Icon';
import { Button } from '../../../components/Button';
import { Modal } from '../../../components/Modal';
import { Col, Row } from '../../../components/Grid';
import { Heading } from '../../../components/Heading';
import { CollectionName } from '../../../../database/types';
import { ClassForm, ClassValues } from '../ClassForm';
import { Span } from '../../../components/Span';
import { translationOptions } from '../../../../locales/i18n';
import { Block } from '../../../components/Block';
import { ClassDocType } from '../../../../database/schemas/class';

interface Props {
  isFirst: boolean;
}

export function NewClass(props: Props) {
  const { t, i18n } = useTranslation();
  const { isFirst } = props;
  const [isOpen, setIsOpen] = useState(isFirst || false);
  const navigate = useNavigate();

  const collection = useRxCollection<ClassDocType>(CollectionName.Classes);

  const initialValues: ClassValues = {
    title: '',
    author: '',
    language: translationOptions.find(option => option.value === i18n.language),
  };

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onCancel = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onSubmit = async (values: ClassValues) => {
    try {
      const id = uuidv4();

      await collection?.insert({
        ...values,
        language: values.language?.value,
        id,
        shared: false,
      });

      isFirst && navigate(`/class/${id}`);
      setIsOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Button white onClick={openModal}>
        <Row gutter={0.5}>
          <Col gutter={0.5}>
            <Span color="yellow">
              <Icon name="plus-solid" />
            </Span>
          </Col>
          <Col gutter={0.5} display={{ xs: 'none', sm: 'block' }}>
            {t('button.newClass')}
          </Col>
        </Row>
      </Button>
      <Modal
        isOpen={isOpen}
        onBackgroundClick={onCancel}
        onEscapeKeydown={onCancel}
      >
        <Row justifyContent="center">
          <Col xs={12}>
            <Block textCenter>
              <Heading level={3}>{t('title.newClass')}</Heading>
            </Block>
          </Col>
          <Col xs={12} gutterY={2}>
            <ClassForm
              initialValues={initialValues}
              onSubmit={onSubmit}
              onCancel={onCancel}
              submitLabel={isFirst ? t('button.next') : undefined}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
}
