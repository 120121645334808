import { useCallback, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';
import { RxDocument } from 'rxdb';
import Papa from 'papaparse';

import { ClassDocType } from '../../database/schemas/class';
import { CollectionName } from '../../database/types';
import { FlashCardDocType } from '../../database/schemas/flashcard';
import { LessonDocType } from '../../database/schemas/lesson';
import { flashcardToExportFormat } from '../../utils/flashcard-export';
import { downloadCsv } from '../../utils/download-csv';

export function useClassExport() {
  const [loading, setLoading] = useState(false);

  const classCollection = useRxCollection<ClassDocType>(CollectionName.Classes);
  const flashCardCollection = useRxCollection<FlashCardDocType>(
    CollectionName.FlashCards,
  );
  const lessonCollection = useRxCollection<LessonDocType>(
    CollectionName.Lessons,
  );

  const exportClass = useCallback(
    async (
      props:
        | {
            classId: string;
            lessonId?: string;
          }
        | {
            classId?: string;
            lessonId: string;
          },
    ) => {
      const { classId, lessonId } = props;
      setLoading(true);

      try {
        let lessonsId = lessonId ? [lessonId] : [];
        let lessons: RxDocument<LessonDocType>[] = [];

        const classQuery = classCollection?.findOne(classId);
        const classDoc = await classQuery?.exec();

        if (!classDoc) return;

        if (classId && lessonId && lessonCollection) {
          const lessonQuery = lessonCollection.findOne(lessonId);
          const lesson = await lessonQuery.exec();
          lesson && lessons.push(lesson);
        } else if (lessonCollection) {
          const lessonsQuery = lessonCollection.find({
            selector: {
              class_id: classId,
            },
          });

          lessons = await lessonsQuery.exec();

          lessonsId = lessons.map(lesson => lesson._data.id);
        }

        if (flashCardCollection) {
          const query = flashCardCollection.find({
            selector: {
              lesson_id: {
                $in: lessonsId,
              },
            },
          });

          const flashcards = await query.exec();
          const flashcardsFormatted = flashcards.map(flashcard => {
            const lesson = lessons.find(
              lesson => lesson.id === flashcard.lesson_id,
            );
            if (lesson)
              return flashcardToExportFormat(classDoc, lesson, flashcard);
          });

          const csv = Papa.unparse(flashcardsFormatted);
          downloadCsv(csv, classDoc.title);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [classCollection, flashCardCollection, lessonCollection],
  );

  return { exportClass, loading };
}
