export enum CollectionName {
  Classes = 'classes',
  Lessons = 'lessons',
  FlashCards = 'flashcards',
  Media = 'media',
  Boards = 'boards',
  Audio = 'audio',
  Score = 'score',
  Profile = 'profile',
}
