import React, { KeyboardEvent, useEffect, useRef } from 'react';
import { FieldArray, useField } from 'formik';
import { useSwiperSlide } from 'swiper/react';

import { Col, Row } from '../../../../../components/Grid';
import { AnswerInput } from './AnswerInput';
import { QuestionType } from '../../../../../../types/Lesson';

interface Props {
  name: string;
  answer: string;
  disabled?: boolean;
  questionType?: string;
}
export const TextAnswer = (props: Props) => {
  const { answer, name, disabled, questionType } = props;
  const placeholders =
    questionType === QuestionType.Spelling
      ? `${answer}`.trim().split(' ')
      : `${answer}`
          .trim()
          .split(' ')
          .map(word => {
            if (word.length < 2) return '__';
            if (word.length >= 6) return '______';
            return '____';
          });
  const itemsRef = useRef<(HTMLInputElement | null)[]>([]);
  const [field, meta, helpers] = useField(name);
  const { isActive } = useSwiperSlide();

  useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        itemsRef.current[0]?.focus();
      }, 300);
    }
  }, [isActive, itemsRef.current?.length]);

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, placeholders.length);
  }, [placeholders.length]);

  const onFilled = event => {
    const index = itemsRef.current?.findIndex(
      inputRef => event.target === inputRef,
    );

    itemsRef.current[index + 1]?.focus();
  };

  const onCleaned = event => {
    const index = itemsRef.current?.findIndex(
      inputRef => event.target === inputRef,
    );

    itemsRef.current[index - 1]?.focus();
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const index = itemsRef.current?.findIndex(
      inputRef => event.target === inputRef,
    );

    if (event.keyCode === 9 && index + 1 === itemsRef.current?.length) {
      event.preventDefault();
    }
  };

  return (
    <FieldArray
      name="words"
      render={arrayHelpers => (
        <Row justifyContent="center" flexDirection="row" gutterY={0.5}>
          {field.value?.map((word, index) => (
            <Col key={index} gutterY={0.5}>
              <AnswerInput
                name={`${name}.${index}`}
                placeholder={placeholders[index]}
                onFilled={onFilled}
                onCleaned={onCleaned}
                ref={el => (itemsRef.current[index] = el)}
                onKeyDown={onKeyDown}
                disabled={disabled}
              />
            </Col>
          ))}
        </Row>
      )}
    />
  );
};
