import styled, { keyframes } from 'styled-components';
import { prop } from 'styled-tools';

const spinKeyframe = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Loader = styled.div<{ color?: string }>`
  &:after {
    content: '';
    display: block;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: solid 3px rgba(${prop('color', '255, 255, 255')}, 0.2);
    border-top-color: rgb(${prop('color', '255, 255, 255')});
    animation: ${spinKeyframe} 1s infinite linear, ${fadeIn} 0.2s ease-out;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -1rem 0 0 -1rem;
    box-sizing: border-box;
  }
`;
