import React, { Component } from 'react';

interface Props {
  fillId: string;
  isStarred: boolean;
  isPartiallyFullStar: boolean;
  isFirstStar: boolean;
  isLastStar: boolean;
  starDimension: string;
  starSpacing: string;
  starRatedColor: string;
  starEmptyColor: string;
  gradientPathName: string;
  ignoreInlineStyles: boolean;
  svgIconPath: string;
  svgIconViewBox: string;
}

class Star extends Component<Props> {
  get starContainerStyle() {
    const { starSpacing, isFirstStar, isLastStar, ignoreInlineStyles } =
      this.props;

    const starContainerStyle = {
      position: 'relative',
      display: 'inline-block',
      verticalAlign: 'middle',
      paddingLeft: isFirstStar ? undefined : starSpacing,
      paddingRight: isLastStar ? undefined : starSpacing,
    };
    return ignoreInlineStyles ? {} : starContainerStyle;
  }

  get starSvgStyle() {
    const { ignoreInlineStyles, starDimension } = this.props;
    const starSvgStyle = {
      width: starDimension,
      height: starDimension,
      transition: 'transform .2s ease-in-out',
    };

    return ignoreInlineStyles ? {} : starSvgStyle;
  }

  get pathStyle() {
    const {
      isStarred,
      isPartiallyFullStar,
      starEmptyColor,
      starRatedColor,
      gradientPathName,
      fillId,
      ignoreInlineStyles,
    } = this.props;

    let fill;
    if (isPartiallyFullStar) fill = `url('${gradientPathName}#${fillId}')`;
    else if (isStarred) fill = starRatedColor;
    else fill = starEmptyColor;

    const pathStyle = {
      fill: fill,
      transition: 'fill .2s ease-in-out',
    };

    return ignoreInlineStyles ? {} : pathStyle;
  }

  get starClasses() {
    const { isPartiallyFullStar, ignoreInlineStyles } = this.props;

    let starClasses = 'widget-svg';
    if (isPartiallyFullStar) starClasses += ' multi-widget-selected';

    return ignoreInlineStyles ? '' : starClasses;
  }

  render() {
    const { svgIconViewBox, svgIconPath } = this.props;
    return (
      <div className="star-container" style={this.starContainerStyle}>
        <svg
          viewBox={svgIconViewBox}
          className={this.starClasses}
          style={this.starSvgStyle}
        >
          <path className="star" style={this.pathStyle} d={svgIconPath} />
        </svg>
      </div>
    );
  }
}

export default Star;
