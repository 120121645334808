import React from 'react';
import styled from 'styled-components/macro';

import { Option } from '../Select/types';
import { Button as ButtonOrigin } from '../Button';
import { Col, Row } from '../Grid';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';

interface Props<ValueType> {
  options: Option<ValueType>[];
  value: Option<ValueType>;
  onChange: (value: Option<ValueType>) => void;
}

export function Tabs<ValueType>(props: Props<ValueType>) {
  const { options, value, onChange } = props;

  return (
    <Wrapper>
      <Row flexWrap="nowrap" gutter={0} alignContent="stretch">
        {options.map(option => (
          <Col
            key={`${option.value}`}
            gutter={0}
            flex="1 auto"
            style={{ minWidth: 'auto' }}
          >
            <Button
              blue={value.value === option.value}
              onClick={() => {
                onChange(option);
              }}
            >
              {option.label}
            </Button>
          </Col>
        ))}
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  //padding: 0.25rem;
  border-radius: 0.75rem;
  border: 2px solid var(--blue-200);
  overflow: hidden;
`;

const Button = styled(ButtonOrigin)`
  color: var(--blue-200) !important;
  border-radius: 0;
  padding: 7px 17px;

  &:hover {
    background-color: var(--blue-10);
  }

  ${ifProp(
    'blue',
    css`
      color: var(--white) !important;
      pointer-events: none;
    `,
  )}
`;
