import * as React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components/macro';
import { v4 as uuidv4 } from 'uuid';
import { useRxCollection, useRxData } from 'rxdb-hooks';

import { Col, Row } from '../../../components/Grid';
import { Navigation } from '../components/Navigation';
import { AudioUpload } from './AudioUpload';
import { MediaDocType } from '../../../../database/schemas/media';
import { CollectionName } from '../../../../database/types';
import { AudioDocType } from '../../../../database/schemas/audio';
import { Heading } from '../../../components/Heading';
import { Modal } from '../../../components/Modal';
import { AudioForm, AudioValues } from './AudioForm';
import { AudioList } from './AudioList';
import { AudioRecord } from './AudioRecord';
import { media } from '../../../../styles/media';
import { SharedBlock } from '../../../components/SharedBlock';
import { LessonDocType } from '../../../../database/schemas/lesson';

export function AudioPage() {
  const { lessonId } = useParams<{ lessonId: string }>();
  const { t } = useTranslation();
  const [audio, setAudio] = useState<
    { type: string; data: string } | undefined
  >();
  const {
    result: [lesson],
  } = useRxData<LessonDocType>(CollectionName.Lessons, collection =>
    collection.findOne(lessonId),
  );

  const audioCollection = useRxCollection<AudioDocType>(CollectionName.Audio);
  const mediaCollection = useRxCollection<MediaDocType>(CollectionName.Media);

  const onSubmit = async ({ title }: AudioValues) => {
    try {
      if (audio) {
        const media = await mediaCollection?.insert({
          data: audio.data,
          mime: audio.type,
        });

        if (media) {
          await audioCollection?.insert({
            id: uuidv4(),
            lesson_id: lessonId as string,
            src: media.path as string,
            title,
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setAudio(undefined);
    }
  };

  const onUpload = ({ type, data }) => {
    setAudio({ type, data });
  };

  const onCancel = () => {
    setAudio(undefined);
  };

  return (
    <>
      <Row flexDirection="column" height="100%">
        <Col>
          <Row alignItems="center" gutter={2}>
            <Col xs="auto" gutter={2}>
              <Navigation />
            </Col>
            <Col gutter={2}>
              <SharedBlock shared={lesson?._data?.shared}>
                <Row>
                  <Col>
                    <AudioUpload onUpload={onUpload} />
                  </Col>
                  <Col>
                    <AudioRecord onUpload={onUpload} />
                  </Col>
                </Row>
              </SharedBlock>
            </Col>
          </Row>
        </Col>
        <Col xs="auto" gutterY={1} style={{ position: 'relative' }}>
          <Wrapper>
            <AudioList />
          </Wrapper>
        </Col>
      </Row>
      <Modal
        isOpen={audio?.data}
        onBackgroundClick={onCancel}
        onEscapeKeydown={onCancel}
      >
        <Row justifyContent="center">
          <Col>
            <Heading level={3}>{t('title.newRecord')}</Heading>
          </Col>
          <Col xs={12} gutterY={2}>
            <AudioForm onSubmit={onSubmit} onCancel={onCancel} />
          </Col>
        </Row>
      </Modal>
      <BackgroundStyles />
    </>
  );
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0.25rem;
  right: 0.25rem;
  bottom: -1.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 1.5rem;

  ${media.md} {
    left: 0.5rem;
    right: 0.5rem;
  }
`;

const BackgroundStyles = createGlobalStyle`
  body {
    background: var(--red);
  }
`;
