import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';

import { Col, Row } from '../Grid';
import { Icon } from '../Icon';
import { Loader } from '../Loader';
import { ifProp } from 'styled-tools';

interface Props {
  loading?: boolean;
  horizontal?: boolean;
}

export function NotSynced(props: Props) {
  const { loading, horizontal } = props;
  const { t } = useTranslation();

  if (loading) {
    return <Loader color="var(--blue-200-rgb)" />;
  }

  return (
    <Wrapper $horizontal={horizontal || false}>
      <Row justifyContent="center" gutter={0.5}>
        <Col gutter={0.5}>
          <Icon name="cloud-1" $size={32} />
        </Col>
        <Col
          xs={horizontal ? 'auto' : 12}
          gutterY={horizontal ? 0 : 0.5}
          gutter={0.5}
        >
          {t('message.notSyncedMessage')}
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ $horizontal: boolean }>`
  color: var(--grey-300);
  font-size: 14px;
  line-height: 20px;

  ${ifProp(
    '$horizontal',
    css`
      max-width: 28rem;
    `,
    css`
      text-align: center;
      max-width: 17rem;
    `,
  )}
`;
