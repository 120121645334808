import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useWindowSize } from 'react-use';
import { RxDocument } from 'rxdb';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import WindowScroller from 'react-virtualized/dist/commonjs/WindowScroller';
import CellMeasurer from 'react-virtualized/dist/commonjs/CellMeasurer';
import CellMeasurerCache from 'react-virtualized/dist/commonjs/CellMeasurer/CellMeasurerCache';

import { ClassListItem } from './ClassListItem';
import { Col } from '../../../components/Grid';
import { ClassDocType } from '../../../../database/schemas/class';
import { EditClass } from '../EditClass';
import { ShareClass } from '../ShareClass';
import { DeleteClass } from '../DeleteClass';

interface Props {
  classes: RxDocument<ClassDocType>[];
}

export function ClassList(props: Props) {
  const { classes } = props;
  const [editDocument, setEditDocument] =
    useState<RxDocument<ClassDocType> | null>(null);
  const [shareDocument, setShareDocument] =
    useState<RxDocument<ClassDocType> | null>(null);
  const [deleteDocument, setDeleteDocument] =
    useState<RxDocument<ClassDocType> | null>(null);
  const { width } = useWindowSize();

  const cache = useMemo(() => {
    return new CellMeasurerCache({
      fixedWidth: false,
      defaultHeight: 76,
    });
  }, []);

  useEffect(() => {
    cache.clearAll();
  }, [classes?.length, width]);

  const onEditClose = useCallback(() => {
    setEditDocument(null);
  }, []);

  const onShareClose = useCallback(() => {
    setShareDocument(null);
  }, []);

  const onDeleteClose = useCallback(() => {
    setDeleteDocument(null);
  }, []);

  const rowRenderer = ({ index, key, style, parent }) => {
    const data = classes[index];

    return (
      <CellMeasurer cache={cache} key={key} parent={parent} rowIndex={index}>
        {({ registerChild, measure }) => (
          <div style={style} ref={registerChild}>
            <Col gutterY={0.75} gutter={0}>
              <ClassListItem
                key={`${data.id}_${data._data._rev}`}
                classDocument={data}
                onDelete={setDeleteDocument}
                onEdit={setEditDocument}
                onShare={setShareDocument}
                measure={measure}
              />
            </Col>
          </div>
        )}
      </CellMeasurer>
    );
  };

  return (
    <>
      <WindowScroller>
        {({ height, isScrolling, scrollTop }) => (
          <AutoSizer disableHeight>
            {({ width }) => (
              <List
                className="List"
                autoHeight
                height={height}
                width={width}
                rowCount={classes.length || 0}
                rowHeight={cache.rowHeight}
                rowRenderer={rowRenderer}
                scrollTop={scrollTop}
              />
            )}
          </AutoSizer>
        )}
      </WindowScroller>
      <EditClass document={editDocument} onClose={onEditClose} />
      <ShareClass document={shareDocument} onClose={onShareClose} />
      <DeleteClass document={deleteDocument} onClose={onDeleteClose} />
    </>
  );
}
