import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Col, Row } from '../../../../components/Grid';
import { Heading } from '../../../../components/Heading';
import { Block } from '../../../../components/Block';
import { StripePricingTable } from '../../../../components/Pricing/PricingTable';
import { Modal } from '../../../../components/Modal';

interface Props {
  onClose: () => void;
}

export function LimitReached(props: Props) {
  const { onClose } = props;
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearchParams({
      pageId: 'pricing',
    });
  }, []);

  return (
    <Modal
      onBackgroundClick={onClose}
      onEscapeKeydown={onClose}
      isOpen
      width={82 * 16}
    >
      <Row>
        <Col xs={12}>
          <Heading level={2}>
            <Block textCenter>{t('message.planLimitReached')}</Block>
          </Heading>
        </Col>
        <Col xs={12} gutterY={4}>
          <StripePricingTable />
        </Col>
      </Row>
    </Modal>
  );

  /*return (
    <>
      <LessonCardContextProvider flippable>
        <LessonCard>
          <LessonCardFront>
            <LessonCardLayout>
              <LessonCardHeader>
                <Row>
                  <Col xs={12} gutterY={0.5}>
                    <Heading level={4}>
                      <Block textCenter>{t('message.planLimitReached')}</Block>
                    </Heading>
                  </Col>
                  <Col gutterY={0.5}></Col>
                </Row>
              </LessonCardHeader>
              <LessonCardContent>
                <Row>
                  <Col xs={12}>
                    <Block textCenter>
                      <Heading level={2}>{t('title.plansAndPricing')}</Heading>
                    </Block>
                  </Col>
                  <Col xs={12} gutterY={0.5}>
                    <Block textCenter>
                      <Span color="grey">
                        {t('message.selectPlanToContinue')}
                      </Span>
                    </Block>
                  </Col>
                  <Col xs={12} gutterY={{ xs: 1, md: 2 }}>
                    <StripePricingTable />
                  </Col>
                </Row>
              </LessonCardContent>
            </LessonCardLayout>
          </LessonCardFront>
        </LessonCard>
      </LessonCardContextProvider>
    </>
  );*/
}
