import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { api } from '../../../services/api';
import { PageWrapper } from '../../components/PageWrapper';

import { sharedReplications } from '../../../database/configureDatabase';

export function SharePage() {
  const { t } = useTranslation();
  const { entry, token } = useParams<{ entry: 'class'; token: string }>();
  const navigate = useNavigate();

  const getAccess = async () => {
    try {
      await api.get(`${entry}/share/${token}`);

      for (const replication of Object.values(sharedReplications)) {
        replication?.reSync();
      }
    } catch (error) {
    } finally {
      navigate('/', { replace: true });
    }
  };

  useEffect(() => {
    getAccess();
  }, []);

  return (
    <PageWrapper loading loadingMessage={t('message.synchronizingData')} />
  );
}
