import { replicateRxCollection } from 'rxdb/plugins/replication';

import { LessonDocType } from './schema';
import { api } from '../../../services/api';
import { Lesson } from '../../../types/Lesson';
import { ReplicationPullHandlerResult } from 'rxdb/dist/types/types/plugins/replication';

type Checkpoint = Pick<LessonDocType, 'updated_at'>;

export async function sharedLessonReplication(collection) {
  return replicateRxCollection<LessonDocType, Checkpoint>({
    collection,
    replicationIdentifier: `${process.env.REACT_APP_API_URL}/shared-lesson`,
    autoStart: true,
    live: true,
    deletedField: 'deleted_at',
    pull: {
      batchSize: 1000,
      async handler(
        lastCheckpoint,
        batchSize,
      ): Promise<ReplicationPullHandlerResult<Lesson, Checkpoint>> {
        const minTimestamp = new Date(0);

        const response = await api.get(`/lesson/sync`, {
          params: {
            last_checkpoint: minTimestamp,
            batchSize,
            show_shared_entities: true,
          },
        });

        const documents = response.data || [];

        return {
          documents,
          checkpoint: {
            updated_at: new Date(0).toISOString(),
          },
        };
      },
      modifier: document => ({
        ...document,
        shared: true,
      }),
    },
  });
}
