import { addRxPlugin, createRxDatabase, RxDatabase } from 'rxdb';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { v4 as uuidv4 } from 'uuid';
import { RxReplicationState } from 'rxdb/dist/types/plugins/replication';

import {
  classReplication,
  classSchema,
  sharedClassReplication,
} from './schemas/class';
import {
  lessonReplication,
  lessonSchema,
  sharedLessonReplication,
} from './schemas/lesson';
import {
  flashCardReplication,
  flashcardSchema,
  sharedFlashCardReplication,
} from './schemas/flashcard';
import { CollectionName } from './types';
import {
  mediaReplication,
  mediaSchema,
  sharedMediaReplication,
} from './schemas/media';
import {
  boardReplication,
  boardSchema,
  sharedBoardReplication,
} from './schemas/board';
import {
  audioReplication,
  audioSchema,
  sharedAudioReplication,
} from './schemas/audio';
import { scoreReplication, scoreSchema } from './schemas/score';
import { User } from '../types/User';
import { generateMediaPath } from '../utils/path-generation';
import { base64MimeType } from '../utils/file-manipulatiion';

export const sharedReplications: {
  [key in CollectionName]?: RxReplicationState<any, any>;
} = {};

export const replications: {
  [key in CollectionName]?: RxReplicationState<any, any>;
} = {};

addRxPlugin(RxDBDevModePlugin);
addRxPlugin(RxDBUpdatePlugin);
addRxPlugin(RxDBMigrationPlugin);
addRxPlugin(RxDBQueryBuilderPlugin);

const name = 'classmaster-3';

function preInsert(plainData) {
  plainData.created_at = new Date().toISOString();
  plainData.updated_at = new Date().toISOString();
}

function preSave(plainData) {
  plainData.updated_at = new Date().toISOString();
}

export function createDatabase(user: User) {
  return createRxDatabase({
    name: `${name}-${user.id}`,
    storage: getRxStorageDexie(),
    password: '',
    multiInstance: true,
    eventReduce: true,
    cleanupPolicy: {},
    allowSlowCount: true,
  });
}

export async function addCollections(db: RxDatabase) {
  await db.addCollections({
    [CollectionName.Classes]: {
      schema: classSchema,
      migrationStrategies: {},
    },
    [CollectionName.Lessons]: {
      schema: lessonSchema,
      migrationStrategies: {},
    },
    [CollectionName.FlashCards]: {
      schema: flashcardSchema,
      migrationStrategies: {},
    },
    [CollectionName.Media]: {
      schema: mediaSchema,
      migrationStrategies: {},
    },
    [CollectionName.Boards]: {
      schema: boardSchema,

      migrationStrategies: {},
    },
    [CollectionName.Audio]: {
      schema: audioSchema,

      migrationStrategies: {},
    },
    [CollectionName.Score]: {
      schema: scoreSchema,
      migrationStrategies: {},
    },
  });

  db[CollectionName.Classes].preInsert(preInsert, false);
  db[CollectionName.Classes].preSave(preSave, false);

  db[CollectionName.Lessons].preInsert(preInsert, false);
  db[CollectionName.Lessons].preSave(preSave, false);

  db[CollectionName.FlashCards].preInsert(preInsert, false);
  db[CollectionName.FlashCards].preSave(preSave, false);

  // get file mime type from base64 string

  db[CollectionName.Media].preInsert(plainData => {
    const id = uuidv4();
    if (!plainData.mime) {
      plainData.mime = base64MimeType(plainData.data);
    }
    plainData.id = id;
    plainData.path = generateMediaPath(id, plainData.mime);
    preInsert(plainData);
  }, false);
  db[CollectionName.Media].preSave(plainData => {
    plainData.path = generateMediaPath(plainData.id, plainData.mime);
    if (!plainData.mime) {
      plainData.mime = base64MimeType(plainData.data);
    }
    preSave(plainData);
  }, false);

  db[CollectionName.Boards].preInsert(preInsert, false);
  db[CollectionName.Boards].preSave(preSave, false);

  db[CollectionName.Audio].preInsert(preInsert, false);
  db[CollectionName.Audio].preSave(preSave, false);

  db[CollectionName.Score].preInsert(preInsert, false);
  db[CollectionName.Score].preSave(preSave, false);
}

export async function addReplications(db: RxDatabase) {
  const replicationsList = {
    [CollectionName.Classes]: classReplication,
    [CollectionName.Lessons]: lessonReplication,
    [CollectionName.FlashCards]: flashCardReplication,
    [CollectionName.Media]: mediaReplication,
    [CollectionName.Boards]: boardReplication,
    [CollectionName.Audio]: audioReplication,
    [CollectionName.Score]: scoreReplication,
  };
  const sharedReplicationsList = {
    [CollectionName.Classes]: sharedClassReplication,
    [CollectionName.Lessons]: sharedLessonReplication,
    [CollectionName.FlashCards]: sharedFlashCardReplication,
    [CollectionName.Audio]: sharedAudioReplication,
    [CollectionName.Boards]: sharedBoardReplication,
    [CollectionName.Media]: sharedMediaReplication,
  };

  for (const collectionName in replicationsList) {
    replications[collectionName] = await replicationsList[collectionName](
      db[collectionName],
    );
  }

  for (const collectionName in sharedReplicationsList) {
    sharedReplications[collectionName] = await sharedReplicationsList[
      collectionName
    ](db[collectionName]);
  }
}
