import React, { useContext, useEffect, useMemo } from 'react';
import { RxDocument } from 'rxdb';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMeasure } from 'react-use';

import { Col, Row } from '../../../../components/Grid';
import { CardLink } from '../../../../components/Card';
import { Block } from '../../../../components/Block';
import { Span } from '../../../../components/Span';
import { Icon, IconName } from '../../../../components/Icon';
import { ClassDocType } from '../../../../../database/schemas/class';
import { media } from '../../../../../styles/media';
import Rating from '../../../../components/Rating';
import { useScore } from '../../../../hooks/useScore';
import { GameType } from '../../../../../types/Game';
import { SharedBlock } from '../../../../components/SharedBlock';
import { useFlashcardCount } from '../../../../hooks/useFlashcardCount';
import { useAudioCount } from '../../../../hooks/useAudioCount';
import { useBoardCount } from '../../../../hooks/useBoardCount';
import { ContextMenu } from '../../../../components/ContextMenu';
import { translationOptions } from '../../../../../locales/i18n';
import {
  ButtonIcon,
  StyledButtonIcon,
} from '../../../../components/ButtonIcon';
import { useClassImport } from '../../../../hooks/useClassImport';
import { useClassExport } from '../../../../hooks/useClassExport';
import { AuthContext } from '../../../../context/AuthContext';

interface Props {
  classDocument: RxDocument<ClassDocType>;
  onDelete: (document: RxDocument<ClassDocType>) => void;
  onEdit: (document: RxDocument<ClassDocType>) => void;
  onShare: (document: RxDocument<ClassDocType>) => void;
  measure: () => void;
}

export function ClassListItem(props: Props) {
  const { t, i18n } = useTranslation();
  const { classDocument, onDelete, onEdit, onShare, measure } = props;
  const { id, title, author, language } = classDocument;
  const flashcardsCount = useFlashcardCount({
    classId: id,
  });
  const audioCount = useAudioCount({ classId: id });
  const boardCount = useBoardCount({ classId: id });
  const [ref, { height }] = useMeasure<HTMLAnchorElement>();
  const { importClass } = useClassImport({ classDocument });
  const { exportClass } = useClassExport();
  const { subscription } = useContext(AuthContext);

  const languageTag = useMemo(() => {
    if (language === i18n.language) return null;

    return translationOptions.find(option => option.value === language)?.label;
  }, [language, i18n.language]);

  const { classScore } = useScore({
    classId: classDocument._data.id,
    gameType: GameType.CardQuiz,
  });

  useEffect(() => {
    setTimeout(measure, 0);
  }, [height]);

  const contextMenuOptions = [
    {
      label: t('button.upload'),
      value: 'upload',
      callback: importClass,
      icon: 'cloud-upload' as IconName,
    },
    {
      label: t('button.download'),
      value: 'download',
      callback: () => {
        exportClass({ classId: id });
      },
      icon: 'cloud-download' as IconName,
    },
    {
      label: t('button.edit'),
      value: 'edit',
      callback: () => {
        onEdit(classDocument);
      },
      icon: 'pencil-8' as IconName,
    },
    {
      label: t('button.delete'),
      value: 'delete',
      callback: () => {
        onDelete(classDocument);
      },
      icon: 'trash-4' as IconName,
      color: 'red',
    },
  ];

  const counts = [
    {
      icon: 'stack',
      count: flashcardsCount,
    },
    {
      icon: 'microphone',
      count: audioCount,
    },
    {
      icon: 'document-text',
      count: boardCount,
    },
  ].filter(({ count }) => count);

  const onShareClick = event => {
    event.preventDefault();
    onShare(classDocument);
  };

  return (
    <Wrapper to={`/class/${classDocument.id}`} ref={ref}>
      <Row gutter={0.5}>
        <Col gutter={0.5}>
          <LessonsCount background="yellow">
            <ButtonIcon block onClick={onShareClick}>
              <Span color="white">
                <Icon name="share-1" />
              </Span>
            </ButtonIcon>
          </LessonsCount>
        </Col>
        <Col gutter={0.5} xs="auto" alignSelf="center">
          <Row alignItems={{ xs: 'flex-start', md: 'center' }}>
            <Col xs="auto">
              <Pad>
                <Row>
                  <Col xs={12} md="auto" alignSelf="center">
                    <Row>
                      <Col xs={12}>
                        <b>{title}</b>
                      </Col>
                      <Col xs={12} gutterY={{ xs: 0.125, md: 0 }}>
                        <Row alignItems="center" gutterY={0.25} gutter={0.5}>
                          {!!author && (
                            <Col
                              flex={{ xs: '100%', md: '0 1 auto' }}
                              gutter={0.5}
                              gutterY={0.25}
                            >
                              <Span color="grey">{author}</Span>
                            </Col>
                          )}
                          {!!languageTag && (
                            <>
                              {!!author && (
                                <Col
                                  display={{ xs: 'none', md: 'block' }}
                                  gutter={0.5}
                                >
                                  <Dot color="grey">•</Dot>
                                </Col>
                              )}
                              <Col
                                gutter={0.5}
                                gutterY={0.25}
                                flex={{ xs: '100%', md: '0 1 auto' }}
                              >
                                <Span color="grey">
                                  <Row
                                    flexWpap="nowrap"
                                    alignItems="center"
                                    gutter={0.25}
                                  >
                                    <Col gutter={0.25}>
                                      <Icon
                                        $size={20}
                                        name="language-1"
                                        $block
                                      />
                                    </Col>
                                    <Col gutter={0.25}>{languageTag}</Col>
                                  </Row>
                                </Span>
                              </Col>
                            </>
                          )}
                          <Col gutterY={0.25} gutter={0.5}>
                            <Row
                              gutter={0.5}
                              flexWrap="nowrap"
                              alignItems="center"
                            >
                              {counts.map(({ icon, count }, index) => {
                                if (!count) return null;

                                return (
                                  <>
                                    {((index === 0 &&
                                      (!!author || !!languageTag)) ||
                                      index > 0) && (
                                      <Col
                                        gutter={0.5}
                                        display={{
                                          xs: index === 0 ? 'none' : 'block',
                                          md: 'block',
                                        }}
                                      >
                                        <Dot color="grey">•</Dot>
                                      </Col>
                                    )}
                                    <Col gutter={0.5}>
                                      <Span color="grey">
                                        <Row
                                          flexWpap="nowrap"
                                          alignItems="center"
                                          gutter={0.25}
                                        >
                                          <Col gutter={0.25}>
                                            <Icon
                                              $size={20}
                                              name={icon as IconName}
                                              $block
                                            />
                                          </Col>
                                          <Col gutter={0.25}>{count}</Col>
                                        </Row>
                                      </Span>
                                    </Col>
                                  </>
                                );
                              })}
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col alignSelf="center" gutterY={{ xs: 0.25, md: 0 }}>
                    <Rating rating={classScore} numberOfStars={3} />
                  </Col>
                </Row>
              </Pad>
            </Col>
            <Col gutter={{ xs: 2, md: 3 }}>
              <SharedBlock shared={classDocument?._data?.shared}>
                <ContextMenu options={contextMenuOptions} asButton />
              </SharedBlock>
            </Col>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled(CardLink)`
  &:hover {
    background-color: var(--blue-10);
  }

  .star-ratings {
    text-align: center;
  }
`;

const LessonsCount = styled(Block)`
  padding: 1rem;
  min-width: 4rem;
  text-align: center;

  ${media.md} {
    padding: 1.125rem;
    min-width: 4.25rem;
  }

  ${StyledButtonIcon} {
    padding: 0.25rem;
    border: 2px solid var(--white);
    border-radius: 100px;

    svg {
      position: relative;
      left: -2px;
    }
  }
`;

const Pad = styled.div`
  padding: 0.5rem 0.25rem;

  ${media.md} {
    padding: 1rem 0.5rem;
  }
`;

const Dot = styled(Span)`
  font-family: 'sans-serif' !important;
  font-size: 0.75rem;
`;
