import React, { useContext } from 'react';
import { useMount } from 'react-use';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../../../context/AuthContext';
import { PageWrapper } from '../../../components/PageWrapper';

export function BoardingGoogle() {
  const { t } = useTranslation();
  const { signInWithGoogle } = useContext(AuthContext);

  useMount(async () => {
    await signInWithGoogle();
  });

  return (
    <PageWrapper
      loading
      loadingMessage={t('message.gettingUserData')}
    ></PageWrapper>
  );
}
