import React, { createContext, PropsWithChildren, useState } from 'react';
import _mapValues from 'lodash/mapValues';
import _pickBy from 'lodash/pickBy';

import { GameFormValues } from './types';

interface Answer {
  correct: boolean;
  touched: boolean;
  hidden?: boolean;
  values: GameFormValues;
}

interface ContextData {
  answers: { [flashcardId: string]: Answer };
  isRetry: boolean;
  setIsRetry: (value: boolean) => void;
  correctAnswers: { [flashcardId: string]: Answer };
  addAnswer: (flashcardId: string, answer: Answer) => void;
  hideCorrectAnswers: () => void;
  retryNumber: number;
}

export const GameContext = createContext({} as ContextData);

export const GameContextProvider = (props: PropsWithChildren) => {
  const [answers, setAnswers] = useState<{ [flashcardId: string]: Answer }>({});
  const [isRetry, setIsRetry] = useState(false);
  const [retryNumber, setRetryNumber] = useState(0);

  const correctAnswers = _pickBy(answers, 'correct');

  const addAnswer = (flashcardId: string, answer: Answer) => {
    setAnswers({ ...answers, [flashcardId]: answer });
  };

  const hideCorrectAnswers = () => {
    setRetryNumber(value => value + 1);
    setAnswers(
      _mapValues(answers, answer => ({
        ...answer,
        hidden: answer.correct,
        touched: false,
        values: {},
      })),
    );
  };

  return (
    <GameContext.Provider
      value={{
        answers,
        correctAnswers,
        addAnswer,
        hideCorrectAnswers,
        isRetry,
        setIsRetry,
        retryNumber,
      }}
    >
      {props.children}
    </GameContext.Provider>
  );
};
