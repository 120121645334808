import React from 'react';
import styled from 'styled-components';
import OriginalSelect from 'react-select';

import { selectStyles } from './styles';
import { DropdownIndicator } from './components';

export const Select = props => {
  const {
    options = [],
    value,
    onChange,
    name,
    border,
    placeholder,
    invalid,
    components,
    ...rest
  } = props;

  return (
    <StyledSelect
      className="context-menu"
      classNamePrefix="react-select"
      options={options}
      value={value}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator,
        ...components,
      }}
      onChange={onChange}
      border={border}
      placeholder={placeholder}
      invalid={invalid}
      menuPortalTarget={document.body}
      {...rest}
    />
  );
};

const StyledSelect = styled(OriginalSelect)<{
  border?: boolean;
  invalid?: boolean;
}>`
  ${selectStyles}
`;
