import { replicateRxCollection } from 'rxdb/plugins/replication';
import { ReplicationPullHandlerResult } from 'rxdb/dist/types/types/plugins/replication';
import _omit from 'lodash/omit';

import { BoardDocType } from './schema';
import { api } from '../../../services/api';

type Checkpoint = Pick<BoardDocType, 'id' | 'updated_at'>;

export async function boardReplication(collection) {
  return replicateRxCollection<BoardDocType, Checkpoint>({
    collection,
    replicationIdentifier: `${process.env.REACT_APP_API_URL}/board`,
    autoStart: true,
    live: true,
    deletedField: 'deleted_at',
    push: {
      batchSize: 1000,
      async handler(docs) {
        const conflicts = await api.post(
          `/board/sync/`,
          docs.map(({ newDocumentState, assumedMasterState }) => {
            return {
              newDocumentState: {
                ..._omit(newDocumentState, 'data', 'deleted_at'),
                value: JSON.stringify(newDocumentState.data),
                title: '',
              },
              assumedMasterState: assumedMasterState && {
                ..._omit(assumedMasterState, 'data', 'deleted_at'),
                value: JSON.stringify(newDocumentState.data),
                title: '',
              },
            };
          }),
        );

        return conflicts.data;
      },
      modifier: document => {
        if (document.shared) return null;
        return document;
      },
    },
    pull: {
      batchSize: 1000,
      async handler(
        lastCheckpoint,
        batchSize,
      ): Promise<ReplicationPullHandlerResult<BoardDocType, Checkpoint>> {
        const minTimestamp = lastCheckpoint
          ? lastCheckpoint?.updated_at || new Date(0)
          : new Date(0);

        const response = await api.get(`/board/sync`, {
          params: {
            last_checkpoint: minTimestamp,
            batchSize,
          },
        });
        const documents = response.data || [];
        const documentsLength = documents.length;
        const lastDocument = documents[documentsLength - 1];

        const checkpoint = documentsLength
          ? {
              id: lastDocument.id,
              updated_at: lastDocument.updated_at,
            }
          : lastCheckpoint;

        return {
          documents: documents.map(({ value, ...d }) => {
            let data = value;

            try {
              data = JSON.parse(value);
            } catch (error) {
              data = value;
            }

            return {
              ...d,
              data,
            };
          }),
          checkpoint,
        };
      },
      modifier: document => ({
        ...document,
        shared: false,
      }),
    },
  });
}
