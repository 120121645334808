import { MultipleChoice } from '../types/FlashCard';

export function normalizeMultipleChoice(multipleChoice: MultipleChoice): {
  options: string[];
  correctIndex: number[];
} {
  if (typeof multipleChoice.correctIndex === 'number') {
    return { ...multipleChoice, correctIndex: [multipleChoice.correctIndex] };
  }
  return multipleChoice as {
    options: string[];
    correctIndex: number[];
  };
}
