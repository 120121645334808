import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row } from '../../components/Grid';
import { PageWrapper } from '../../components/PageWrapper';
import { AccountHeader } from './Header';
import { AuthContext } from '../../context/AuthContext';
import { Button } from '../../components/Button';
import { Container } from '../../components/Container';
import { SubscriptionCard } from './SubscriptionCard';
import { ProfileCard } from './ProfileCard';
import { SettingsCard } from './SettingsCard';
import { Heading } from '../../components/Heading';
import { Data } from './Data';

export function AccountPage() {
  const { t } = useTranslation();
  const { signOut } = useContext(AuthContext);

  return (
    <>
      <AccountHeader />
      <PageWrapper>
        <Container $fixed>
          <Row>
            <Col xs={12}>
              <Row alignItems="center">
                <Col xs="auto" display={{ xs: 'block', md: 'none' }} />
                <Col xs="auto" display={{ xs: 'none', md: 'block' }}>
                  <Heading white level={3}>
                    {t('title.myAccount')}
                  </Heading>
                </Col>
                <Col>
                  <Button red onClick={signOut}>
                    {t('button.signOut')}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xs={12} gutterY={{ xs: 1, md: 1.5 }}>
              <Row gutter={1.5} gutterY={{ xs: 1, md: 1.5 }}>
                <Col xs={12} md={6} gutter={1.5} gutterY={{ xs: 1, md: 1.5 }}>
                  <ProfileCard />
                </Col>
                <Col xs={12} md={6} gutter={1.5} gutterY={{ xs: 1, md: 1.5 }}>
                  <Row gutterY={{ xs: 1, md: 1.5 }}>
                    <Col xs={12} gutterY={{ xs: 1, md: 1.5 }}>
                      <SettingsCard />
                    </Col>
                    <Col xs={12} gutterY={{ xs: 1, md: 1.5 }}>
                      <SubscriptionCard />
                    </Col>
                    <Col xs={12} gutterY={{ xs: 1, md: 1.5 }}>
                      <Data />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    </>
  );
}
