import React from 'react';
import styled from 'styled-components';
import { Col as ColOrigin, Row as RowOrigin } from './Origin';

type Gutter =
  | string
  | number
  | {
      xs?: number | string;
      sm?: number | string;
      md?: number | string;
      lg?: number | string;
      xl?: number | string;
    };

interface RowProps {
  gutterY?: Gutter;
  gutter?: Gutter;
  display?: 'flex' | 'inline-flex';
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column‑reverse';
  flexWrap?: 'nowrap' | 'wrap' | 'wrap‑reverse';
  justifyContent?:
    | 'flex‑start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space‑around';
  alignItems?:
    | 'baseline'
    | 'first baseline'
    | 'last baseline'
    | 'flex‑start'
    | 'flex-end'
    | 'center'
    | 'stretch';
  alignContent?:
    | 'baseline'
    | 'first baseline'
    | 'last baseline'
    | 'flex‑start'
    | 'flex-end'
    | 'center'
    | 'space‑between'
    | 'space-around'
    | 'stretch';
  elementType?: 'div' | 'span';
  children: any;
  height?: number | string;
  style?: React.CSSProperties;
}

export const Col = styled(ColOrigin)``;

export const Row = styled(RowOrigin)<RowProps>`
  height: ${prop => prop.height};
`;
