import { lookup } from 'mimeish';

export const base64ToBlob = (base64, mime) =>
  fetch(base64).then(res => res.blob());

export async function blobToFormData(blob, name) {
  const formData = new FormData();
  formData.append('file', blob, name);

  return formData;
}

export function blobToBase64(buffer): Promise<string> {
  const reader = new window.FileReader();
  return new Promise((resolve, reject) => {
    reader.readAsDataURL(buffer);
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
}

export function base64MimeType(encoded) {
  if (!encoded) return;
  const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

  if (mime && mime.length) return mime[1];
}
