import { useMemo } from 'react';
import _keyBy from 'lodash/keyBy';
import _meanBy from 'lodash/meanBy';
import _mapValues from 'lodash/mapValues';
import { useRxData } from 'rxdb-hooks';

import { GameType } from '../../types/Game';
import { ScoreDocType } from '../../database/schemas/score';
import { CollectionName } from '../../database/types';
import { LessonDocType } from '../../database/schemas/lesson';

interface Props {
  classId: string;
  gameType: GameType;
}
export function useScore(props: Props) {
  const { classId, gameType } = props;

  const { result: lessons } = useRxData<LessonDocType>(
    CollectionName.Lessons,
    collection =>
      collection.find({
        selector: {
          class_id: classId,
        },
      }),
  );

  const { result: scores } = useRxData<ScoreDocType>(
    CollectionName.Score,
    collection =>
      collection.find({
        selector: {
          type: gameType,
          lesson_id: {
            $in: lessons.map(lesson => lesson._data.id),
          },
        },
      }),
  );

  const scoresByLessonId = useMemo(() => {
    const scoresById = _keyBy(scores, 'lesson_id');
    return _mapValues(scoresById, ({ _data }) => (_data.score || 0) * 0.03);
  }, [scores?.length, gameType]);

  const classScore = useMemo(
    () => (_meanBy(scores, 'score') || 0) * 0.03,
    [scores?.length, gameType],
  );

  return {
    scoresByLessonId,
    classScore,
  };
}
