import React from 'react';
import { useRxData } from 'rxdb-hooks';
import { useParams } from 'react-router-dom';
import { RxDocument } from 'rxdb';

import { CollectionName } from '../../../../../database/types';
import { AudioDocType } from '../../../../../database/schemas/audio';
import { Col, Row } from '../../../../components/Grid';
import { AudioListItem } from './AudioListItem';

export function AudioList() {
  const { lessonId } = useParams<{ lessonId: string }>();

  const { result: audioList } = useRxData<AudioDocType>(
    CollectionName.Audio,
    collection =>
      collection.find({
        selector: {
          lesson_id: lessonId,
        },
        sort: [{ created_at: 'desc' }],
      }),
  );

  const onDelete = async (document: RxDocument<AudioDocType>) => {
    document?.remove();
  };

  return (
    <Row gutterY={0.75}>
      {audioList.map(audio => (
        <Col xs={12} gutterY={0.75} key={`${audio.id}_${audio._data._rev}`}>
          <AudioListItem audio={audio} onDelete={onDelete} />
        </Col>
      ))}
    </Row>
  );
}
