import React, { useCallback } from 'react';
import styled from 'styled-components/macro';

import { UnsplashImage } from './types';

const UNSPLASH_APP_NAME = process.env.REACT_APP_UNSPLASH_APP_NAME;

interface Props {
  image: UnsplashImage;
  onImageSelect: (image: UnsplashImage) => void;
}

export function Image(props: Props) {
  const { image, onImageSelect } = props;
  const { id, urls, links, user } = image;

  const onClick = useCallback(() => {
    onImageSelect(image);
  }, [id]);

  return (
    <Wrapper src={urls.small} onClick={onClick}>
      <ImageLinks onClick={e => e.stopPropagation()}>
        <ImageLink
          target="_blank"
          rel="noreferrer nofollow"
          href={`https://unsplash.com/?utm_source=${UNSPLASH_APP_NAME}&utm_medium=referral`}
        >
          Unsplash
        </ImageLink>
        <ImageLink
          target="_blank"
          rel="noreferrer nofollow"
          href={`https://unsplash.com/@${user?.username}?utm_source=${UNSPLASH_APP_NAME}&utm_medium=referral`}
        >
          {user?.name}
        </ImageLink>
      </ImageLinks>
    </Wrapper>
  );
}

const ImageLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px 0;
  flex-wrap: wrap;
  transition: transform 0.3s;
  cursor: default;
  transform: translateY(100%);
`;

const ImageLink = styled.a`
  font-size: 10px;
  line-height: 12px;
  color: white;
  text-decoration: none;
  margin: 0 5px;

  &:hover {
    text-decoration: underline;
  }
`;

const Wrapper = styled.div<{ src: string }>`
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
  border-radius: 0.5rem;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  &:hover ${ImageLinks} {
    transform: translateY(0);
  }
`;
