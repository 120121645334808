import styled from 'styled-components/macro';

export const LessonCardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(0deg);
  overflow: hidden;
`;
