import React, { useEffect } from 'react';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import { useField } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { useWakeLock } from 'react-screen-wake-lock';

import { Icon } from '../../../../../components/Icon';
import { Span } from '../../../../../components/Span';
import { PulseIconButton } from '../../../../../components/PulseIconButton';

interface Props {
  name: string;
  isActive: boolean;
  disabled?: boolean;
}

export function VoiceAnswer(props: Props) {
  const { name, isActive, disabled } = props;
  const { resetTranscript, transcript, listening } = useSpeechRecognition();
  const [field, meta, helpers] = useField(name);
  let [searchParams, setSearchParams] = useSearchParams();
  const { request, release } = useWakeLock();

  const language = searchParams.get('lang') || 'en';

  useEffect(() => {
    if (isActive && typeof transcript === 'string' && transcript.length) {
      const value = transcript.split(' ');
      helpers.setValue(value);
    }
  }, [isActive, transcript]);

  useEffect(() => {
    if (!listening) {
      resetTranscript();
      release();
    }
  }, [isActive, listening]);

  const onClick = () => {
    if (!listening) {
      request();
      SpeechRecognition.startListening({ language });
    } else {
      SpeechRecognition.stopListening();
      release();
    }
  };

  return (
    <PulseIconButton
      type="button"
      color="--white-rgb"
      withPadding
      $active={listening}
      onClick={onClick}
      disabled={disabled}
    >
      <Span color="blue">
        <Icon name="microphone" />
      </Span>
    </PulseIconButton>
  );
}
