import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { createGlobalStyle } from 'styled-components';
import { useRxCollection, useRxQuery } from 'rxdb-hooks';
import { SwiperSlide } from 'swiper/react';

import { Col, Row } from '../../../components/Grid';
import { Navigation } from '../components/Navigation';
import { CollectionName } from '../../../../database/types';
import { BoardDocType } from '../../../../database/schemas/board';
import { BoardsSwiper } from './BoardsSwiper';
import { OldBoard } from './OldBoard';
import { Board } from './Board';

export function BoardsPage() {
  const { lessonId } = useParams<{ lessonId: string }>();

  const boardsCollection = useRxCollection<BoardDocType>(CollectionName.Boards);

  const boardsQuery = useMemo(
    () =>
      boardsCollection?.find({
        selector: {
          lesson_id: lessonId,
        },
      }),
    [boardsCollection, lessonId],
  );

  const { result: boards, isFetching: boardsIsFetching } =
    useRxQuery(boardsQuery);

  if (!boardsCollection || boardsIsFetching) return null;

  const newBoards = boards.filter(
    board => typeof board._data.data === 'object',
  );
  const oldBoards = boards.filter(
    board => typeof board._data.data === 'string',
  );

  return (
    <>
      <Row flexDirection="column" height="100%" gutter={0}>
        <Col gutter={0} display={{ lg: 'none' }}>
          <Navigation />
        </Col>
        <Col
          xs="auto"
          gutterY={{ xs: 1, md: 1.5, lg: 0 }}
          style={{ position: 'relative' }}
          gutter={0}
        >
          <BoardsSwiper>
            {(newBoards.length ? newBoards : [undefined]).map(board => (
              <SwiperSlide key={'new'}>
                <Wrapper>
                  <Board board={board} />
                </Wrapper>
              </SwiperSlide>
            ))}
            {oldBoards.map(board => (
              <SwiperSlide key={board.id}>
                <Wrapper>
                  <OldBoard board={board} />
                </Wrapper>
              </SwiperSlide>
            ))}
          </BoardsSwiper>
        </Col>
      </Row>
      <BackgroundStyles />
    </>
  );
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const BackgroundStyles = createGlobalStyle`
  body {
    background: var(--green-100);
  }

  #root {
    height: -webkit-fill-available;
  }
`;
