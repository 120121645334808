import { useField } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import _debounce from 'lodash/debounce';
import axios from 'axios';
import { Input } from '../../../../../../components/Input';
import { Col, Row } from '../../../../../../components/Grid';
import { api } from '../../../../../../../services/api';
import { UnsplashImage } from './types';
import { Images } from './Images';
import { media } from '../../../../../../../styles/media';
import { Loader } from '../../../../../../components/Loader';
import {
  LessonCardContent,
  LessonCardFooter,
} from '../../../../../../components/LessonCard';
import { Button } from '../../../../../../components/Button';

const reader = new FileReader();

export const WebImageField = props => {
  const { t } = useTranslation();
  const { label, name } = props;
  const [field, meta, helpers] = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState<UnsplashImage[]>([]);

  useEffect(() => {
    if (inputRef?.current) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 250);
    }
  }, [inputRef?.current]);

  const onFileRead = event => {
    helpers.setValue({
      type: 'image/jpeg',
      data: event.target.result,
    });
  };

  const onReplace = () => {
    helpers.setValue(undefined);
  };

  useEffect(() => {
    reader.addEventListener('load', onFileRead);
    return () => {
      reader.removeEventListener('load', onFileRead);
    };
  }, []);

  const onChange = async event => {
    setLoading(true);
    try {
      const response = await api.get<UnsplashImage[]>(`/unsplash/search`, {
        params: {
          num: 10,
          query: event.target.value,
          searchType: 'image',
          fileType: 'jpg',
          imgSize: 'medium',
        },
      });

      setImages(response.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const debouncedChangeHandler = useCallback(_debounce(onChange, 300), []);

  const onImageSelect = async (image: UnsplashImage) => {
    try {
      const response = await axios.get(`${image?.urls?.regular}&w=720`, {
        responseType: 'blob',
      });

      reader.readAsDataURL(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  if (field.value?.data) {
    return (
      <>
        <LessonCardContent>
          {field?.value?.data ? <img src={field.value.data} /> : null}
        </LessonCardContent>
        <LessonCardFooter $hidden={!field.value?.data}>
          <Row justifyContent="center">
            <Col>
              <Button onClick={onReplace} blue>
                {t('button.replace')}
              </Button>
            </Col>
          </Row>
        </LessonCardFooter>
      </>
    );
  }

  return (
    <Wrapper>
      <Row flexDirection="column" height="100%" gutter={0}>
        <Col gutter={0}>
          <Input
            onChange={debouncedChangeHandler}
            placeholder={t('message.typeToSearch')}
            ref={inputRef}
          />
        </Col>
        {loading ? (
          <Loader />
        ) : (
          <Col
            gutter={0}
            xs="auto"
            gutterY={{ xs: 1, md: 2 }}
            style={{ position: 'relative' }}
          >
            <ImagesWrapper>
              <Images images={images} onImageSelect={onImageSelect} />
            </ImagesWrapper>
          </Col>
        )}
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 1rem;

  ${media.md} {
    padding: 1.5rem;
  }

  ${Loader}:after {
    border: solid 3px var(--blue-200);
    border-top-color: #fff;
  }
`;

const ImagesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  /*right: 0;
  bottom: 0;*/
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translate3d(0, 0, 0);
`;
