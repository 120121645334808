import {
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  toTypedRxJsonSchema,
} from 'rxdb';

export const lessonSchemaLiteral = {
  version: 0,
  title: 'Lesson Schema',
  description: '',
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    title: {
      type: 'string',
    },
    class_id: {
      type: 'string',
      maxLength: 36,
    },
    shared: {
      type: 'boolean',
    },
    created_at: {
      type: 'string',
    },
    updated_at: {
      type: 'string',
    },
    deleted_at: {
      type: 'string',
    },
  },
  required: ['id', 'title', 'class_id'],
  indexes: ['class_id'],
} as const;

const schemaTyped = toTypedRxJsonSchema(lessonSchemaLiteral);
export type LessonDocType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof schemaTyped
>;
export const lessonSchema: RxJsonSchema<LessonDocType> = lessonSchemaLiteral;
