import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../Button';
import { api } from '../../../services/api';
import { AuthContext } from '../../context/AuthContext';

export const ManageSubscriptionLink = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { subscription } = useContext(AuthContext);

  const getStripePortalUrl = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get('stripe/get-portal-link');
      window.open(response.data.url);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  if (!subscription) {
    return (
      <Button yellow $loading={isLoading} to="/pricing">
        {t('button.manageSubscription')}
      </Button>
    );
  }

  return (
    <Button yellow onClick={getStripePortalUrl} $loading={isLoading}>
      {t('button.manageSubscription')}
    </Button>
  );
};
