import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, switchProp } from 'styled-tools';

interface Props {
  checked: boolean;
  color: 'white' | 'yellow';
  onClick?: () => void;
}

export function Checkbox(props: Props) {
  const { color, checked, onClick } = props;

  return (
    <StyledCheckbox color={color} type="button" onClick={onClick}>
      <CheckboxDot color={color} checked={checked} />
    </StyledCheckbox>
  );
}

export const StyledCheckbox = styled.button<{ color?: string }>`
  cursor: pointer;
  display: inline-block;
  align-items: center;
  user-select: none;
  content: '';
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.25rem;
  border: 2px solid;
  border-color: ${switchProp('color', {
    white: 'var(--white)',
    yellow: 'var(--yellow-100)',
  })};
  border-radius: 1rem;
`;

const CheckboxDot = styled.span<{ checked?: boolean; color?: string }>`
  display: none;
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 50%;
  background-color: ${switchProp('color', {
    white: 'var(--white)',
    yellow: 'var(--yellow-100)',
  })};

  ${ifProp(
    'checked',
    css`
      display: block;
    `,
  )}
`;
