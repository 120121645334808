import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components/macro';

import { Col, Row } from '../Grid';
import { ifProp } from 'styled-tools';
import { media } from '../../../styles/media';

interface Props {
  align?: 'top' | 'center';
  withPadding?: boolean;
}

const defaultProps = {
  align: 'center',
  withPadding: true,
};

export function LessonCardContent(
  props: PropsWithChildren<Props> & typeof defaultProps,
) {
  const { children, align, withPadding } = props;

  if (align === 'top') {
    return (
      <Wrapper>
        <Content withPadding={withPadding}>{children}</Content>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Row height="100%" justifyContent="center" alignItems="center" gutter={0}>
        <Col xs={12} gutter={0}>
          <Content withPadding={withPadding}>{children}</Content>
        </Col>
      </Row>
    </Wrapper>
  );
}

LessonCardContent.defaultProps = defaultProps;

const Wrapper = styled.div`
  overflow: auto;
  height: 100%;
`;

const Content = styled.div<{ withPadding: boolean }>`
  color: var(--grey-500);

  ${ifProp(
    'withPadding',
    css`
      padding: 1rem;

      ${media.md} {
        padding: 1.5rem;
      }
    `,
  )}

  img {
    max-width: 100%;
  }
`;
