import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

export interface HeadingProps {
  level?: number;
  weight?: 400 | 500 | 600 | 700;
  children: any;
  white?: boolean;
  style?: React.CSSProperties;
}

const fontSize = ({ level }) => {
  switch (level) {
    case 1:
      return 30;
    case 2:
      return 24;
    case 3:
      return 20;
    case 4:
      return 16;
    case 5:
      return 14;
  }

  return 18;
};

export const headingStyles = css`
  font-size: ${fontSize}px;
  line-height: ${({ level }) => fontSize({ level }) + 4}px;
  margin: 0;
  white-space: pre-wrap;
`;

export const StyledHeading = styled(({ level, children, ...props }) =>
  React.createElement(`h${level}`, props, children),
)`
  ${headingStyles};
  font-weight: ${({ weight }) => weight};
  ${ifProp(
    'white',
    css`
      color: var(--white);
    `,
  )};
`;

const StyledHeadingArrowBack = styled(StyledHeading)`
  position: relative;
  padding-left: 2rem;
  font-weight: 700;

  svg {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    left: 0;
    top: 50%;
    margin-top: -0.75rem;
  }
`;

StyledHeading.defaultProps = {
  level: 1,
  weight: 700,
};

export const Heading = (props: HeadingProps) => {
  return <StyledHeading {...props} />;
};
