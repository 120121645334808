import React from 'react';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { Col, Row } from '../../../components/Grid';
import { Heading } from '../../../components/Heading';
import { Card } from '../../../components/Card';
import { ManageSubscriptionLink } from '../../../components/ManageSubscriptionLink';

export const SubscriptionCard = () => {
  const { t } = useTranslation();
  const isMd = useMediaQuery(useTheme()?.breakpoints.up('md'));

  return (
    <Card padding={isMd ? 2 : 1.5}>
      <Row>
        <Col xs={12}>
          <Heading level={3}>{t('title.subscription')}</Heading>
        </Col>
        <Col xs={12} gutterY={1.5}>
          <ManageSubscriptionLink />
        </Col>
      </Row>
    </Card>
  );
};
