import React, { useCallback, useMemo } from 'react';
import { SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import card_quiz from '../assets/card_quiz.png';
import mock_test from '../assets/mock_test.png';
import speak_game from '../assets/speak_game.png';

import { Col, Row } from '../../../components/Grid';
import { Slider } from './Slider';
import { GameType } from '../../../../types/Game';
import { StyledHeading } from '../../../components/Heading';

interface Props {
  onGameChange: (gameType: GameType) => void;
}

export function GamesSlider(props: Props) {
  const { t } = useTranslation();
  const { onGameChange } = props;

  const options = useMemo(() => {
    return [
      {
        value: GameType.CardQuiz,
        label: t('title.cardQuiz'),
        img: card_quiz,
      },
      {
        value: GameType.MockTest,
        label: t('title.mockTest'),
        img: mock_test,
      },
      {
        value: GameType.SpeakGame,
        label: t('title.speakGame'),
        img: speak_game,
      },
    ];
  }, []);

  const onSlideChange = useCallback(
    (swiper: any) => {
      const option = options[swiper.activeIndex];
      onGameChange(option.value);
    },
    [options.length],
  );

  return (
    <Slider onSlideChange={onSlideChange}>
      {options.map(({ label, value, img }) => (
        <SwiperSlide key={value}>
          <Row alignItems="center" flexDirection="column">
            <Col>
              <Heading>{label}</Heading>
            </Col>
            <Col gutterY={{ xs: 1, md: 2 }}>
              <img src={img} alt={label} />
            </Col>
          </Row>
        </SwiperSlide>
      ))}
    </Slider>
  );
}

const Heading = styled(StyledHeading)`
  color: var(--white);
`;
