import { replicateRxCollection } from 'rxdb/plugins/replication';
import { ReplicationPullHandlerResult } from 'rxdb/dist/types/types/plugins/replication';
import _omit from 'lodash/omit';

import { AudioDocType } from './schema';
import { api } from '../../../services/api';

type Checkpoint = Pick<AudioDocType, 'updated_at'>;

export async function sharedAudioReplication(collection) {
  return replicateRxCollection<AudioDocType, Checkpoint>({
    collection,
    replicationIdentifier: `${process.env.REACT_APP_API_URL}/shared-audio`,
    autoStart: true,
    live: true,
    deletedField: 'deleted_at',
    pull: {
      async handler(
        lastCheckpoint,
        batchSize,
      ): Promise<ReplicationPullHandlerResult<AudioDocType, Checkpoint>> {
        const minTimestamp = new Date(0);

        const response = await api.get(`/audio/sync`, {
          params: {
            last_checkpoint: minTimestamp,
            batchSize,
            show_shared_entities: true,
          },
        });
        const documents = response.data || [];

        return {
          documents,
          checkpoint: {
            updated_at: new Date(0).toISOString(),
          },
        };
      },
      batchSize: 1000,
      modifier: document => ({
        ...document,
        shared: true,
      }),
    },
  });
}
