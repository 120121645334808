import React, { useContext } from 'react';

import { PageWrapper } from '../../components/PageWrapper';
import { AuthContext } from '../../context/AuthContext';
import { HomeHeader } from '../HomePage/Header';
import { Container } from '../../components/Container';
import { Pricing } from '../../components/Pricing';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row } from '../../components/Grid';
import { Heading } from '../../components/Heading';
import { Block } from '../../components/Block';
import { Span } from '../../components/Span';

export function PricingPage() {
  const { user } = useContext(AuthContext);
  let [searchParams] = useSearchParams();
  const message = searchParams.get('message');
  const { t } = useTranslation();

  if (
    !user ||
    !process.env.REACT_APP_STRIPE_PRICING_TABLE_ID ||
    !process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  ) {
    return null;
  }

  return (
    <>
      <HomeHeader />
      <PageWrapper>
        <Container $fixed>
          <Row>
            {!!message && (
              <Col xs={12} gutterY={2}>
                <Heading level={2}>
                  <Block textCenter>
                    <Span color="white">{t(`message.${message}`)}</Span>
                  </Block>
                </Heading>
              </Col>
            )}
            <Col xs={12} gutterY={2}>
              <Pricing />
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    </>
  );
}
