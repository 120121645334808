import { Swiper as SwiperOrigin } from 'swiper/react';
import styled from 'styled-components/macro';
import { PropsWithChildren } from 'react';
import { EffectCards } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/scrollbar';

export function BoardsSwiper(props: PropsWithChildren) {
  return (
    <Wrapper>
      <svg viewBox="0 0 50 70" height="100%" />
      <SwiperOrigin
        effect="cards"
        modules={[EffectCards]}
        cardsEffect={{
          perSlideRotate: 2,
          perSlideOffset: 4,
        }}
        perspective={false}
      >
        {props.children}
      </SwiperOrigin>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .swiper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    perspective: none;
  }

  .swiper-wrapper {
    transform-style: unset;
    transform: none;
  }

  .swiper-slide {
    transform-style: unset;
  }

  .swiper-slide-fully-visible:first-child {
    transform: none !important;
  }
`;
