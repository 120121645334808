import { useAsync } from 'react-use';
import { useRxCollection } from 'rxdb-hooks';
import { FlashCardDocType } from '../../database/schemas/flashcard';
import { CollectionName } from '../../database/types';
import { LessonDocType } from '../../database/schemas/lesson';

interface Props {
  classId: string;
  lessonId?: string;
}

export function useAudioCount(props: Props) {
  const { classId, lessonId } = props;

  const audioCollection = useRxCollection<FlashCardDocType>(
    CollectionName.Audio,
  );

  const lessonCollection = useRxCollection<LessonDocType>(
    CollectionName.Lessons,
  );

  const { value: count } = useAsync(async () => {
    let lessonsId = lessonId ? [lessonId] : [];

    if (!lessonId && lessonCollection) {
      const lessonsQuery = await lessonCollection.find({
        selector: {
          class_id: classId,
        },
      });

      const lessons = await lessonsQuery.exec();

      lessonsId = lessons.map(lesson => lesson._data.id);
    }

    if (audioCollection) {
      const query = audioCollection.count({
        selector: {
          lesson_id: {
            $in: lessonsId,
          },
        },
      });

      const count = await query.exec();

      return count;
    }

    return undefined;
  }, [audioCollection, lessonCollection, classId, lessonId]);

  return count || 0;
}
