import styled, { css } from 'styled-components/macro';
import { ifProp, prop, switchProp } from 'styled-tools';

interface Props {
  padding?: number;
  background?: 'yellow' | 'white';
  textCenter?: boolean;
  textRight?: boolean;
}

export const Block = styled.div<Props>`
  padding: ${ifProp('padding', prop('padding'), 0)}rem;
  min-height: 100%;

  ${switchProp('background', {
    yellow: css`
      background: var(--yellow-100);
      color: var(--white);
    `,
  })};

  ${ifProp(
    'textCenter',
    css`
      text-align: center;
    `,
  )}

  ${ifProp(
    'textRight',
    css`
      text-align: right;
    `,
  )}
`;
