import styled, { css } from 'styled-components/macro';
import { ifProp, prop } from 'styled-tools';
import { Link } from 'react-router-dom';

interface CardProps {
  padding?: number;
}

export const cardStyles = css`
  padding: ${ifProp('padding', prop('padding'), 0)}rem;
  box-shadow: 0 2px 4px 0 rgba(var(--black), 0.05);
  background: var(--white);
  border-radius: 0.5rem;
  overflow: hidden;
`;

export const Card = styled.div<CardProps>`
  ${cardStyles}
`;

export const CardLink = styled(Link)<CardProps>`
  ${cardStyles};
  display: block;
  text-decoration: none;
  color: inherit;
`;
