import React from 'react';
import styled from 'styled-components/macro';

import { Button as ButtonOrigin } from '../../../../../components/Button';
import { Col, Row } from '../../../../../components/Grid';
import { Icon } from '../../../../../components/Icon';
import { Circle } from '../../../../../components/AnswerOption';

interface Props {
  option: string;
  onSelect: (option: string) => void;
  isActive: boolean;
}

export const ChoiceOption = (props: Props) => {
  const { option, onSelect, isActive } = props;

  const onClick = () => {
    onSelect(option);
  };

  return (
    <Button onClick={onClick} whiteBorder block>
      <Row gutter={0.5}>
        <Col gutter={0.5}>
          <Circle correct={isActive}>
            <Icon name="checkmark" />
          </Circle>
        </Col>
        <Col gutter={0.5} xs="auto">
          {option}
        </Col>
      </Row>
    </Button>
  );
};

const Button = styled(ButtonOrigin)`
  white-space: normal;
  font-weight: 400;
  text-align: left;
  display: block;
  background: rgba(var(--blue-200-rgb), 0.1);
  color: var(--blue-200);

  &:hover {
    background: rgba(var(--blue-200-rgb), 0.2);
  }
`;
