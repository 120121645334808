import React from 'react';

import { Col, Row } from '../../../../../../components/Grid';
import { UnsplashImage } from './types';
import { Image } from './Image';

interface Props {
  images: UnsplashImage[];
  onImageSelect: (image: UnsplashImage) => void;
}

export function Images(props: Props) {
  const { images, onImageSelect } = props;

  return (
    <Row gutterY={{ xs: 0.5, md: 1 }} gutter={{ xs: 0.5, md: 1 }}>
      {images.map((image, index) => (
        <Col
          xs={4}
          key={image.id}
          gutterY={{ xs: 0.5, md: 1 }}
          gutter={{ xs: 0.5, md: 1 }}
        >
          <Image image={image} onImageSelect={onImageSelect} />
        </Col>
      ))}
    </Row>
  );
}
