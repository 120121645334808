import { lookup } from 'mimeish';

export function generateMediaName(uuid: string, mime: string) {
  return `${uuid}.${lookup.extension(mime)}`;
}

export function generateMediaPath(uuid: string, type: string) {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const name = generateMediaName(uuid, type);

  return `${process.env.REACT_APP_STORAGE_URL}/${user.id}/${name}`;
}
