import Cookies from 'universal-cookie';

const cookies = new Cookies();

const ACCESS_TOKEN = 'auth.accessToken';
const REFRESH_TOKEN = 'auth.refreshToken';
const CREATE_EMAIL = 'auth.email';

export function createEmailCookie(email: string) {
  localStorage.setItem(CREATE_EMAIL, email);
}

export function removeEmailCookie() {
  localStorage.removeItem(CREATE_EMAIL);
}

export function getEmailCookie() {
  return localStorage.getItem(CREATE_EMAIL);
}

export function getGoogleTokens() {
  const accessToken = cookies.get('access_token');
  const refreshToken = cookies.get('refresh_token');
  return { accessToken, refreshToken };
}

export function createTokenCookies(accessToken: string, refreshToken: string) {
  localStorage.setItem(ACCESS_TOKEN, accessToken);
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
}

export function removeTokenCookies() {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
}

export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN);
}

export function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN);
}
