import React from 'react';

import { Col, Row } from '../../../components/Grid';
import { Icon } from '../../../components/Icon';
import { Container } from '../../../components/Container';
import { Heading } from '../../../components/Heading';
import { Ellipsis } from '../../../components/Ellipsis';
import { Block } from '../../../components/Block';
import { Header } from '../../../components/Header';
import { BackButton } from '../../../components/BackButton';
import { Link } from '../../../components/Link';
import { Logo } from '../../../components/Logo';
import { ProfileButton } from '../../../components/ProfileButton';

type Props = {
  title?: string;
};

export function ClassHeader(props: Props) {
  const { title } = props;

  return (
    <Header>
      <Container>
        <Row
          alignItems="center"
          gutter={{ xs: 1.5, md: 1 }}
          display={{ xs: 'flex', md: 'none' }}
        >
          <Col>
            <BackButton to=".." block withPadding>
              <Icon name="chevron-left-1" />
            </BackButton>
          </Col>
          <Col xs="auto" gutter={2}>
            <Block textCenter>
              <Heading level={3}>
                <Ellipsis>{title}</Ellipsis>
              </Heading>
            </Block>
          </Col>
          <Col xs="3.625rem" />
        </Row>
        <Row
          justifyContent="space-between"
          display={{ xs: 'none', md: 'flex' }}
        >
          <Col>
            <Link to="..">
              <Logo />
            </Link>
          </Col>
          <Col>
            <ProfileButton />
          </Col>
        </Row>
      </Container>
    </Header>
  );
}
