import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button } from '../Button';
import { Col, Row } from '../Grid';
import { Icon } from '../Icon';
import { GameType } from '../../../types/Game';
import { Span } from '../Span';

interface Props {
  lessonsId?: string[];
  label?: string;
}

export const StudyButton = (props: Props) => {
  const { t } = useTranslation();
  const { lessonsId, label } = props;
  const location = useLocation();

  const pathname = `/play${
    lessonsId ? `/${GameType.CardQuiz}/${lessonsId.join(',')}` : ''
  }`;

  return (
    <Button
      to={pathname}
      state={{
        prevPath: location.pathname,
      }}
      white
      block
    >
      <Row gutter={0.5} flexWrap="nowrap">
        <Col gutter={0.5}>
          <Span color="green">
            <Icon name="play-solid" />
          </Span>
        </Col>
        <Col gutter={0.5} display={{ xs: 'none', sm: 'block' }}>
          {label || t('button.studyGames')}
        </Col>
      </Row>
    </Button>
  );
};
