import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import { RxDocument } from 'rxdb';

import { media } from '../../../../../styles/media';
import { AudioDocType } from '../../../../../database/schemas/audio';
import { DeleteAudio } from './DeleteAudio';
import { Col, Row } from '../../../../components/Grid';
import { Card } from '../../../../components/Card';
import { AudioPlayer } from '../../../../components/AudioPlayer';
import { useMediaFile } from '../../../../hooks/useMediaFile';
import { NotSynced } from '../../../../components/NotSynced';
import { SharedBlock } from '../../../../components/SharedBlock';

interface Props {
  audio: RxDocument<AudioDocType>;
  onDelete: (document: RxDocument<AudioDocType>) => void;
}

export function AudioListItem(props: Props) {
  const { audio, onDelete } = props;
  const { title } = audio;

  const onDeleteButtonClick = useCallback(async () => {
    onDelete(audio);
  }, [audio._data._rev]);

  const shared = audio._data.shared;
  const { src, loading } = useMediaFile({ path: audio._data.src });

  return (
    <Wrapper>
      <Row>
        <Col xs="auto" alignSelf="center" order={1}>
          <b>{title}</b>
        </Col>
        <Col
          xs={12}
          md={8}
          alignSelf="center"
          order={{ xs: 3, md: 2 }}
          gutterY={{ xs: 0.5, md: 0 }}
          gutter={{ xs: 1, md: 4 }}
        >
          {src ? (
            <AudioPlayer src={src} />
          ) : (
            <NotSynced loading={loading} horizontal />
          )}
        </Col>
        <Col order={{ xs: 2, md: 3 }}>
          <SharedBlock shared={shared}>
            <DeleteAudio onDelete={onDeleteButtonClick} title={title} />
          </SharedBlock>
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled(Card)`
  display: block;
  padding: 0.75rem 0.75rem 1rem 0.75rem;
  position: relative;
  margin-top: -1px;

  ${media.md} {
    padding: 1.125rem 1.5rem;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 1rem;
    right: 1rem;
    height: 1px;
    background: var(--grey-150);

    ${media.md} {
      left: 1.5rem;
      right: 1.5rem;
    }
  }

  &:first-child {
    margin-top: 0;

    &:before {
      display: none;
    }
  }
`;

const AudioName = styled.div`
  padding: 0.25rem 0;

  ${media.md} {
    padding: 0;
  }
`;
