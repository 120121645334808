import React, { useContext, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import styled, { useTheme } from 'styled-components/macro';

import { Col, Row } from '../../../components/Grid';
import { Heading } from '../../../components/Heading';
import { FileField, InputField } from '../../../components/Fields';
import { Label } from '../../../components/Label';
import { ErrorMessage } from '../../../components/ErrorMessage';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { AuthContext } from '../../../context/AuthContext';
import { stringSchema } from '../../../../utils/validation-schemas';
import { media } from '../../../../styles/media';

const validationSchema = Yup.object({
  email: Yup.string().email().required(),
  name: stringSchema,
});

export type UserValues = Yup.InferType<typeof validationSchema>;

export const ProfileCard = () => {
  const isMd = useMediaQuery(useTheme()?.breakpoints.up('md'));
  const { t } = useTranslation();
  const { user, updateUserData } = useContext(AuthContext);
  const [showError, setShowError] = useState(false);

  const onSubmit = async values => {
    setShowError(false);

    const error = await updateUserData(values);

    if (error) {
      setShowError(true);
    }
  };

  return (
    <Card padding={isMd ? 2 : 1.5}>
      <Row>
        <Col xs={12}>
          <Heading level={3}>{t('title.profile')}</Heading>
        </Col>
        <Col xs={12} gutterY={1.5}>
          <Formik
            initialValues={{
              email: user.email,
              name: user.name,
              photo_src: user.photo_src,
            }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ handleSubmit, isSubmitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={12}>
                      <Profile>
                        <div></div>
                        <div>
                          <FileField name="photo_src" />
                        </div>

                        <div>
                          <Label htmlFor="email">{t('label.email')}</Label>
                        </div>
                        <div>
                          <InputField
                            id="email"
                            name="email"
                            placeholder={t('label.email')}
                          />
                        </div>
                        <div>
                          <Label htmlFor="name">{t('label.name')}</Label>
                        </div>
                        <div>
                          <InputField
                            id="name"
                            name="name"
                            placeholder={t('label.name')}
                          />
                        </div>
                      </Profile>
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col xs="auto">
                          {showError ? (
                            <ErrorMessage>{t('message.getWrong')}</ErrorMessage>
                          ) : null}
                        </Col>
                        <Col>
                          <Button type="submit" yellow $loading={isSubmitting}>
                            {t('button.save')}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </form>
              );
            }}
          </Formik>
        </Col>
      </Row>
    </Card>
  );
};

const Profile = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto auto;
  gap: 1rem;
  grid-template-areas:
    '. .'
    '. .'
    '. .'
    '. .';

  ${media.md} {
    gap: 1.5rem;
  }

  & > div {
    align-self: center;
  }
`;
