import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { RxDocument } from 'rxdb';
import { useTranslation } from 'react-i18next';

import {
  LessonCard,
  LessonCardContent,
  LessonCardFooter,
  LessonCardFront,
  LessonCardLayout,
} from '../../../../components/LessonCard';
import { Col, Row } from '../../../../components/Grid';
import { GameContext } from '../../GameContext';
import card_quiz from '../../../GamesPage/assets/card_quiz.png';
import mock_test from '../../../GamesPage/assets/mock_test.png';
import speak_game from '../../../GamesPage/assets/speak_game.png';
import { LessonDocType } from '../../../../../database/schemas/lesson';
import { FlashCardDocType } from '../../../../../database/schemas/flashcard';
import { Button } from '../../../../components/Button';
import { GameRetryButton } from './GameRetryButton';
import { Hr } from '../../../../components/Hr';
import { GameType } from '../../../../../types/Game';
import { Result } from './Result';

interface Props {
  flashcards: RxDocument<FlashCardDocType>[];
  lessons: RxDocument<LessonDocType>[];
  slidesCount: number;
}

export const GameResult = (props: Props) => {
  const { flashcards, lessons, slidesCount } = props;
  const { correctAnswers } = useContext(GameContext);
  const { gameType } = useParams<{ gameType: GameType }>();
  const { t } = useTranslation();

  return (
    <Row height="100%" flexDirection="column" alignContent="center">
      <Col xs="auto" style={{ position: 'relative' }}>
        <LessonCard>
          <LessonCardFront>
            <LessonCardLayout gridRows="auto 0fr 0fr 0fr 0fr">
              <LessonCardContent>
                <Row justifyContent="center" height="100%">
                  <Col xs={6} md={4} gutterY={2}>
                    {gameType === GameType.CardQuiz && (
                      <img src={card_quiz} alt={card_quiz} />
                    )}
                    {gameType === GameType.MockTest && (
                      <img src={mock_test} alt={mock_test} />
                    )}
                    {gameType === GameType.SpeakGame && (
                      <img src={speak_game} alt={speak_game} />
                    )}
                  </Col>
                  <Col xs={12} gutterY={2}>
                    <Row>
                      {lessons.map((lesson, index) => {
                        return (
                          <Col xs={12} gutterY={1} key={lesson.id}>
                            <Row gutterY={1} gutter={1}>
                              {index !== 0 && (
                                <Col xs={12} gutterY={0.5} gutter={1}>
                                  <Hr />
                                </Col>
                              )}
                              <Result
                                flashcards={flashcards}
                                lesson={lesson}
                                gameType={gameType as GameType}
                              />
                            </Row>
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
              </LessonCardContent>
              <LessonCardFooter>
                <Row justifyContent="center" gutterY={1}>
                  {Object.keys(correctAnswers).length < slidesCount ? (
                    <Col gutterY={1}>
                      <GameRetryButton />
                    </Col>
                  ) : null}
                  <Col gutterY={1}>
                    <Button to="/play" yellow>
                      {t('button.continue')}
                    </Button>
                  </Col>
                </Row>
              </LessonCardFooter>
            </LessonCardLayout>
          </LessonCardFront>
        </LessonCard>
      </Col>
    </Row>
  );
};
