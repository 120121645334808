import styled from 'styled-components/macro';
import { prop } from 'styled-tools';

export const LessonCardLayout = styled.div<{ gridRows?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${prop('gridRows', '0fr auto 0fr 0fr 0fr')};
  gap: 0;
  grid-template-areas:
    '.'
    '.'
    '.'
    '.'
    '.';
`;
