import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RxDocument } from 'rxdb';

import {
  LessonCard,
  LessonCardBack,
  LessonCardContent,
  LessonCardContextProvider,
  LessonCardFront,
  LessonCardHeader,
  LessonCardLayout,
  LessonFlipButton,
} from '../../../../components/LessonCard';
import { FlashCardDocType } from '../../../../../database/schemas/flashcard';
import { AnswerType, QuestionType } from '../../../../../types/Lesson';
import { MultipleChoiceAnswer, TextAnswer } from './Answer';
import {
  AudioQuestion,
  MultipleChoiceAnswers,
  PhotoQuestion,
  TextQuestion,
} from './Question';
import { FlashCardForm } from '../FlashCardForm';
import { Col, Row } from '../../../../components/Grid';

interface Props {
  flashcard: RxDocument<FlashCardDocType>;
  onDelete: (document: RxDocument<FlashCardDocType>) => void;
}

export function FlashCard(props: Props) {
  const { flashcard, onDelete } = props;
  const { t } = useTranslation();
  const { question_type, answer_type, shared } = flashcard;
  const [editFlashcard, setEditFlashcard] = useState(false);

  const onDeleteButtonClick = useCallback(async () => {
    onDelete(flashcard);
  }, [flashcard._data._rev]);

  const onEdit = useCallback(async () => {
    setEditFlashcard(true);
  }, []);

  const onEditCancel = useCallback(() => {
    setEditFlashcard(false);
  }, []);

  if (editFlashcard) {
    return <FlashCardForm onCancel={onEditCancel} flashcard={flashcard} />;
  }

  return (
    <LessonCardContextProvider flippable>
      <LessonCard>
        <LessonCardFront>
          <LessonCardLayout>
            <LessonCardHeader
              title={t('label.question')}
              onDelete={shared ? undefined : onDeleteButtonClick}
              onEdit={shared ? undefined : onEdit}
            />
            <LessonCardContent>
              {question_type === QuestionType.Text && (
                <TextQuestion flashcard={flashcard} />
              )}
              {question_type === QuestionType.Spelling && (
                <TextQuestion flashcard={flashcard} />
              )}
              {question_type === QuestionType.Photo && (
                <PhotoQuestion flashcard={flashcard} />
              )}
              {question_type === QuestionType.Audio && (
                <AudioQuestion flashcard={flashcard} />
              )}
              {question_type === QuestionType.WebImage && (
                <PhotoQuestion flashcard={flashcard} />
              )}
              {answer_type === AnswerType.MultipleChoice && (
                <Row>
                  <Col xs={12} gutterY={2}>
                    <MultipleChoiceAnswers flashcard={flashcard} />
                  </Col>
                </Row>
              )}
            </LessonCardContent>
            <LessonFlipButton
              $hidden={question_type === QuestionType.Spelling}
            />
          </LessonCardLayout>
        </LessonCardFront>
        <LessonCardBack>
          <LessonCardLayout>
            <LessonCardHeader
              title={t('label.answer')}
              onDelete={shared ? undefined : onDeleteButtonClick}
              onEdit={shared ? undefined : onEdit}
            />
            <LessonCardContent>
              {answer_type === AnswerType.Text ? (
                <TextAnswer flashcard={flashcard} />
              ) : null}
              {answer_type === AnswerType.MultipleChoice ? (
                <MultipleChoiceAnswer flashcard={flashcard} />
              ) : null}
            </LessonCardContent>
            {question_type !== QuestionType.Spelling ? (
              <LessonFlipButton />
            ) : null}
          </LessonCardLayout>
        </LessonCardBack>
      </LessonCard>
    </LessonCardContextProvider>
  );
}
