import styled from 'styled-components/macro';

export const LessonCardBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  overflow: hidden;
`;
