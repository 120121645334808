import React from 'react';
import styled from 'styled-components/macro';

import { Container } from '../../../../components/Container';
import { Col, Row } from '../../../../components/Grid';
import { ButtonIcon } from '../../../../components/ButtonIcon';
import { Icon } from '../../../../components/Icon';
import { Span } from '../../../../components/Span';
import { prop } from 'styled-tools';
import { Header } from '../../../../components/Header';
import { useSwiper } from 'swiper/react';
import { useLocation } from 'react-router';

interface Props {
  index: number;
  slidesCount: number;
}
export function GameHeader(props: Props) {
  const { index, slidesCount } = props;
  const swiper = useSwiper();
  const progress = (index / slidesCount) * 100;
  const location = useLocation() as { state: { prevPath?: string } };

  const onClick = () => {
    swiper.slideNext();
  };

  return (
    <Header>
      <Container>
        <Row alignItems="center" flexWrap="nowrap" gutter={1}>
          <Col gutter={{ xs: 0.5, md: 1 }}>
            <ButtonIcon
              to={location?.state?.prevPath || '/play'}
              block
              withPadding
            >
              <Span color="grey">
                <Icon name="cross" />
              </Span>
            </ButtonIcon>
          </Col>
          <Col xs="auto" gutter={{ xs: 0.5, md: 1 }}>
            <ProgressBar progress={progress} />
          </Col>
          <Col gutter={{ xs: 0.5, md: 1 }}>
            <ButtonIcon block withPadding onClick={onClick}>
              <Span color="grey">
                <Icon name="chevron-right-1" />
              </Span>
            </ButtonIcon>
          </Col>
        </Row>
      </Container>
    </Header>
  );
}

const ProgressBar = styled.div<{ progress: number }>`
  position: relative;
  overflow: hidden;
  height: 1rem;
  background-color: var(--grey-150);
  border-radius: 20px;
  max-width: 24rem;
  margin: 0 auto;

  ::after {
    content: '';
    position: absolute;
    width: ${prop('progress', 0)}%;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: #ffa000;
    background-image: linear-gradient(
      -45deg,
      #ffc107 25%,
      transparent 25%,
      transparent 50%,
      #ffc107 50%,
      #ffc107 75%,
      transparent 75%,
      transparent
    );
    background-repeat: repeat-x;
    background-size: 40px 40px;
    transition: width 0.25s;
  }
`;
