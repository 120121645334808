import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { RxDocument } from 'rxdb';
import { useTranslation } from 'react-i18next';

import { Col, Row } from '../../../components/Grid';
import { LessonDocType } from '../../../../database/schemas/lesson';
import { Icon, IconName } from '../../../components/Icon';
import { media } from '../../../../styles/media';
import { Link } from '../../../components/Link';
import Rating from '../../../components/Rating';
import { LessonStatus } from './LessonStatus';
import { SharedBlock } from '../../../components/SharedBlock';
import { ContextMenu } from '../../../components/ContextMenu';
import { useFlashcardCount } from '../../../hooks/useFlashcardCount';
import { useAudioCount } from '../../../hooks/useAudioCount';
import { useBoardCount } from '../../../hooks/useBoardCount';
import { Span } from '../../../components/Span';

interface Props {
  lesson: RxDocument<LessonDocType>;
  onDelete: (document: RxDocument<LessonDocType>) => void;
  onEdit: (document: RxDocument<LessonDocType>) => void;
  score: number;
  style?: string;
  measure?: () => void;
}

export function LessonListItem(props: Props) {
  const { lesson, onEdit, onDelete, score, measure } = props;
  const { title, class_id, id } = lesson?._data || {};
  const { t } = useTranslation();
  const flashcardsCount = useFlashcardCount({
    classId: class_id,
    lessonId: id,
  });
  const audioCount = useAudioCount({ classId: class_id, lessonId: id });
  const boardCount = useBoardCount({ classId: class_id, lessonId: id });

  useEffect(() => {
    measure?.();
  }, [flashcardsCount, audioCount, boardCount, title]);

  const contextMenuOptions = useMemo(
    () => [
      {
        label: t('button.edit'),
        value: 'edit',
        callback: () => {
          onEdit(lesson);
        },
        icon: 'pencil-8' as IconName,
      },
      {
        label: t('button.delete'),
        value: 'delete',
        callback: () => {
          onDelete(lesson);
        },
        icon: 'trash-4' as IconName,
        color: 'red',
      },
    ],
    [lesson._data._rev],
  );

  const counts = [
    {
      icon: 'stack',
      count: flashcardsCount,
    },
    {
      icon: 'microphone',
      count: audioCount,
    },
    {
      icon: 'document-text',
      count: boardCount,
    },
  ].filter(({ count }) => count);

  return (
    <Wrapper to={`/lesson/${lesson.id}/flashcards`}>
      <Row>
        <Col xs="auto" alignSelf="center">
          <LessonName>
            <Row alignItems="center">
              <Col xs={12} md="auto" alignSelf="center">
                <Row
                  alignItems={{ xs: 'flex-start', md: 'center' }}
                  gutter={{ xs: 0.5, md: 2 }}
                >
                  <Col
                    xs="auto"
                    md={9}
                    order={{ xs: 2, md: 1 }}
                    gutter={{ xs: 0.5, md: 2 }}
                  >
                    <Row>
                      <Col xs={12}>
                        <b>{title}</b>
                      </Col>
                      <Col xs={12} gutterY={{ xs: 0.125, md: 0 }}>
                        <Row alignItems="center" gutterY={0.25} gutter={0.5}>
                          <Col gutterY={0.25} gutter={0.5}>
                            <Row
                              gutter={0.5}
                              flexWrap="nowrap"
                              alignItems="center"
                            >
                              {counts.map(({ icon, count }, index) => {
                                if (!count) return null;

                                return (
                                  <>
                                    {index > 0 && (
                                      <Col
                                        gutter={0.5}
                                        display={{
                                          xs: index === 0 ? 'none' : 'block',
                                          md: 'block',
                                        }}
                                      >
                                        <Dot color="grey">•</Dot>
                                      </Col>
                                    )}
                                    <Col gutter={0.5}>
                                      <Span color="grey">
                                        <Row
                                          flexWpap="nowrap"
                                          alignItems="center"
                                          gutter={0.25}
                                        >
                                          <Col gutter={0.25}>
                                            <Icon
                                              $size={20}
                                              name={icon as IconName}
                                              $block
                                            />
                                          </Col>
                                          <Col gutter={0.25}>{count}</Col>
                                        </Row>
                                      </Span>
                                    </Col>
                                  </>
                                );
                              })}
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs="18px"
                    md={3}
                    order={{ xs: 1, md: 2 }}
                    gutter={{ xs: 0.5, md: 2 }}
                  >
                    <LessonStatus lessonId={lesson.id} score={score} />
                  </Col>
                </Row>
              </Col>
              <Col gutterY={{ xs: 0.125, md: 0 }} gutter={{ xs: 3, md: 1 }}>
                <Rating numberOfStars={3} rating={score} />
              </Col>
            </Row>
          </LessonName>
        </Col>
        <Col xs={{ xs: 12, md: 6 }}>
          <SharedBlock shared={lesson?._data?.shared}>
            <ContextMenu options={contextMenuOptions} asButton />
          </SharedBlock>
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled(Link)`
  display: block;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  position: relative;

  ${media.md} {
    padding: 1.125rem 1rem 1.125rem 1.5rem;
  }

  &:hover {
    background-color: rgba(var(--blue-100-rgb), 0.1);
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    left: 1rem;
    right: 1rem;
    height: 1px;
    background: var(--grey-150);

    ${media.md} {
      left: 1.5rem;
      right: 1.5rem;
    }
  }

  &:first-child {
    margin-top: 0;

    /*&:before {
      display: none;
    }*/
  }
`;

const LessonName = styled.div`
  padding: 0.25rem 0;

  ${media.md} {
    padding: 0;
  }
`;

const Dot = styled(Span)`
  font-family: 'sans-serif' !important;
  font-size: 0.75rem;
`;
