import { replicateRxCollection } from 'rxdb/plugins/replication';
import { ReplicationPullHandlerResult } from 'rxdb/dist/types/types/plugins/replication';

import { ScoreDocType } from './schema';
import { api } from '../../../services/api';
import { Score } from '../../../types/Score';

type Checkpoint = Pick<ScoreDocType, 'id' | 'updated_at'>;

export async function scoreReplication(collection) {
  return replicateRxCollection<ScoreDocType, Checkpoint>({
    collection,
    replicationIdentifier: `${process.env.REACT_APP_API_URL}/score`,
    autoStart: true,
    live: true,
    deletedField: 'deleted_at',
    push: {
      batchSize: 1000,
      async handler(docs) {
        const conflicts = await api.post(`/score/sync/`, docs);
        return conflicts.data;
      },
    },
    pull: {
      batchSize: 1000,
      async handler(
        lastCheckpoint,
        batchSize,
      ): Promise<ReplicationPullHandlerResult<Score, Checkpoint>> {
        const minTimestamp = lastCheckpoint
          ? lastCheckpoint?.updated_at || new Date(0)
          : new Date(0);

        const response = await api.get(`/score/sync`, {
          params: {
            last_checkpoint: minTimestamp,
            batchSize,
          },
        });

        const documents = response.data || [];
        const documentsLength = documents.length;
        const lastDocument = documents[documentsLength - 1];

        const checkpoint = documentsLength
          ? {
              id: lastDocument.id,
              updated_at: lastDocument.updated_at,
            }
          : lastCheckpoint;

        return {
          documents,
          checkpoint,
        };
      },
    },
  });
}
