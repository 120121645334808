import styled, { css } from 'styled-components/macro';

import { media } from '../../../styles/media';
import { ifProp } from 'styled-tools';

export const LessonCardFooter = styled.div<{ $hidden?: boolean }>`
  padding-top: 1rem;
  margin: 0 1rem 1rem;
  position: relative;
  border-top: 1px solid var(--grey-150);

  ${ifProp(
    '$hidden',
    css`
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
    `,
  )}

  ${media.md} {
    padding-top: 1.5rem;
    margin: 0 1.5rem 1.5rem;
  }
`;
