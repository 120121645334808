import React, { PropsWithChildren } from 'react';
import { useField } from 'formik';
import { ifProp } from 'styled-tools';
import styled, { css } from 'styled-components/macro';
import { media } from '../../../../../../styles/media';
import { FieldAttributes } from 'formik/dist/Field';

interface Props extends FieldAttributes<any> {}

export function TypeRadioField(props: PropsWithChildren<Props>) {
  const { name, value, children, disabled, onChange } = props;
  const [field, meta, helpers] = useField(name);
  const checked = meta.value === value;

  return (
    <Wrapper checked={checked} disabled={disabled}>
      <Input
        {...field}
        name={name}
        value={value}
        checked={checked}
        onChange={event => {
          onChange && onChange(event);
          field.onChange(event);
        }}
      />
      {children}
    </Wrapper>
  );
}

export const Wrapper = styled.label<{
  checked?: boolean;
  disabled?: boolean;
}>`
  display: block;
  position: relative;
  cursor: pointer;
  padding: 1rem;
  margin-top: -1px;

  ${media.md} {
    padding: 1.5rem;
  }

  &:before {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 1rem;
    right: 1rem;
    border-bottom: 1px solid var(--grey-150);
    transform: translateZ(0);

    ${media.md} {
      left: 1.5rem;
      right: 1.5rem;
    }
  }

  &:hover {
    transition: background-color 0.2s;
    background-color: rgba(var(--blue-100-rgb), 0.1);
  }

  ${ifProp(
    'checked',
    css`
      background-color: rgba(var(--blue-100-rgb), 0.3);

      &:hover {
        background-color: rgba(var(--blue-100-rgb), 0.3);
      }
    `,
  )}

  ${ifProp(
    'disabled',
    css`
      pointer-events: none;
      opacity: 0.5;
    `,
  )}
`;

const Input = styled.input.attrs({ type: 'radio' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
