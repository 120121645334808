import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Button as OriginButton } from '../../../../components/Button';
import { Col, Row } from '../../../../components/Grid';
import { Icon } from '../../../../components/Icon';
import { base64MimeType } from '../../../../../utils/file-manipulatiion';
import { Span } from '../../../../components/Span';

const reader = new FileReader();

interface Props {
  onUpload: ({ type, data }: { type: string; data: string }) => void;
}

export function AudioUpload(props: Props) {
  const { onUpload } = props;
  const { t } = useTranslation();
  const [type, setType] = useState<string | undefined>(undefined);

  const onFileRead = event => {
    onUpload({
      data: event.target.result,
      type: base64MimeType(event.target.result),
    });
  };

  useEffect(() => {
    reader.addEventListener('load', onFileRead);
    return () => {
      reader.removeEventListener('load', onFileRead);
    };
  }, []);

  const onDrop = acceptedFiles => {
    reader.readAsDataURL(acceptedFiles[0]);
  };

  const { getInputProps, open } = useDropzone({
    accept: {
      'audio/*': [],
    },
    multiple: false,
    onDrop,
  });

  return (
    <>
      <input {...getInputProps()} />
      <Button onClick={open} white block>
        <Row gutter={0.5}>
          <Col gutter={0.5}>
            <Span color="red">
              <Icon name="cloud-upload-solid" />
            </Span>
          </Col>
          <Col gutter={0.5} display={{ xs: 'none', md: 'block' }}>
            <Span color="red">{t('button.upload')}</Span>
          </Col>
        </Row>
      </Button>
    </>
  );
}

const Button = styled(OriginButton)`
  color: var(--red);
`;
