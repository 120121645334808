import React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';

import { Col, Row } from '../Grid';
import { Heading } from '../Heading';
import { ReactComponent as LogoSvg } from './assets/logo.svg';

export function Logo() {
  const isMd = useMediaQuery(useTheme()?.breakpoints.up('md'));

  return (
    <Wrapper>
      <Row alignItems="center" flexWrap="nowrap">
        <Col>
          <LogoSvg />
        </Col>
        <Col>
          <Heading level={isMd ? 2 : 3}>classmaster.io</Heading>
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  svg {
    width: 44px;
    height: 44px;
  }
`;
