import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import { RxDocument } from 'rxdb';
import styled from 'styled-components/macro';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { api } from '../../../../services/api';
import { ClassDocType } from '../../../../database/schemas/class';
import { Col, Row } from '../../../components/Grid';
import { Heading } from '../../../components/Heading';
import { Block } from '../../../components/Block';
import { Button } from '../../../components/Button';
import { Modal } from '../../../components/Modal';
import { Label } from '../../../components/Label';
import { Tabs } from '../../../components/Tabs';
import { Option } from '../../../components/Select/types';
import { Textarea } from '../../../components/Textarea';
import { ButtonIcon, StyledButtonIcon } from '../../../components/ButtonIcon';
import { Icon } from '../../../components/Icon';

enum ShareTokenLifetime {
  SingleTime = 'one-off',
  Reusable = 'reusable',
}

enum ShareTokenAccessType {
  Read = 'read-only',
  Copy = 'copy',
}

interface Props {
  document: RxDocument<ClassDocType> | null;
  onClose: () => void;
}

export function ShareClass(props: Props) {
  const { t, i18n } = useTranslation();
  const { document, onClose } = props;
  const [copied, setCopied] = useState(false);

  const onCopy = useCallback(() => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (!document) {
      setCopied(false);
    }
  }, [document?._data.id]);

  const lifetimeOptions: Option<string>[] = useMemo(
    () => [
      {
        value: ShareTokenLifetime.SingleTime,
        label: t('label.oneTime'),
      },
      {
        value: ShareTokenLifetime.Reusable,
        label: t('label.reusable'),
      },
    ],
    [i18n.language],
  );

  const accessOptions: Option<string>[] = useMemo(
    () => [
      {
        value: ShareTokenAccessType.Read,
        label: t('classes.readOnly'),
      },
      {
        value: ShareTokenAccessType.Copy,
        label: t('classes.copy'),
      },
    ],
    [],
  );

  const [lifetimeValue, setLifetimeValue] = useState(lifetimeOptions[0]);
  const [accessValue, setAccessValue] = useState(accessOptions[0]);

  const { value, loading } = useAsync(async () => {
    if (document) {
      const response = await api.get(
        `/class/share/link/${document?._data?.id}/${lifetimeValue.value}/${accessValue.value}`,
      );

      return response.data;
    }
  }, [lifetimeValue.value, accessValue.value, document?._data?.id]);

  const link = useMemo(() => {
    if (!value?.token) return '';

    return `${process.env.REACT_APP_URL}/share/class/${value?.token || ''}`;
  }, [value?.token]);

  if (!document) return null;

  const { title } = document._data;

  return (
    <Modal
      width={30 * 16}
      isOpen
      onBackgroundClick={onClose}
      onEscapeKeydown={onClose}
    >
      <Row justifyContent="center">
        <Col xs={12}>
          <Heading level={3}>
            <Block textCenter>{t('title.shareClass', { title })}</Block>
          </Heading>
        </Col>
        <Col xs={12} gutterY={2}>
          <Row>
            <Col xs={12}>
              <Label>{t('label.lifetime')}</Label>
            </Col>
            <Col gutterY={0.25}>
              <Tabs<string>
                options={lifetimeOptions}
                value={lifetimeValue}
                onChange={setLifetimeValue}
              />
            </Col>
          </Row>
        </Col>
        {/*<Col xs={12} gutterY={1.5}>
          <Row>
            <Col xs={12}>
              <Label>{t('classes.access')}</Label>
            </Col>
            <Col gutterY={0.25}>
              <Tabs<string>
                options={accessOptions}
                value={accessValue}
                onChange={setAccessValue}
              />
            </Col>
          </Row>
        </Col>*/}
        <Col xs={12} gutterY={1.5}>
          <Row>
            <Col xs={12}>
              <Label>{t('label.link')}</Label>
            </Col>
            <Col gutterY={0.25} xs={12}>
              <TextareaContainer>
                <Textarea readOnly value={link} />
                <CopyToClipboard text={link} onCopy={onCopy}>
                  <ButtonIcon withPadding>
                    <Icon name={copied ? 'checkmark' : 'copy'} />
                  </ButtonIcon>
                </CopyToClipboard>
              </TextareaContainer>
            </Col>
          </Row>
        </Col>
        <Col xs={12} gutterY={2}>
          <Row justifyContent="center">
            <Col>
              <Button border onClick={onClose}>
                {t('button.close')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
}

const TextareaContainer = styled.div`
  position: relative;

  textarea {
    resize: none;
    padding-right: 50px;
  }

  ${StyledButtonIcon} {
    position: absolute;
    right: 0;
    top: 0;
  }
`;
