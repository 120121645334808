import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createGlobalStyle } from 'styled-components';

import Paragraph from '@editorjs/paragraph';
import EditorJS, { OutputData } from '@editorjs/editorjs';
import Header from '@editorjs/header';
import NestedList from '@editorjs/nested-list';
import Code from '@editorjs/code';
import Quote from '@editorjs/quote';
import Delimiter from '@editorjs/delimiter';
import Table from '@editorjs/table';
import Checklist from '@editorjs/checklist';
import Marker from '@editorjs/marker';
import Alert from 'editorjs-alert';
import ImageTool from '@editorjs/image';

import './alert.css';
import { BoardImage } from '../../../types/Board';

interface Props {
  defaultValue?: OutputData | undefined;
  uploadByFile: (file: File) => Promise<
    {
      success: number;
    } & BoardImage
  >;
  onSave: (value: OutputData | undefined) => Promise<void>;
  readOnly?: boolean;
}

const EDITTOR_HOLDER_ID = 'editorjs';

export function Editor(props: Props) {
  const { defaultValue, uploadByFile, onSave, readOnly } = props;
  const { t } = useTranslation();
  const ejInstance = useRef<EditorJS | null>(null);

  const onChange = async () => {
    if (ejInstance.current?.saver) {
      let content = await ejInstance.current.saver.save();
      await onSave(content);
    }
  };

  const initEditor = () => {
    ejInstance.current = new EditorJS({
      holder: EDITTOR_HOLDER_ID,
      autofocus: true,
      data: defaultValue,
      onChange,
      placeholder: t('message.letsWrite'),
      tools: {
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
          config: {
            preserveBlank: true,
          },
        },
        header: {
          class: Header,
          inlineToolbar: true,
        },
        list: {
          class: NestedList,
          inlineToolbar: true,
        },
        code: {
          class: Code,
          inlineToolbar: true,
        },
        quote: {
          class: Quote,
          inlineToolbar: true,
        },
        delimiter: {
          class: Delimiter,
          inlineToolbar: true,
        },
        table: {
          class: Table,
          inlineToolbar: true,
          config: {
            withHeadings: true,
          },
        },
        alert: {
          class: Alert,
          inlineToolbar: true,
        },
        checklist: {
          class: Checklist,
          inlineToolbar: true,
        },
        marker: Marker,
        image: {
          class: ImageTool,
          config: {
            uploader: {
              uploadByFile,
            },
          },
        },
      },
      readOnly: readOnly || false,
    });
  };

  const removeEditor = async () => {
    try {
      await ejInstance.current?.isReady;

      if (!ejInstance.current?.destroy) return false;

      ejInstance.current?.destroy();
      ejInstance.current = null;

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const reinit = async () => {
    const removed = await removeEditor();

    if (removed) {
      initEditor();
    }
  };

  useEffect(() => {
    if (ejInstance.current === null) {
      initEditor();
    }

    return () => {
      removeEditor();
    };
  }, []);

  useEffect(() => {
    reinit();
  }, [!defaultValue]);

  return (
    <>
      <div id={EDITTOR_HOLDER_ID}></div>
      <EditorStyles />
    </>
  );
}

const EditorStyles = createGlobalStyle`
  @media (min-width: 650px) {
    .ce-block__content,
    .ce-toolbar__content {
      max-width: calc(100% - 100px);
    }
  }

  .image-tool__image-picture {
    max-height: 40vh;
  }

  .ce-block--stretched {
    .ce-block__content {
      max-width: calc(100% - 100px);

      .image-tool__image-picture {
        max-height: none;
      }
    }
  }
  .image-tool {
    .image-tool--stretched {

    }
  }
`;
