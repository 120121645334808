import * as Yup from 'yup';

export const passwordSchema = Yup.string()
  .required('errorMessage.required')
  .min(6, 'errorMessage.passwordMinLength')
  .max(20, 'errorMessage.passwordMaxLength')
  .matches(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{6,20}$/,
    'errorMessage.passwordCharacters',
  );

export const stringSchemaOptional = Yup.string().optional();
export const stringSchema = Yup.string().required('errorMessage.required');
export const numberSchema = Yup.number().required('errorMessage.required');

export const numberSchemaOptional = Yup.number().optional();

export const emailSchema = Yup.string()
  .required('errorMessage.required')
  .email('errorMessage.email');

export const optionSchema = Yup.object({
  value: Yup.mixed(),
  label: Yup.string(),
}).required('errorMessage.required');

export const dateSchema = Yup.date().required('errorMessage.required');

export const booleanSchema = Yup.boolean()
  .isTrue()
  .required('errorMessage.required');

export const fileSchema = Yup.object().required('errorMessage.required');

export const urlSchema = Yup.string()
  .matches(
    /^(?=.{4,2048}$)((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]{1,63}(\.[a-zA-Z]{1,63}){1,5}(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
    'Enter correct url!',
  )
  .required('errorMessage.required');

export const urlSchemaOptional = Yup.string()
  .matches(
    /^(?=.{4,2048}$)((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]{1,63}(\.[a-zA-Z]{1,63}){1,5}(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
    'Enter correct url!',
  )
  .optional();
