import React, { useEffect, useState } from 'react';
import WindowScroller from 'react-virtualized/dist/commonjs/WindowScroller';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import CellMeasurer from 'react-virtualized/dist/commonjs/CellMeasurer';
import CellMeasurerCache from 'react-virtualized/dist/commonjs/CellMeasurer/CellMeasurerCache';
import { Col, Row } from 'app/components/Grid';
import styled from 'styled-components/macro';
import { useRxData } from 'rxdb-hooks';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';
import { useWindowSize } from 'react-use';

import { PageWrapper } from '../../components/PageWrapper';
import { GamesHeader } from './Header';
import { DropDown } from './DropDown';
import { Container } from '../../components/Container';
import { ClassDocType } from '../../../database/schemas/class';
import { CollectionName } from '../../../database/types';
import { LanguageSelect } from './components/LanguageSelect';
import { Footer } from './Footer';
import { GameType } from '../../../types/Game';
import { GamesSlider } from './GamesPageSlider';
import { NumberField } from '../../components/Fields';
import { media } from '../../../styles/media';

const cache = new CellMeasurerCache({
  fixedWidth: false,
  defaultHeight: 72,
});

export function GamesPage() {
  const { t, i18n } = useTranslation();
  const [gameType, setGameType] = useState<GameType>(GameType.CardQuiz);
  const { width } = useWindowSize();

  const { result: classes } = useRxData<ClassDocType>(
    CollectionName.Classes,
    collection => collection.find({ sort: [{ created_at: 'desc' }] }),
  );

  useEffect(() => {
    cache.clearAll();
  }, [classes?.length, width]);

  const onSubmit = () => {};

  const rowRenderer = ({ index, key, style, parent }) => {
    const data = classes[index];

    if (!data) return null;

    const { id, _rev } = data._data;

    return (
      <CellMeasurer cache={cache} key={key} parent={parent} rowIndex={index}>
        {({ registerChild, measure }) => (
          <div ref={registerChild} style={style}>
            <Col xs={12} gutterY={0.75}>
              <DropDown
                key={`${id}_${_rev}`}
                data={data}
                gameType={gameType}
                measure={measure}
              />
            </Col>
          </div>
        )}
      </CellMeasurer>
    );
  };

  return (
    <>
      <GamesHeader />
      <PageWrapper>
        <Wrapper>
          <Row gutter={0}>
            <Col xs={12} gutter={0}>
              <GamesSlider onGameChange={setGameType} />
            </Col>
            <Col xs={12} gutter={0}>
              <Formik<{
                classes: { [key: string]: string[] };
                language: string;
                cardsCount: number;
              }>
                initialValues={{
                  classes: {},
                  language: i18n.language,
                  cardsCount: 10,
                }}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {({ values }) => {
                  return (
                    <form>
                      <Container $fixed>
                        <Row>
                          <Col xs={12}>
                            <Row
                              justifyContent="flex-end"
                              alignItems="center"
                              flexWrap="nowrap"
                            >
                              <Col
                                order={gameType === GameType.MockTest ? 2 : 1}
                              >
                                <TopBar
                                  $hidden={gameType !== GameType.MockTest}
                                >
                                  <Label>
                                    <Row gutter={0.5} alignItems="center">
                                      <Col gutter={0.5}>
                                        {t('message.numberOfCards')}
                                      </Col>
                                      <Col gutter={0.5}>
                                        <CardsCount name="cardsCount" />
                                      </Col>
                                    </Row>
                                  </Label>
                                </TopBar>
                              </Col>
                              <Col
                                order={gameType === GameType.MockTest ? 1 : 2}
                              >
                                <TopBar
                                  $hidden={gameType !== GameType.SpeakGame}
                                >
                                  <LanguageSelect
                                    name="language"
                                    label={t('label.speakingLanguage')}
                                  />
                                </TopBar>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12} gutterY={0.75}>
                            <Row gutterY={0.75}>
                              <WindowScroller>
                                {({ height, isScrolling, scrollTop }) => (
                                  <AutoSizer disableHeight>
                                    {({ width }) => (
                                      <List
                                        className="List"
                                        autoHeight
                                        height={height}
                                        width={width}
                                        rowCount={classes.length || 0}
                                        rowHeight={cache.rowHeight}
                                        rowRenderer={rowRenderer}
                                        scrollTop={scrollTop}
                                      />
                                    )}
                                  </AutoSizer>
                                )}
                              </WindowScroller>
                            </Row>
                          </Col>
                        </Row>
                        {!!Object.values(values.classes)?.flat()?.length && (
                          <Footer values={values} gameType={gameType} />
                        )}
                      </Container>
                    </form>
                  );
                }}
              </Formik>
            </Col>
          </Row>
        </Wrapper>
      </PageWrapper>
    </>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  padding-bottom: 4.5rem;

  ${media.md} {
    padding-bottom: 6.25rem;
  }
`;

const TopBar = styled.div<{ $hidden: boolean }>`
  ${ifProp(
    '$hidden',
    css`
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
    `,
  )}
`;

const Label = styled.b`
  color: var(--white);
`;

const CardsCount = styled(NumberField)`
  background-color: rgba(var(--black-rgb), 0.2);
  color: var(--white);
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  width: 4rem;
  display: inline-block;
`;
