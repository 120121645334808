import { replicateRxCollection } from 'rxdb/plugins/replication';
import { ReplicationPullHandlerResult } from 'rxdb/dist/types/types/plugins/replication';

import { ClassDocType } from './schema';
import { api } from '../../../services/api';
import { Class } from '../../../types/Class';

type Checkpoint = Pick<ClassDocType, 'updated_at'>;

export async function sharedClassReplication(collection) {
  return replicateRxCollection<ClassDocType, Checkpoint>({
    collection,
    replicationIdentifier: `${process.env.REACT_APP_API_URL}/shared-class`,
    autoStart: true,
    live: true,
    deletedField: 'deleted_at',
    pull: {
      batchSize: 1000,
      async handler(
        lastCheckpoint,
        batchSize,
      ): Promise<ReplicationPullHandlerResult<Class, Checkpoint>> {
        const minTimestamp = new Date(0);

        const response = await api.get(`/class/sync`, {
          params: {
            last_checkpoint: minTimestamp,
            batchSize,
            show_shared_entities: true,
          },
        });
        const documents = response.data || [];

        return {
          documents,
          checkpoint: {
            updated_at: new Date(0).toISOString(),
          },
        };
      },
      modifier: document => ({
        ...document,
        shared: true,
      }),
    },
  });
}
