import styled, { css } from 'styled-components/macro';
import { switchProp } from 'styled-tools';

import { media } from '../../../../../styles/media';

interface Props {
  background?: 'yellow';
}

export const CheckboxWrapper = styled.div<Props>`
  padding: 1.25rem 0;
  min-width: 4rem;
  min-height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;

  ${media.md} {
    padding: 1.5rem 0.5rem;
    min-width: 4.75rem;
  }

  ${switchProp('background', {
    yellow: css`
      background: var(--yellow-100);
    `,
  })};
`;
