import * as React from 'react';
import { useField } from 'formik';

import { Label } from '../Label';
import { Select } from '../Select';
import { Col, Row } from '../Grid';

export const SelectField = props => {
  const { label, name, options } = props;
  const [field, meta, helpers] = useField(name);

  return (
    <Row gutterY={0.25}>
      {label ? (
        <Col xs={12} gutterY={0.25}>
          <Label htmlFor={name}>{label}</Label>
        </Col>
      ) : null}
      <Col xs={12} gutterY={0.25}>
        <Select
          {...field}
          {...props}
          options={options}
          invalid={!!(meta.touched && meta.error)}
          onBlur={() => {
            helpers.setTouched(true);
          }}
          onChange={value => {
            helpers.setValue(value);
          }}
        />
      </Col>
    </Row>
  );
};
