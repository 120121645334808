import { keyframes } from 'styled-components';
import styled, { css } from 'styled-components/macro';
import { ButtonIcon } from '../ButtonIcon';
import { ifProp, prop } from 'styled-tools';

const pulse = keyframes`
  to {
    box-shadow: 0 0 0 2rem rgba(var(--blue-200-rgb), 0), 0 0 0.5rem rgba(0, 0, 0, 0.25) inset;
  }
`;

export const PulseIconButton = styled(ButtonIcon)<{
  $active?: boolean;
  color?: string;
}>`
  box-shadow: 0 0 0 0
    rgba(var(${ifProp('color', prop('color'), '--blue-200-rgb')}), 0.7);
  border-radius: 50%;
  background-color: rgba(
    var(${ifProp('color', prop('color'), '--blue-200-rgb')}),
    1
  );
  color: var(--white) !important;
  padding: 1rem;

  ${ifProp(
    '$active',
    css`
      box-shadow: 0 0 0 0
          rgba(var(${ifProp('color', prop('color'), '--blue-200-rgb')}), 0.7),
        0 0 0.5rem rgba(0, 0, 0, 0.25) inset;
      animation: ${pulse} 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    `,
  )}

  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
`;
