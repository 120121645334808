import React from 'react';
import {
  ClearIndicatorProps,
  components,
  DropdownIndicatorProps,
  MultiValueRemoveProps,
  OptionProps,
} from 'react-select';

import { Icon } from '../Icon';

export const DropdownIndicator = (props: DropdownIndicatorProps) => (
  <components.DropdownIndicator {...props}>
    <Icon name="chevron-down" />
  </components.DropdownIndicator>
);

export const ClearIndicator = (props: ClearIndicatorProps) => {
  return (
    <components.ClearIndicator {...props}>
      <Icon name="cross" />
    </components.ClearIndicator>
  );
};

export const MultiOption = (props: OptionProps) => {
  return <components.Option {...props}>{props.children}</components.Option>;
};

export const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon name="cross" />
    </components.MultiValueRemove>
  );
};
