import React from 'react';
import styled from 'styled-components';
import OriginalSelect, {
  components,
  ControlProps,
  ValueContainerProps,
} from 'react-select';

import { selectStyles } from './styles';
import { DropdownIndicator } from './components';

const CustomControl = ({ className, ...props }: ControlProps) => (
  <components.Control
    {...props}
    className={props.hasValue ? 'react-select__control--has-value' : ''}
  />
);

const CustomValueContainer = ({ children, ...props }: ValueContainerProps) => {
  return (
    <components.ValueContainer {...props}>
      {/* @ts-ignore */}
      <components.Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </components.Placeholder>
      {React.Children.map(children, child =>
        child &&
        /* @ts-ignore */
        (child.type === components.SingleValue ||
          /* @ts-ignore */
          child.type === components.MultiValue)
          ? null
          : child,
      )}
    </components.ValueContainer>
  );
};

export const FilterSelect = props => {
  const {
    options = [],
    value,
    onChange,
    name,
    placeholder,
    isSearchable,
    onBlur,
    isMulti,
    closeMenuOnSelect,
    menuIsOpen,
  } = props;

  return (
    <StyledFilterSelect
      classNamePrefix="react-select"
      options={options}
      value={value}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator,
        ValueContainer: CustomValueContainer,
        Control: CustomControl,
      }}
      onChange={onChange}
      placeholder={placeholder}
      isSearchable={isSearchable}
      onBlur={onBlur}
      isMulti={isMulti}
      closeMenuOnSelect={closeMenuOnSelect || false}
      hideSelectedOptions={false}
      isClearable={false}
      menuPortalTarget={document.body}
      menuIsOpen={menuIsOpen}
    />
  );
};

export const StyledFilterSelect = styled(OriginalSelect)`
  ${selectStyles};

  .react-select__control {
    border-color: var(--grey-600);
    background: transparent;

    &:hover {
      border-color: var(--gold-30);
      background-color: var(--gold-100);
    }

    &.react-select__control--menu-is-open {
      background-color: var(--gold-30);
      border-color: var(--gold-30);
    }

    &.react-select__control--has-value {
      border-color: var(--gold-30);
    }

    .react-select__placeholder {
      color: var(--white);
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 14px;
    }
  }
`;
