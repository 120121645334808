import React, { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';

export function SharedBlock(props: PropsWithChildren<{ shared?: boolean }>) {
  const { children, shared } = props;

  return <Wrapper $hidden={!!shared}>{children}</Wrapper>;
}

const Wrapper = styled.div<{ $hidden: boolean }>`
  ${ifProp(
    '$hidden',
    css`
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    `,
  )}
`;
