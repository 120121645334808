import React, { PropsWithChildren, ReactNode } from 'react';
import { Swiper as SwiperOrigin } from 'swiper/react';
import styled from 'styled-components/macro';
import { Keyboard, Navigation, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { media } from '../../../../styles/media';

interface Props {
  onSlideChange?: (swiper: any) => void;
  children: ReactNode;
}

export function Slider(props: PropsWithChildren<Props>) {
  const { children, onSlideChange } = props;

  return (
    <Wrapper>
      <SwiperOrigin
        modules={[Navigation, Pagination, Keyboard]}
        keyboard={{
          enabled: true,
        }}
        navigation
        pagination={{ type: 'fraction' }}
        onSlideChange={onSlideChange}
      >
        {children}
      </SwiperOrigin>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  padding: 1rem 0;

  ${media.md} {
    padding: 3rem 0;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 12rem;

    ${media.md} {
      width: 16rem;
    }
  }

  .swiper-slide-shadow {
    border-radius: 0.5rem;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    color: var(--white);

    ${media.md} {
      display: flex;
    }

    &:hover {
      color: var(--yellow-100);
    }

    &:after {
      font-size: 2rem;
    }

    &.swiper-button-disabled {
      display: none;
    }
  }

  .swiper-button-prev {
    left: 0.5rem;

    ${media.sm} {
      left: 2rem;
    }

    ${media.xl} {
      left: auto;
      right: 50%;
      margin-right: calc(78rem / 2 - 44px);
    }
  }

  .swiper-button-next {
    right: 0.5rem;

    ${media.sm} {
      right: 2rem;
    }

    ${media.xl} {
      right: auto;
      left: 50%;
      margin-left: calc(78rem / 2 - 44px);
    }
  }

  .swiper-pagination {
    top: 100%;
    color: var(--white);
    margin-top: 1rem;
    line-height: 1rem;

    ${media.md} {
      margin-top: 1.25rem;
    }
  }
`;
