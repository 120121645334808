import React, { useContext } from 'react';
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { ifProp, prop } from 'styled-tools';

import { Icon, IconWrapper } from '../Icon';
import { buttonIconStyles } from '../ButtonIcon';
import { AuthContext } from '../../context/AuthContext';

export function ProfileButton() {
  const { user } = useContext(AuthContext);

  return (
    <Button to="/account" backgroundImage={user.photo_src}>
      <Icon name="person-2" />
    </Button>
  );
}

const Button = styled(Link)<{ backgroundImage?: string }>`
  ${buttonIconStyles};
  display: block;
  border-radius: 100px;
  background: url(${prop('backgroundImage')});
  background-size: cover;
  background-position: center center;
  color: var(--blue-200);

  svg {
    width: 44px;
    height: 44px;
    stroke-width: 1px;
  }

  ${ifProp(
    'backgroundImage',
    css`
      ${IconWrapper} {
        opacity: 0;
        visibility: hidden;
      }
    `,
  )}
`;
