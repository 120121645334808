import {
  AxiosDefaults,
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

import {
  createTokenCookies,
  getAccessToken,
  getRefreshToken,
  removeTokenCookies,
} from '../utils/token-cookies';
import { api } from './api';

interface IFailedRequestQueue {
  onSuccess: (token: string) => void;
  onFailure: (error: AxiosError) => void;
}

let isRefreshing = false;
let failedRequestQueue: IFailedRequestQueue[] = [];

export function setAuthorizationHeader(
  request: AxiosDefaults | AxiosRequestConfig,
  accessToken: string,
) {
  // @ts-ignore
  request.headers.Authorization = `Bearer ${accessToken}`;
}

function handleRefreshToken(refreshToken: string) {
  isRefreshing = true;

  api
    .post('/auth/refresh-token', { refreshToken })
    .then(response => {
      const { accessToken, refreshToken } = response.data;

      createTokenCookies(accessToken, refreshToken);
      setAuthorizationHeader(api.defaults, accessToken);

      failedRequestQueue.forEach(request => request.onSuccess(accessToken));
      failedRequestQueue = [];
    })
    .catch(error => {
      failedRequestQueue.forEach(request => request.onFailure(error));
      failedRequestQueue = [];

      removeTokenCookies();
    })
    .finally(() => {
      isRefreshing = false;
    });
}

function onRequest(config: AxiosRequestConfig): AxiosRequestConfig {
  const accessToken = getAccessToken();
  accessToken && setAuthorizationHeader(config, accessToken);
  return config;
}

function onRequestError(error: AxiosError): Promise<AxiosError> {
  return Promise.reject(error);
}

function onResponse(response: AxiosResponse): AxiosResponse {
  return response;
}

function onResponseError(
  error: AxiosError,
): Promise<AxiosError | AxiosResponse> {
  if (error?.response?.status === 401) {
    // @ts-ignore
    if (error.response.data?.message === 'Access token invalid or expired') {
      const originalConfig = error.config;
      const refreshToken = getRefreshToken();

      !isRefreshing && refreshToken && handleRefreshToken(refreshToken);

      return new Promise((resolve, reject) => {
        failedRequestQueue.push({
          onSuccess: (accessToken: string) => {
            setAuthorizationHeader(originalConfig, accessToken);
            resolve(api(originalConfig));
          },
          onFailure: (error: AxiosError) => {
            reject(error);
          },
        });
      });
    } else {
      removeTokenCookies();
    }
  }

  return Promise.reject(error);
}

export function setupInterceptors(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
