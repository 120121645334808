import { createGlobalStyle } from 'styled-components';
import { IconWrapper } from '../app/components/Icon';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Geomanist;
    src: url('/fonts/Geomanist-Regular-Webfont/geomanist-regular-webfont.woff') format('woff'),
         url('/fonts/Geomanist-Regular-Webfont/geomanist-regular-webfont.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: Geomanist;
    src: url('/fonts/Geomanist-Medium/Geomanist-Medium.woff2') format('woff2'),
         url('/fonts/Geomanist-Medium/Geomanist-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: Geomanist;
    src: url('/fonts/Geomanist-Bold-Webfont/geomanist-bold-webfont.woff2') format('woff2'),
         url('/fonts/Geomanist-Bold-Webfont/geomanist-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: GeomanistBook;
    src: url('/fonts/Geomanist-Book-Webfont/geomanist-book-webfont.woff2') format('woff2'),
         url('/fonts/Geomanist-Book-Webfont/geomanist-book-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: ProximaNovaSemibold;
    src: url('/fonts/Proxima-Nova-SemiBold-Webfont/ProximaNova-Semibold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: ProximaNovaBold;
    src: url('/fonts/Proxima-Nova-Bold-Webfont/ProximaNova-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: ProximaNovaLight;
    src: url('/fonts/Proxima-Nova-Light-Webfont/ProximaNova-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: ProximaNovaRegular;
    src: url('/fonts/Proxima-Nova-Regular-Webfont/proxima_nova_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: MyriadProRegular;
    src: url('/fonts/Myriad-Pro-Regular-Webfont/MyriadPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: MyriadProSemibold;
    src: url('/fonts/Myriad-Pro-Semibold-Webfont/MyriadPro-Semibold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  :where(:root) {
    overflow-wrap: normal;
  }

  :root {
    --white: #FDFDFD;
    --white-rgb: 253, 253, 253;

    --black: #000000;
    --black-rgb: 0, 0, 0;

    --blue-10: #e9f6fb;
    --blue-100: #35A9DC;
    --blue-100-rgb: 53, 169, 220;
    --blue-200: #3098C6;
    --blue-200-rgb: 48, 152, 198;
    --blue-400: #267FA7;

    --grey-100: #F1F4FA;
    --grey-150: #E5E5E5;
    --grey-200: #C1C1C1;
    --grey-300: #808080;
    --grey-300-rgb: 128, 128, 128;
    --grey-500: #525252;
    --grey-900: #414141;
    --grey-900-rgb: 65, 65, 65;

    --yellow-100: #FFCB24;
    --yellow-100-rgb: 255, 203, 36;
    --yellow-200: #FABE0E;

    --red: #BA244B;
    --red-rgb: 186, 36, 75;
    --red-hover: #b51d45;

    --green-100: #1C9E3E;
    --green-200: #388E3C;
    --green-200-rgb: 56,142,60;

    --font-primary: 'Inter', sans-serif;
    --font-secondary: Geomanist, 'Inter', sans-serif;
    --font-monospace: 'Source Code Pro', monospace;
  }

  * {
    outline: none;
  }

  html,
  body {
    height: 100%;
    width: 100%;
    background: var(--blue-200);
    color: var(--black);
    font-size: 16px;
  }

  html {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 320px;
    height: 100%;
    min-height: 100%;
  }

  body {
    font-size: 16px;
    line-height: 22px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    transition: background-color 0.2s linear;

    &.fontLoaded {
      font-family: var(--font-primary);
    }
  }

  #root {
    min-height: 100%;
    min-width: 100%;
    max-height: -webkit-fill-available;
    display: flex;
  }

  .react-select__menu-portal {
    z-index: 3 !important;

    .react-select__menu {
      background: var(--white);
      border-radius: 0.5rem;
      line-height: 22px;
      font-size: 16px;
      overflow: hidden;
      margin: 0.25rem 0 0 0;
      min-width: 100%;
      width: auto;
      white-space: nowrap;
      /*box-shadow: 0 2px 4px rgb(0 0 0 / 10%);*/
      /*border: 1px solid var(--grey-200);*/
      border: 1px solid #FFFFFF;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
      padding: 0;
    }

    .react-select__menu-list {
      padding: 0;
      max-height: 480px;
    }

    .react-select__group {
      padding: 0;

      .react-select__group-heading {
        display: none;
      }
    }

    .react-select__group + .react-select__group {
      padding-top: 0.5rem;
      margin-top: 0.5rem;
      position: relative;

      &:before {
        display: block;
        content: '';
        position: absolute;
        left: 0.5rem;
        right: 0.5rem;
        top: 0;
        border-top: 1px solid var(--grey-300);
      }
    }

    .react-select__option {
      padding: 11px 12px;
      position: relative;

      & > ${IconWrapper} {
        position: absolute;
        right: 0.75rem;
        top: 50%;
        margin-top: -0.75rem;
        display: none;
      }

      &.react-select__option--is-focused {
        background: rgba(var(--blue-100-rgb), 0.1);
      }

      &.react-select__option--is-selected {
        background: var(--blue-100);
        color: var(--white);

        & > ${IconWrapper} {
          display: block;
        }
      }
    }
  }


  table {
    width: 100%;
  }
`;
