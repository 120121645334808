import React, { useMemo } from 'react';
import { Formik } from 'formik';
import styled, { css } from 'styled-components/macro';
import { ifProp, prop } from 'styled-tools';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { useRxCollection, useRxData } from 'rxdb-hooks';
import { RxDocument } from 'rxdb';
import { useParams } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { useSwiperSlide } from 'swiper/react';
import { useMeasure } from 'react-use';

import {
  LessonCard,
  LessonCardBack,
  LessonCardContent,
  LessonCardContextProvider,
  LessonCardFront,
  LessonCardHeader,
  LessonCardLayout,
  LessonFlipSwitcher,
} from '../../../../components/LessonCard';
import { TypeRadioField } from './components/TypeRadioField';
import { Col, Row } from '../../../../components/Grid';
import { Icon, IconName } from '../../../../components/Icon';
import { Span } from '../../../../components/Span';
import { TextAreaField } from './components/TextAreaField';
import { ButtonIcon } from '../../../../components/ButtonIcon';
import {
  AnswerType,
  FlashcardFile,
  LessonFlashCard,
  MultipleChoiceAnswer,
  QuestionType,
} from '../../../../../types/Lesson';
import { MultipleChoiceField } from './components/MultipleChoiceField';
import { PhotoField } from './components/PhotoField';
import { AudioField } from './components/AudioField';
import { WebImageField } from './components/WebImageField';
import { FlashCardDocType } from '../../../../../database/schemas/flashcard';
import { CollectionName } from '../../../../../database/types';
import { MediaDocType } from '../../../../../database/schemas/media';
import { Header } from '../../../../components/Header';
import { media } from '../../../../../styles/media';
import { Button } from '../../../../components/Button';
import { SubmitButton } from './components/SubmitButton';
import { normalizeMultipleChoice } from '../../../../../utils/multiple-choice-normalizer';
import { MultipleChoice } from '../../../../../types/FlashCard';

const validationSchema = Yup.object({
  question_type: Yup.string().required(),
  question_value: Yup.mixed().required(),
  answer_type: Yup.string().when('question_type', (question_type, schema) =>
    question_type[0] === QuestionType.Spelling
      ? Yup.string()
      : Yup.string().required(),
  ),
  answer_value: Yup.mixed().when('question_type', (question_type, schema) =>
    question_type[0] === QuestionType.Spelling
      ? Yup.mixed()
      : Yup.mixed().required(),
  ),
});

interface FlashCardTypeOption {
  value: QuestionType | AnswerType;
  label: string;
  icon: IconName;
  disabled: boolean;
}

export type Values = Pick<
  LessonFlashCard,
  'question_type' | 'question_value' | 'answer_type' | 'answer_value'
>;

interface Props {
  flashcard?: RxDocument<FlashCardDocType>;
  onCancel: () => void;
}

export function FlashCardForm(props: Props) {
  const { flashcard, onCancel } = props;
  const { t, i18n } = useTranslation();
  const { lessonId } = useParams<{ lessonId: string }>();
  const { isActive } = useSwiperSlide();
  const [ref, { width }] = useMeasure<HTMLDivElement>();

  const flashCardCollection = useRxCollection<FlashCardDocType>(
    CollectionName.FlashCards,
  );
  const mediaCollection = useRxCollection<MediaDocType>(CollectionName.Media);

  const {
    result: [prevMediaDocument],
  } = useRxData<MediaDocType>(CollectionName.Media, collection =>
    flashcard?.question_type === QuestionType.Photo ||
    flashcard?.question_type === QuestionType.Audio ||
    flashcard?.question_type === QuestionType.WebImage
      ? collection.findOne({
          selector: { path: flashcard.question_value },
        })
      : undefined,
  );

  const questionTypeOptions: FlashCardTypeOption[] = useMemo(
    () => [
      {
        value: QuestionType.Text,
        label: t('label.text'),
        icon: 'document-text',
        disabled: false,
      },
      {
        value: QuestionType.Spelling,
        label: t('label.spelling'),
        icon: 'spelling-1',
        disabled: false,
      },
      {
        value: QuestionType.Audio,
        label: t('label.audio'),
        icon: 'microphone',
        disabled: false,
      },
      {
        value: QuestionType.Photo,
        label: t('label.photo'),
        icon: 'photo',
        disabled: false,
      },
      {
        value: QuestionType.WebImage,
        label: t('label.webImages'),
        icon: 'web',
        disabled: false,
      },
    ],
    [i18n.language],
  );

  const answerTypeOptions: FlashCardTypeOption[] = useMemo(
    () => [
      {
        value: AnswerType.Text,
        label: t('label.text'),
        icon: 'document-text',
        disabled: false,
      },
      {
        value: AnswerType.MultipleChoice,
        label: t('label.multipleChoice'),
        icon: 'checkmark',
        disabled: false,
      },
    ],
    [i18n.language],
  );

  const initialValues = useMemo(() => {
    if (!flashcard) {
      return {} as Values;
    }

    const { question_type, question_value, answer_type, answer_value } =
      flashcard._data;

    return {
      ...flashcard?._data,
      answer_value:
        answer_type === AnswerType.MultipleChoice
          ? // @ts-ignore
            normalizeMultipleChoice(answer_value)
          : answer_value || '',
      question_value:
        (question_type === QuestionType.Photo ||
        question_type === QuestionType.Audio ||
        question_type === QuestionType.WebImage
          ? {
              type: prevMediaDocument?.mime,
              data: prevMediaDocument?.data || question_value,
            }
          : question_value) || '',
    };
  }, [flashcard?._data?._rev, prevMediaDocument?._data?._rev]);

  const onSubmit = async (values: Required<Values>) => {
    const { question_type, question_value, answer_type, answer_value } = values;

    const normalizedValues = {
      lesson_id: lessonId as string,
      question_type,
      question_value: question_value as string,
      answer_type,
      answer_value,
    };

    if (
      typeof question_value !== 'string' &&
      [QuestionType.Photo, QuestionType.Audio, QuestionType.WebImage].includes(
        question_type,
      )
    ) {
      await prevMediaDocument?.remove();

      const media = await mediaCollection?.insert({
        mime: (question_value as FlashcardFile).type,
        data: (question_value as FlashcardFile).data,
      });

      normalizedValues.question_value = media?.path as string;
    }

    try {
      if (flashcard) {
        await flashcard.update({
          $set: normalizedValues,
        });
      } else {
        // @ts-ignore
        await flashCardCollection?.insert({
          id: uuidv4(),
          ...normalizedValues,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      onCancel();
    }
  };

  return (
    <Formik<Values>
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue, isValid, submitForm }) => {
        const questionType = questionTypeOptions.find(
          ({ value }) => value === values.question_type,
        );
        const answerType = answerTypeOptions.find(
          ({ value }) => value === values.answer_type,
        );

        return (
          <LessonCardContextProvider flippable>
            {isActive &&
              createPortal(
                <ControlsPortal>
                  <ControlsContainer style={{ width }}>
                    <Row justifyContent="space-between">
                      <Col>
                        <Button whiteBorder onClick={onCancel}>
                          {t('button.cancel')}
                        </Button>
                      </Col>
                      <Col>
                        <FlipperContainer
                          $hidden={
                            values.question_type === QuestionType.Spelling
                          }
                        >
                          <LessonFlipSwitcher />
                        </FlipperContainer>
                      </Col>
                      <Col>
                        <SubmitButton />
                      </Col>
                    </Row>
                  </ControlsContainer>
                </ControlsPortal>,
                document.body,
              )}
            <LessonCard ref={ref}>
              <LessonCardFront>
                <Steps $offset={values?.question_type ? '100%' : '0'}>
                  <Step>
                    <LessonCardLayout>
                      <LessonCardHeader title={t('title.questionType')} />
                      <LessonCardContent align="top" withPadding={false}>
                        {questionTypeOptions.map(
                          ({ value, label, icon, disabled }) => (
                            <TypeRadioField
                              name="question_type"
                              value={value}
                              type="radio"
                              key={value}
                              disabled={disabled}
                              onChange={event => {
                                setFieldValue('question_value', undefined);
                              }}
                            >
                              <Row alignItems="center" gutter={0.5}>
                                <Col gutter={0.5}>
                                  <Span color="blue">
                                    <Icon name={icon} />
                                  </Span>
                                </Col>
                                <Col xs="auto" gutter={0.5}>
                                  <Span color="black">{label}</Span>
                                </Col>
                              </Row>
                            </TypeRadioField>
                          ),
                        )}
                      </LessonCardContent>
                    </LessonCardLayout>
                  </Step>
                  <Step>
                    <LessonCardLayout>
                      <LessonCardHeader>
                        <Row xs="3.5rem" height="44px">
                          <Col xs="auto" gutter={0}>
                            <ButtonIcon
                              block
                              withPadding
                              onClick={() => {
                                setFieldValue('question_type', undefined);
                              }}
                            >
                              <Icon name="chevron-left-1" />
                            </ButtonIcon>
                          </Col>
                          <Col gutter={0}>
                            {questionType ? (
                              <Row
                                gutter={0.5}
                                alignItems="center"
                                height="100%"
                              >
                                <Col gutter={0.5}>
                                  <Span color="blue">
                                    <Icon name={questionType?.icon} />
                                  </Span>
                                </Col>
                                <Col gutter={0.5}>
                                  <b>
                                    <Span color="black">
                                      {questionType.label}
                                    </Span>
                                  </b>
                                </Col>
                              </Row>
                            ) : null}
                          </Col>
                          <Col xs="auto" gutter={0} />
                        </Row>
                      </LessonCardHeader>
                      {values.question_type === QuestionType.Text ||
                      values.question_type === QuestionType.Spelling ? (
                        <TextAreaField name="question_value" />
                      ) : null}
                      {values.question_type === QuestionType.Photo ? (
                        <PhotoField name="question_value" />
                      ) : null}
                      {values.question_type === QuestionType.Audio ? (
                        <AudioField name="question_value" />
                      ) : null}
                      {values.question_type === QuestionType.WebImage ? (
                        <WebImageField name="question_value" />
                      ) : null}
                    </LessonCardLayout>
                  </Step>
                </Steps>
              </LessonCardFront>
              <LessonCardBack>
                <Steps $offset={values?.answer_type ? '100%' : '0'}>
                  <Step>
                    <LessonCardLayout>
                      <LessonCardHeader title={t('title.answerType')} />
                      <LessonCardContent align="top" withPadding={false}>
                        {answerTypeOptions.map(({ value, label, icon }) => (
                          <TypeRadioField
                            name="answer_type"
                            value={value}
                            type="radio"
                            key={value}
                            onChange={event => {
                              setFieldValue('answer_value', undefined);
                            }}
                          >
                            <Row alignItems="center" gutter={0.5}>
                              <Col gutter={0.5}>
                                <Span color="blue">
                                  <Icon name={icon} />
                                </Span>
                              </Col>
                              <Col xs="auto" gutter={0.5}>
                                <Span color="black">{label}</Span>
                              </Col>
                            </Row>
                          </TypeRadioField>
                        ))}
                      </LessonCardContent>
                    </LessonCardLayout>
                  </Step>
                  <Step>
                    <LessonCardLayout>
                      <LessonCardHeader>
                        <Row xs="3.5rem" height="44px">
                          <Col xs="auto" gutter={0}>
                            <ButtonIcon
                              block
                              withPadding
                              onClick={() => {
                                setFieldValue('answer_type', undefined);
                              }}
                            >
                              <Icon name="chevron-left-1" />
                            </ButtonIcon>
                          </Col>
                          <Col gutter={0}>
                            {answerType ? (
                              <Row
                                gutter={0.5}
                                alignItems="center"
                                height="100%"
                              >
                                <Col gutter={0.5}>
                                  <Span color="blue">
                                    <Icon name={answerType?.icon} />
                                  </Span>
                                </Col>
                                <Col gutter={0.5}>
                                  <b>
                                    <Span color="black">
                                      {answerType.label}
                                    </Span>
                                  </b>
                                </Col>
                              </Row>
                            ) : null}
                          </Col>
                          <Col xs="auto" gutter={0} />
                        </Row>
                      </LessonCardHeader>
                      {values.answer_type === AnswerType.Text ? (
                        <TextAreaField name="answer_value" />
                      ) : null}
                      {values.answer_type === AnswerType.MultipleChoice ? (
                        <LessonCardContent align="top" withPadding={false}>
                          <MultipleChoiceField name="answer_value" />
                        </LessonCardContent>
                      ) : null}
                    </LessonCardLayout>
                  </Step>
                </Steps>
              </LessonCardBack>
            </LessonCard>
          </LessonCardContextProvider>
        );
      }}
    </Formik>
  );
}

const Steps = styled.div<{ $offset: string }>`
  width: 200%;
  position: relative;
  height: 100%;
  left: -${prop('$offset')};
  transition: left 0.2s ease-out;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0;
  grid-template-areas: '. .';
`;

const Step = styled.div`
  position: relative;
`;

const FlipperContainer = styled.div<{ $hidden: boolean }>`
  ${ifProp(
    '$hidden',
    css`
      visibility: hidden;
      pointer-events: none;
    `,
    '',
  )}
`;

const ControlsPortal = styled(Header)`
  background: var(--blue-200);
  top: 3.5rem;
  padding: 1rem;

  ${media.md} {
    top: 4.75rem;
    padding: 1.5rem 1rem 1rem;
  }

  ${media.lg} {
    left: 20rem;
    top: 0;
    padding: 1rem 1rem 1rem;
  }
`;

const ControlsContainer = styled.div`
  margin: 0 auto;
`;
