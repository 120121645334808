import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row } from '../../../../../components/Grid';
import { Button } from '../../../../../components/Button';
import { LessonCardContext } from '../../../../../components/LessonCard';

export function GameCheckButton() {
  const { t } = useTranslation();
  const { onFlip, flippable } = useContext(LessonCardContext);

  if (!flippable) return null;

  return (
    <Row justifyContent="center">
      <Col>
        <Button yellow onClick={onFlip} type="submit">
          {t('button.check')}
        </Button>
      </Col>
    </Row>
  );
}
