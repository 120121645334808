import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { Card } from '../Card';
import { StripePricingTable } from './PricingTable';
import { Col, Row } from '../Grid';
import { Heading } from '../Heading';
import { Span } from '../Span';
import { media } from '../../../styles/media';

export function Pricing() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Row>
        <Col xs={12}>
          <Heading level={2}>{t('title.plansAndPricing')}</Heading>
        </Col>
        <Col xs={12} gutterY={0.5}>
          <Span color="grey">{t('message.selectPlanToContinue')}</Span>
        </Col>
        <Col xs={12} gutterY={{ xs: 1, md: 2 }}>
          <StripePricingTable />
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled(Card)`
  padding: 1.5rem 1rem;
  text-align: center;

  ${media.md} {
    padding: 2rem;
  }
`;
