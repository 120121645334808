import { useState } from 'react';
import { useWakeLock } from 'react-screen-wake-lock';

export interface recorderControls {
  startRecording: () => void;
  stopRecording: () => void;
  togglePauseResume: () => void;
  recordingBlob?: Blob;
  isRecording: boolean;
  isPaused: boolean;
  recordingTime: number;
  mediaRecorder?: MediaRecorder;
}

export type MediaAudioTrackConstraints = Pick<
  MediaTrackConstraints,
  | 'deviceId'
  | 'groupId'
  | 'autoGainControl'
  | 'channelCount'
  | 'echoCancellation'
  | 'noiseSuppression'
  | 'sampleRate'
  | 'sampleSize'
>;

interface Props {
  audioTrackConstraints?: MediaAudioTrackConstraints;
  mediaRecorderOptions?: MediaRecorderOptions;
  onRecordingComplete: (blob: Blob) => void;
}

export const useAudioRecorder = (props: Props) => {
  const { audioTrackConstraints, mediaRecorderOptions, onRecordingComplete } =
    props;
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();
  const [chunks, setChunks] = useState<Blob[]>([]);
  const { request, release } = useWakeLock();

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: audioTrackConstraints ?? true, video: false })
      .then(stream => {
        request();
        setIsRecording(true);
        const recorder: MediaRecorder = new MediaRecorder(stream, {
          ...mediaRecorderOptions,
          mimeType: mediaRecorderOptions?.mimeType ?? 'video/webm;codecs=vp9',
        });

        setMediaRecorder(recorder);
        recorder.start();

        recorder.addEventListener('dataavailable', event => {
          if (event.data && event.data.size > 0) {
            chunks.push(event.data);
          }
          recorder.stream.getTracks().forEach(t => t.stop());
          setMediaRecorder(undefined);
        });

        recorder.onstop = () => {
          const blob = new Blob(chunks, { type: recorder.mimeType });
          onRecordingComplete(blob);
        };
      })
      .catch(error => {
        console.error(error);
      });
  };

  const stopRecording = () => {
    mediaRecorder?.stop();
    setIsRecording(false);
    release();
  };

  return {
    startRecording,
    stopRecording,
    isRecording,
    mediaRecorder,
  };
};
