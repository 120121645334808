import { replicateRxCollection } from 'rxdb/plugins/replication';
import { ReplicationPullHandlerResult } from 'rxdb/dist/types/types/plugins/replication';

import { BoardDocType } from './schema';
import { api } from '../../../services/api';

type Checkpoint = Pick<BoardDocType, 'updated_at'>;

export async function sharedBoardReplication(collection) {
  return replicateRxCollection<BoardDocType, Checkpoint>({
    collection,
    replicationIdentifier: `${process.env.REACT_APP_API_URL}/shared-board`,
    autoStart: true,
    live: true,
    deletedField: 'deleted_at',
    pull: {
      batchSize: 1000,
      async handler(
        lastCheckpoint,
        batchSize,
      ): Promise<ReplicationPullHandlerResult<BoardDocType, Checkpoint>> {
        const minTimestamp = new Date(0);

        const response = await api.get(`/board/sync`, {
          params: {
            last_checkpoint: minTimestamp,
            batchSize,
            show_shared_entities: true,
          },
        });
        const documents = response.data || [];

        return {
          documents: documents.map(({ value, ...d }) => {
            let data = value;

            try {
              data = JSON.parse(value);
            } catch (error) {
              data = value;
            }

            return {
              ...d,
              data,
            };
          }),
          checkpoint: {
            updated_at: new Date(0).toISOString(),
          },
        };
      },
      modifier: document => ({
        ...document,
        shared: true,
      }),
    },
  });
}
