import React, { useCallback, useState } from 'react';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useRxDB } from 'rxdb-hooks';
import { removeRxDatabase } from 'rxdb';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';
import { useNetworkState } from 'react-use';

import { Col, Row } from '../../../components/Grid';
import { Heading } from '../../../components/Heading';
import { Card } from '../../../components/Card';
import { Button } from '../../../components/Button';
import {
  replications,
  sharedReplications,
} from '../../../../database/configureDatabase';
import { sleep } from '../../../../utils/sleep';

export const Data = () => {
  const { t } = useTranslation();
  const isMd = useMediaQuery(useTheme()?.breakpoints.up('md'));
  const db = useRxDB();
  const [syncLoading, setSyncLoading] = useState(false);
  const { online } = useNetworkState();

  const onReSync = async () => {
    setSyncLoading(true);
    try {
      if (db) {
        await removeRxDatabase(db.name, getRxStorageDexie());

        for (const replication of Object.values(replications)) {
          replication?.reSync();
        }
        for (const replication of Object.values(sharedReplications)) {
          replication?.reSync();
        }

        await sleep(1000);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSyncLoading(false);
    }
  };

  return (
    <Card padding={isMd ? 2 : 1.5}>
      <Row>
        <Col xs={12}>
          <Heading level={3}>{t('title.data')}</Heading>
        </Col>
        <Col xs={12} gutterY={1.5}>
          <Button
            yellow
            onClick={onReSync}
            $loading={syncLoading}
            disabled={!online}
          >
            {t('button.reSync')}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};
