import React, { useState } from 'react';
import { useCollapse } from 'react-collapsed';
import { useRxData } from 'rxdb-hooks';
import styled from 'styled-components';

import { CollectionName } from '../../../../database/types';
import { LessonDocType } from '../../../../database/schemas/lesson';
import { ClassDocType } from '../../../../database/schemas/class';
import { LessonRow } from './LessonRow';
import { ClassRow } from './ClassRow';
import { GameType } from '../../../../types/Game';
import { useScore } from '../../../hooks/useScore';

interface Props {
  data: ClassDocType;
  gameType: GameType;
  measure: () => void;
}

export function DropDown(props: Props) {
  const { data, gameType, measure } = props;
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps } = useCollapse({
    isExpanded,
    hasDisabledAnimation: true,
    onTransitionStateChange: () => {
      setTimeout(measure, 0);
    },
  });

  const { result: lessons } = useRxData<LessonDocType>(
    CollectionName.Lessons,
    collection =>
      collection.find({
        selector: {
          class_id: data.id,
        },
      }),
  );

  const { classScore, scoresByLessonId } = useScore({
    classId: data.id,
    gameType,
  });

  const onToggle = () => {
    setExpanded(prevExpanded => !prevExpanded);
  };

  return (
    <Wrapper>
      <ClassRow
        data={data}
        isExpanded={isExpanded}
        classOnly={false}
        score={classScore}
        onToggle={onToggle}
      />
      <div {...getCollapseProps()}>
        {lessons.map(lesson => (
          <LessonRow
            key={lesson.id}
            lesson={lesson}
            score={scoresByLessonId[lesson.id]}
          />
        ))}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: var(--white);
  border-radius: 0.5rem;
  cursor: pointer;
  overflow: hidden;

  .star-ratings {
    text-align: center;
  }
`;
