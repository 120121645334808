import React, { useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import styled from 'styled-components/macro';
import { useDropzone } from 'react-dropzone';

import {
  LessonCardContent,
  LessonCardFooter,
} from '../../../../../components/LessonCard';
import { useTranslation } from 'react-i18next';
import { Col, Row } from '../../../../../components/Grid';
import { Button } from '../../../../../components/Button';
import { base64MimeType } from '../../../../../../utils/file-manipulatiion';

const reader = new FileReader();

let interval: ReturnType<typeof setTimeout> | undefined = undefined;

export function PhotoField(props) {
  const { name } = props;
  const [field, meta, helpers] = useField<{
    type: string;
    data: string;
  }>(name);
  const { t } = useTranslation();
  const form = useFormikContext();

  const onFileRead = event => {
    helpers.setValue({
      ...field.value,
      data: event.target.result,
      type: base64MimeType(event.target.result),
    });
  };

  useEffect(() => {
    reader.addEventListener('load', onFileRead);
    return () => {
      reader.removeEventListener('load', onFileRead);
    };
  }, []);

  const onDrop = acceptedFiles => {
    reader.readAsDataURL(acceptedFiles[0]);
  };

  const onFileDialogCancel = () => {
    if (!field.value?.data) {
      form.setFieldValue('question_type', undefined);
    }
  };

  const { getRootProps, getInputProps, open, isFileDialogActive } = useDropzone(
    {
      accept: {
        'image/*': [],
      },
      multiple: false,
      onDrop,
      onFileDialogCancel,
    },
  );

  useEffect(() => {
    if (interval) clearTimeout(interval);

    interval = setTimeout(() => {
      if (!field.value?.data) {
        open();
      }
    }, 250);
  }, []);

  return (
    <>
      <LessonCardContent>
        <Wrapper {...getRootProps()}>
          <input {...getInputProps()} accept="image/*;capture=camera" />
          {field?.value?.data ? <img src={field.value.data} /> : null}
        </Wrapper>
      </LessonCardContent>
      <LessonCardFooter $hidden={!field.value?.data}>
        <Row justifyContent="center">
          <Col>
            <Button onClick={open} blue>
              {t('button.replace')}
            </Button>
          </Col>
        </Row>
      </LessonCardFooter>
    </>
  );
}

const Wrapper = styled.div`
  cursor: pointer;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
