import { Swiper as SwiperOrigin } from 'swiper/react';
import styled from 'styled-components/macro';
import { Dispatch, PropsWithChildren } from 'react';
import { Virtual } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { media } from '../../../../../styles/media';

interface Props {
  onSlideNext: Dispatch<React.SetStateAction<number>>;
}

export function GameSwiper(props: PropsWithChildren<Props>) {
  const { children, onSlideNext } = props;

  const onSlideChange = swiper => {
    onSlideNext(swiper.activeIndex);
  };

  return (
    <Wrapper>
      <SwiperOrigin
        modules={[Virtual]}
        allowTouchMove={false}
        onSlideChange={onSlideChange}
        virtual
      >
        {children}
      </SwiperOrigin>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
  margin: 0 1rem;

  .swiper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    padding-bottom: 1rem;

    ${media.md} {
      padding-bottom: 1.5rem;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
`;
