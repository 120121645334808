import React, { useContext, useMemo, useState } from 'react';
import { SwiperSlide } from 'swiper/react';
import { RxDocument } from 'rxdb';
import _shuffle from 'lodash/shuffle';

import { GameSwiper } from '../components/GameSwiper';
import { FlashCardDocType } from '../../../../database/schemas/flashcard';
import { GameHeader } from '../components/GameHeader';
import { GameForm } from '../components/GameForm';
import { GameContext } from '../GameContext';
import { GameResult } from '../components/GameResult';
import { LessonDocType } from '../../../../database/schemas/lesson';

interface Props {
  flashcards: RxDocument<FlashCardDocType>[];
  lessons: RxDocument<LessonDocType>[];
  isFetching: boolean;
}

export function Game(props: Props) {
  const { lessons, flashcards, isFetching } = props;
  const [swiperIndex, setSwiperIndex] = useState<number>(0);
  const { answers, retryNumber } = useContext(GameContext);

  const flashcardsFiltered = useMemo(
    () =>
      _shuffle(
        flashcards.filter(flashcard => !answers[flashcard._data.id]?.hidden),
      ),
    [retryNumber, flashcards.length],
  );

  return (
    <GameSwiper onSlideNext={setSwiperIndex}>
      <GameHeader index={swiperIndex} slidesCount={flashcardsFiltered.length} />
      {flashcardsFiltered.map((flashcard, index) => {
        return (
          <SwiperSlide key={flashcard.id} virtualIndex={index}>
            <GameForm flashcard={flashcard} />
          </SwiperSlide>
        );
      })}
      {isFetching || !flashcards.length ? null : (
        <SwiperSlide virtualIndex={flashcardsFiltered.length}>
          <GameResult
            flashcards={flashcards}
            lessons={lessons}
            slidesCount={flashcards.length}
          />
        </SwiperSlide>
      )}
    </GameSwiper>
  );
}
