import React, { useEffect } from 'react';
import { useAsyncRetry } from 'react-use';
import { useRxCollection } from 'rxdb-hooks';
import { FlashCardDocType } from '../../database/schemas/flashcard';
import { CollectionName } from '../../database/types';
import { LessonDocType } from '../../database/schemas/lesson';

type Props =
  | {
      classId: string;
      lessonId?: string;
    }
  | {
      classId?: string;
      lessonId: string;
    };

export function useFlashcardCount(props: Props) {
  const { classId, lessonId } = props;

  const flashCardCollection = useRxCollection<FlashCardDocType>(
    CollectionName.FlashCards,
  );

  const lessonCollection = useRxCollection<LessonDocType>(
    CollectionName.Lessons,
  );

  const { value: count, retry } = useAsyncRetry(async () => {
    let lessonsId = lessonId ? [lessonId] : [];

    if (classId && !lessonId && lessonCollection) {
      const lessonsQuery = await lessonCollection.find({
        selector: {
          class_id: classId,
        },
      });

      const lessons = await lessonsQuery.exec();

      lessonsId = lessons.map(lesson => lesson._data.id);
    }

    if (flashCardCollection) {
      const flashcards = await flashCardCollection
        .find({
          selector: {
            lesson_id: {
              $in: lessonsId,
            },
          },
        })
        .exec();

      return flashcards?.length || 0;
    }

    return undefined;
  }, [flashCardCollection, lessonCollection, classId, lessonId]);

  useEffect(() => {
    if (flashCardCollection) {
      flashCardCollection.insert$.subscribe(event => {
        setTimeout(retry, 1000);
      });
    }
  }, [flashCardCollection]);

  return count || 0;
}
