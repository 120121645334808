import { replicateRxCollection } from 'rxdb/plugins/replication';
import { ReplicationPullHandlerResult } from 'rxdb/dist/types/types/plugins/replication';
import _omit from 'lodash/omit';

import { AudioDocType } from './schema';
import { api } from '../../../services/api';

type Checkpoint = Pick<AudioDocType, 'id' | 'updated_at'>;

export async function audioReplication(collection) {
  return replicateRxCollection<AudioDocType, Checkpoint>({
    collection,
    replicationIdentifier: `${process.env.REACT_APP_API_URL}/audio`,
    autoStart: true,
    live: true,
    deletedField: 'deleted_at',
    push: {
      batchSize: 1000,
      async handler(docs) {
        const conflicts = await api.post(`/audio/sync/`, docs);
        return conflicts.data;
      },
      modifier: document => {
        if (document.shared) return null;
        return document;
      },
    },
    pull: {
      batchSize: 1000,
      async handler(
        lastCheckpoint,
        batchSize,
      ): Promise<ReplicationPullHandlerResult<AudioDocType, Checkpoint>> {
        const minTimestamp = lastCheckpoint
          ? lastCheckpoint?.updated_at || new Date(0)
          : new Date(0);

        const response = await api.get(`/audio/sync`, {
          params: {
            last_checkpoint: minTimestamp,
            batchSize,
          },
        });
        const documents = response.data || [];
        const documentsLength = documents.length;
        const lastDocument = documents[documentsLength - 1];

        const checkpoint = documentsLength
          ? {
              id: lastDocument.id,
              updated_at: lastDocument.updated_at,
            }
          : lastCheckpoint;

        return {
          documents,
          checkpoint,
        };
      },
      modifier: document => ({
        ...document,
        shared: false,
      }),
    },
  });
}
