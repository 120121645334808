import styled from 'styled-components/macro';
import { ButtonIcon } from '../ButtonIcon';
import { StyledHeading } from '../Heading';
import { media } from '../../../styles/media';

export const BackButton = styled(ButtonIcon)`
  color: var(--grey-900);
  text-decoration: none;
  margin-left: -12px;

  ${media.md} {
    margin-left: -15px;
  }

  &:hover {
    color: var(--yellow-100);

    ${StyledHeading} {
      color: var(--yellow-100);
    }
  }

  svg {
    width: 2rem;
    height: 2rem;
    stroke-width: 1px;
  }

  ${StyledHeading} {
    color: var(--grey-300);
  }
`;
