import React, { useCallback, useRef, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styled from 'styled-components/macro';

import { PulseIconButton } from '../PulseIconButton';
import { Icon } from '../Icon';

interface Props {
  src: string;
  color?: string;
}

const styles = {
  path: {
    stroke: 'var(--yellow-100)',
  },
  trail: {
    stroke: 'transparent',
  },
};

export const AudioButton = ({ src, color }: Props) => {
  const [playing, setPlaying] = useState(false);
  const ref = useRef<HTMLAudioElement>(null);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const onTimeUpdate = () => {
    if (ref.current) {
      if (ref.current.duration !== Infinity && loading) {
        setLoading(false);
        ref.current.currentTime = 0;
      }

      if (!loading) {
        setProgress(ref.current.currentTime / ref.current.duration);
      }
    }
  };

  const onLoadedMetadata = () => {
    if (
      ref.current &&
      (ref.current.duration === Infinity || isNaN(Number(ref.current.duration)))
    ) {
      setLoading(true);
      ref.current.currentTime = 1e101;
    }
  };

  const onPlay = useCallback(() => {
    setPlaying(true);
  }, []);

  const onPause = useCallback(() => {
    setPlaying(false);
  }, []);

  const onEnded = useCallback(() => {
    setPlaying(false);
  }, [ref.current]);

  const play = () => {
    if (ref.current && progress === 1) {
      ref.current.currentTime = 0;
    }

    ref.current?.play();
  };

  const pause = () => {
    ref.current?.pause();
  };

  return (
    <Wrapper>
      <PulseIconButton
        onClick={playing ? pause : play}
        color={color}
        type="button"
      >
        <Icon name={playing ? 'pause' : 'play'} />
      </PulseIconButton>
      <audio
        ref={ref}
        src={src}
        onPlay={onPlay}
        onPause={onPause}
        onEnded={onEnded}
        onTimeUpdate={onTimeUpdate}
        onLoadedMetadata={onLoadedMetadata}
      />
      {!loading ? (
        <ProgressWrapper>
          <CircularProgressbar
            strokeWidth={4}
            value={progress}
            styles={styles}
            minValue={0}
            maxValue={1}
          />
        </ProgressWrapper>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const ProgressWrapper = styled.div`
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  pointer-events: none;
`;
