import has from 'lodash/has';
import isObject from 'lodash/isObject';
import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';
import { css } from 'styled-components';

import { themeProvider } from '../../theme';

const { theme } = themeProvider;

export const gutterHeight = (props, breakpoint) => {
  if (isObject(props.gutterY) && has(props, `gutterY.${breakpoint}`)) {
    return props.gutterY[breakpoint];
  }

  if (!isObject(props.gutterY) && has(props, 'gutterY')) {
    return props.gutterY;
  }

  return isObject(theme(props).gutter)
    ? theme(props).gutter[breakpoint]
    : theme(props).gutter;
};

export const row = (props, breakpoint) => {
  const gutterUnit = gutterHeight(props, breakpoint);
  const gutter = isNumber(gutterUnit) ? `${gutterUnit}rem` : gutterUnit;

  if (
    (!props.gutterY && props.gutterY !== 0) ||
    (isObject(props.gutterY) &&
      !props.gutterY[breakpoint] &&
      props.gutterY[breakpoint] !== 0)
  )
    return null;

  return isUndefined(gutterUnit)
    ? null
    : css`
        margin-top: -${gutter};
      `;
};

export const col = (props, breakpoint) => {
  const gutterUnit = gutterHeight(props, breakpoint);
  const gutter = isNumber(gutterUnit) ? `${gutterUnit}rem` : gutterUnit;

  if (
    (!props.gutterY && props.gutterY !== 0) ||
    (isObject(props.gutterY) &&
      !props.gutterY[breakpoint] &&
      props.gutterY[breakpoint] !== 0)
  )
    return null;

  return isUndefined(gutterUnit)
    ? null
    : css`
        margin-top: ${gutter};
      `;
};
