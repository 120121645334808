import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import de from './de/translation.json';
import en from './en/translation.json';
import es from './es/translation.json';
import fr from './fr/translation.json';
import it from './it/translation.json';
import pt from './pt/translation.json';
import ru from './ru/translation.json';
import zhHans from './zh-Hans/translation.json';
import zhHant from './zh-Hant/translation.json';
import { convertLanguageJsonToObject } from './translations';

export const translationOptions = [
  { value: 'de', label: 'German' },
  { value: 'en', label: 'English' },
  { value: 'es', label: 'Spanish' },
  { value: 'fr', label: 'French' },
  { value: 'it', label: 'Italian' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'ru', label: 'Russian' },
  { value: 'zh-TW', label: 'Chinese (Traditional)' },
  { value: 'zh-CN', label: 'Chinese (Simplified)' },
];

export const translationsJson = {
  de: {
    translation: de,
  },
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  fr: {
    translation: fr,
  },
  it: {
    translation: it,
  },
  pt: {
    translation: pt,
  },
  ru: {
    translation: ru,
  },
  'zh-CN': {
    translation: zhHans,
  },
  'zh-TW': {
    translation: zhHant,
  },
};

// Create the 'translations' object to provide full intellisense support for the static json files.
convertLanguageJsonToObject(de);
convertLanguageJsonToObject(en);
convertLanguageJsonToObject(es);
convertLanguageJsonToObject(fr);
convertLanguageJsonToObject(it);
convertLanguageJsonToObject(pt);
convertLanguageJsonToObject(ru);
convertLanguageJsonToObject(zhHant);
convertLanguageJsonToObject(zhHans);

export const i18n = i18next
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: translationsJson,
    fallbackLng: 'en',
    debug:
      process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
