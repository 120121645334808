import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Login } from './Login';
import { Boarding } from './Boarding';
import { BoardingGoogle } from './BoardingGoogle';

export function AuthPage(props) {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="boarding" element={<Boarding />} />
      <Route path="google-boarding" element={<BoardingGoogle />} />
    </Routes>
  );
}
