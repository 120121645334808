import styled from 'styled-components/macro';
import { switchProp } from 'styled-tools';

interface Props {
  color?: 'black' | 'grey' | 'blue' | 'yellow' | 'red' | 'green';
}

export const Dot = styled.span<Props>`
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${switchProp('color', {
    black: 'var(--black)',
    grey: 'var(--grey-300)',
    blue: 'var(--blue-200)',
    yellow: 'var(--yellow-200)',
    red: 'var(--red)',
    green: 'var(--green-100)',
  })};
`;
