import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import shuffle from 'lodash/shuffle';

import { Col, Row } from '../../../../../components/Grid';
import { ChoiceOption } from './ChoiceOption';
import { Label } from '../../../../../components/Label';
import { useTranslation } from 'react-i18next';

interface Props {
  answer: any;
  name: string;
}

export const MultipleChoice = (props: Props) => {
  const { answer, name } = props;
  const { options } = answer;
  const [field, meta, helpers] = useField(name);
  const [fakeField, fakeMeta, fakeHelpers] = useField(`${name}_fake`);
  const [shuffleOptions, setShuffleOptions] = useState(options);
  const { t } = useTranslation();

  useEffect(() => {
    if (!options) return;
    setShuffleOptions(shuffle(options));
  }, [options]);

  const onSelect = option => {
    const findFunc = o => o === option;

    const realIndex = options.findIndex(findFunc);
    const fakeIndex = shuffleOptions.findIndex(findFunc);

    const isActive = (meta.value || []).includes(realIndex);

    if (!isActive) {
      helpers.setValue([...(meta.value || []), realIndex]);
      fakeHelpers.setValue([...(fakeMeta.value || []), fakeIndex]);
    } else {
      helpers.setValue(meta.value.filter(n => n !== realIndex));
      fakeHelpers.setValue(fakeMeta.value.filter(n => n !== fakeIndex));
    }
  };

  return (
    <Row
      justifyContent="center"
      flexDirection={{ xs: 'column', lg: 'row' }}
      gutter={0.5}
    >
      <Col gutterY={0.5} xs={12}>
        <Label>{t('label.options')}</Label>
      </Col>
      {shuffleOptions.map((option, index) => {
        return (
          <Col key={index} gutterY={0.5} xs={12}>
            <ChoiceOption
              option={option}
              onSelect={onSelect}
              isActive={(fakeField.value || []).includes(index)}
            />
          </Col>
        );
      })}
    </Row>
  );
};
