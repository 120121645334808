import React, { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';

import { media } from '../../../styles/media';
import { StyledLinkIcon } from '../ButtonIcon';
import { Link } from '../Link';

export function Sidebar(props: PropsWithChildren) {
  const { children } = props;

  return <Wrapper>{children}</Wrapper>;
}

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 20rem;
  background: var(--white);
  padding: 0.375rem 0;
  box-shadow: 0 2px 4px 0 rgba(var(--black), 0.05);
  color: var(--grey-900);
  display: none;
  z-index: 2;

  ${media.lg} {
    padding: 1rem 0;
    min-height: 76px;
    display: flex;
  }

  ${StyledLinkIcon} {
    color: var(--grey-900);

    &:hover {
      color: var(--yellow-100);
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  ${Link} {
    &:hover {
      color: var(--yellow-100);
    }
  }
`;
