import styled from 'styled-components/macro';

import { media } from '../../../styles/media';
import { Link } from '../Link';
import { StyledLinkIcon } from '../ButtonIcon';

export const Header = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: var(--white);
  padding: 0.375rem 0;
  box-shadow: 0 2px 4px 0 rgba(var(--black), 0.05);
  color: var(--grey-900);
  min-height: 56px;
  z-index: 2;

  ${media.md} {
    padding: 1rem 0;
    min-height: 76px;
  }

  ${StyledLinkIcon} {
    color: var(--grey-900);

    &:hover {
      color: var(--yellow-100);
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  ${Link} {
    &:hover {
      color: var(--yellow-100);
    }
  }
`;
