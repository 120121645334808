import { DefaultTheme } from 'styled-components/macro';
import { createStyledBreakpointsTheme } from 'styled-breakpoints';
import _mapValues from 'lodash/mapValues';

import { breakpoints } from './media';

const fontSize = 16;

const theme: DefaultTheme = {
  flexa: {
    breakpoints,
  },
  ...createStyledBreakpointsTheme({
    breakpoints: _mapValues<typeof breakpoints, `${number}px`>(
      breakpoints,
      breakpoint => `${breakpoint * fontSize}px`,
    ),
  }),
};

export default theme;
