import {
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  toTypedRxJsonSchema,
} from 'rxdb';

export const scoreSchemaLiteral = {
  version: 0,
  title: 'Score Schema',
  description: '',
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    score: {
      type: 'number',
    },
    type: {
      type: 'string',
      maxLength: 36,
    },
    lesson_id: {
      type: 'string',
      maxLength: 36,
    },
    created_at: {
      type: 'string',
    },
    updated_at: {
      type: 'string',
    },
    deleted_at: {
      type: 'string',
    },
  },
  required: ['id', 'lesson_id', 'type', 'score'],
  indexes: ['lesson_id', 'type'],
} as const;

const schemaTyped = toTypedRxJsonSchema(scoreSchemaLiteral);
export type ScoreDocType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof schemaTyped
>;
export const scoreSchema: RxJsonSchema<ScoreDocType> = scoreSchemaLiteral;
