import { useField } from 'formik';
import * as React from 'react';
import { forwardRef, TextareaHTMLAttributes } from 'react';

import { Col, Row } from '../Grid';
import { Label } from '../Label';
import { Textarea } from '../Textarea';

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label?: string;
  style?: {
    height?: number;
  };
}

export const TextAreaField = forwardRef<HTMLTextAreaElement, Props>(
  (props, ref) => {
    const { name, label } = props;
    const [field, meta, helpers] = useField(name);

    return (
      <Row gutterY={0.25}>
        {label ? (
          <Col xs={12} gutterY={0.25}>
            <Label htmlFor={name}>{label}</Label>
          </Col>
        ) : null}
        <Col xs={12} gutterY={0.25}>
          <Textarea
            {...field}
            {...props}
            invalid={!!(meta.touched && meta.error)}
            ref={ref}
          />
        </Col>
      </Row>
    );
  },
);
