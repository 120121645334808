import React, { InputHTMLAttributes, Ref } from 'react';
import { useField } from 'formik';

import { Input } from '../Input';
import { Col, Row } from '../Grid';
import { Label } from '../Label';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string | React.ReactNode;
  inputRef?: Ref<HTMLInputElement>;
}

export const InputField = (props: Props) => {
  const { name, label, inputRef } = props;
  const [field, meta, helpers] = useField(name);

  return (
    <Row gutterY={0.25}>
      {label ? (
        <Col xs={12} gutterY={0.25}>
          <Label htmlFor={name}>{label}</Label>
        </Col>
      ) : null}
      <Col xs={12} gutterY={0.25}>
        <Input
          {...field}
          {...props}
          invalid={!!(meta.touched && meta.error)}
          ref={inputRef}
        />
      </Col>
    </Row>
  );
};
