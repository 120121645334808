import { css } from 'styled-components';

import { IconWrapper } from '../Icon';
import { ifProp } from 'styled-tools';

export const selectStyles = css`
  .react-select__control {
    min-height: 44px;
    background-color: var(--grey-100);
    font-size: 16px;
    line-height: 22px;
    border: 1px solid ${ifProp('invalid', 'var(--red)', 'var(--grey-100)')};
    box-shadow: none;
    border-radius: 0.5rem;
    transition: none;
    outline: none;

    &:hover {
      border-color: var(--grey-200);
      transition: background-color 0.2s, border-color 0.2s;
    }

    &.react-select__control--menu-is-open {
      background-color: var(--grey-100);
      border-color: var(--grey-200);
      transition: background-color 0.2s, border-color 0.2s;

      .react-select__dropdown-indicator {
        transform: rotate(180deg);
      }
    }
  }

  .react-select__value-container {
    padding: 6px 0 6px 12px;
  }

  .react-select__placeholder {
    color: var(--grey-200);
    font-size: 16px;
    margin-left: 0;
  }

  .react-select__single-value {
    color: var(--black);
    margin-left: 0;
  }

  .react-select__input-container {
    color: var(--black);
    margin: 0;
  }

  .react-select__indicator {
    color: var(--black) !important;

    &.react-select__clear-indicator {
      &:hover {
        color: var(--gold-30) !important;
      }
    }

    &.react-select__dropdown-indicator {
      transition: transform 0.2s;
    }
  }

  .react-select__menu {
    background: var(--grey-700);
    border-radius: 0.5rem;
    line-height: 22px;
    font-size: 16px;
    overflow: hidden;
    margin: 0.25rem 0 0 0;
    padding: 0.25rem;
    min-width: 100%;
    width: auto;
    white-space: nowrap;
    box-shadow: 0 2px 6px rgb(0 0 0 / 20%);
  }

  .react-select__menu-list {
    padding: 0;
  }

  .react-select__option {
    padding: 0.75rem 3rem 0.75rem 0.75rem;
    position: relative;
    border-radius: 4px;

    & > ${IconWrapper} {
      position: absolute;
      right: 0.75rem;
      top: 50%;
      margin-top: -0.75rem;
      display: none;
    }

    &.react-select__option--is-focused {
      background: var(--grey-600) !important;
    }
    &.react-select__option--is-selected {
      background: transparent;
      color: var(--gold-30);
      font-weight: 600;

      & > ${IconWrapper} {
        display: block;
      }
    }
  }

  .react-select__loading-indicator {
    color: var(--white);
  }
`;

export const multiSelectStyles = css`
  ${selectStyles}

  .react-select__control {
    &.react-select__control--menu-is-open {
      .react-select__multi-value {
        background-color: var(--grey-500);

        .react-select__multi-value__remove:hover {
          background: var(--grey-400);
        }
      }
    }
  }

  .react-select__value-container--is-multi {
    .react-select__input-container,
    .react-select__placeholder {
      padding-left: 8px;
    }
  }

  .react-select__value-container--is-multi {
    padding: 4px 6px;

    .react-select__multi-value {
      background: var(--grey-600);

      .react-select__multi-value__label {
        color: var(--white);
        font-size: 16px;
        line-height: 22px;
        padding: 4px 8px;
      }

      .react-select__multi-value__remove {
        padding-left: 7px;
        padding-right: 7px;

        svg {
          width: 16px;
          height: 16px;
        }

        &:hover {
          background: var(--grey-500);
          color: var(--white);
        }
      }
    }
  }
`;
