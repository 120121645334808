import styled from 'styled-components/macro';
import { Tooltip as ReactTooltipOrigin } from 'react-tooltip';

export const Tooltip = styled(ReactTooltipOrigin)`
  &&& {
    padding: 0.25rem 0.5rem;
    border: 1px solid var(--grey-900);
    background: var(--grey-900);
    font-size: 16px;
    line-height: 24px;
    color: var(--white);
    max-width: 400px;
    border-radius: 0.25rem;
    backdrop-filter: blur(0.5rem);
    white-space: pre-line;
    opacity: 0.95;

    &:before,
    &:after {
      display: none;
    }
  }
`;
