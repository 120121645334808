import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { Col, Row } from '../../../components/Grid';
import { InputField } from '../../../components/Fields';
import { Button } from '../../../components/Button';
import {
  optionSchema,
  stringSchema,
  stringSchemaOptional,
} from '../../../../utils/validation-schemas';
import { translationOptions } from '../../../../locales/i18n';
import { SelectField } from '../../../components/Fields/SelectField';
import { Label } from '../../../components/Label';
import { Question } from '../../../components/Question';

const validationSchema = Yup.object({
  id: stringSchemaOptional,
  title: stringSchema,
  author: stringSchemaOptional,
  language: optionSchema.optional(),
});

export type ClassValues = Yup.InferType<typeof validationSchema>;

interface Props {
  initialValues: ClassValues;
  onSubmit: (values: ClassValues) => Promise<void>;
  onCancel: () => void;
  submitLabel?: string;
}

export function ClassForm(props: Props) {
  const { t } = useTranslation();
  const { initialValues, onSubmit, onCancel, submitLabel } = props;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <InputField name="title" label={t('label.name')} autoFocus />
              </Col>
              <Col xs={12} gutterY={1.5}>
                <InputField
                  name="author"
                  label={`${t('label.teacher')} (${t('label.optional')})`}
                />
              </Col>
              <Col xs={12} gutterY={1.5}>
                <SelectField
                  name="language"
                  options={translationOptions}
                  label={
                    <Row gutter={0.125} alignItems="center">
                      <Col gutter={0.125}>
                        <Label>{t('label.language')}</Label>
                      </Col>
                      <Col gutter={0.125}>
                        <Question>{t('message.classLanguage')}</Question>
                      </Col>
                    </Row>
                  }
                />
              </Col>
              <Col xs={12} gutterY={3}>
                <Row justifyContent="center">
                  <Col>
                    <Button border onClick={onCancel}>
                      {t('button.cancel')}
                    </Button>
                  </Col>
                  <Col>
                    <Button yellow type="submit" $loading={isSubmitting}>
                      {submitLabel ||
                        (initialValues.id
                          ? t('button.save')
                          : t('button.create'))}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
        );
      }}
    </Formik>
  );
}
