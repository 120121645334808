import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import WindowScroller from 'react-virtualized/dist/commonjs/WindowScroller';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import CellMeasurer from 'react-virtualized/dist/commonjs/CellMeasurer';
import CellMeasurerCache from 'react-virtualized/dist/commonjs/CellMeasurer/CellMeasurerCache';
import { useParams } from 'react-router-dom';
import { useRxData } from 'rxdb-hooks';
import { Helmet } from 'react-helmet-async';
import { RxDocument } from 'rxdb';
import { useWindowSize } from 'react-use';
import { useTranslation } from 'react-i18next';

import { ClassHeader } from './Header';
import { PageWrapper } from '../../components/PageWrapper';
import { ClassDocType } from '../../../database/schemas/class';
import { CollectionName } from '../../../database/types';
import { Col, Row } from '../../components/Grid';
import { NewLesson } from './NewLesson';
import { Card } from '../../components/Card';
import { LessonDocType } from '../../../database/schemas/lesson';
import { LessonListItem } from './LessonListItem';
import { EditLesson } from './EditLesson';
import { Container } from '../../components/Container';
import { useScore } from '../../hooks/useScore';
import { GameType } from '../../../types/Game';
import { StudyButton } from '../../components/StudyButton';
import { DeleteLesson } from './DeleteLesson';
import { Ellipsis } from '../../components/Ellipsis';
import { Heading } from '../../components/Heading';
import Rating from '../../components/Rating';

const cache = new CellMeasurerCache({
  fixedWidth: false,
  defaultHeight: 0,
});

export function ClassPage() {
  const params = useParams<{ classId: string }>();
  const { t } = useTranslation();
  const classId = params.classId as string;
  const [editDocument, setEditDocument] =
    useState<RxDocument<LessonDocType> | null>(null);
  const [deleteDocument, setDeleteDocument] =
    useState<RxDocument<LessonDocType> | null>(null);
  const { width } = useWindowSize();

  const {
    result: [classDocument],
  } = useRxData<ClassDocType>(CollectionName.Classes, collection =>
    collection.findOne(classId),
  );

  const { result: lessons, isFetching } = useRxData<LessonDocType>(
    CollectionName.Lessons,
    collection =>
      collection.find({
        selector: {
          class_id: classId,
        },
        sort: [{ created_at: 'desc' }],
      }),
  );

  useEffect(() => {
    cache.clearAll();
  }, [lessons?.length, width]);

  const { scoresByLessonId } = useScore({
    classId,
    gameType: GameType.CardQuiz,
  });

  const { classScore } = useScore({
    classId,
    gameType: GameType.CardQuiz,
  });

  const onEdit = useCallback((document: RxDocument<LessonDocType>) => {
    setEditDocument(document);
  }, []);

  const onEditClose = useCallback(() => {
    setEditDocument(null);
  }, []);

  const onDelete = useCallback((document: RxDocument<LessonDocType>) => {
    setDeleteDocument(document);
  }, []);

  const onDeleteClose = useCallback(() => {
    setDeleteDocument(null);
  }, []);

  const rowRenderer = ({ index, key, style, parent }) => {
    const data = lessons[index];

    if (!data) return null;

    return (
      <CellMeasurer cache={cache} key={key} parent={parent} rowIndex={index}>
        {({ registerChild, measure }) => (
          <div style={style}>
            <div ref={registerChild}>
              <LessonListItem
                key={`${data.id}_${data._data._rev}`}
                lesson={data}
                onDelete={onDelete}
                onEdit={onEdit}
                score={scoresByLessonId[data.id]}
                measure={measure}
              />
            </div>
          </div>
        )}
      </CellMeasurer>
    );
  };

  return (
    <>
      <Helmet>
        <title>{classDocument?.title}</title>
      </Helmet>
      <ClassHeader title={classDocument?.title} />
      <PageWrapper>
        <Container $fixed>
          <Row>
            <Col xs={12}>
              <Row alignItems="center" gutter={2}>
                <Col
                  gutter={2}
                  xs="auto"
                  display={{ xs: 'block', md: 'none' }}
                />
                <Col gutter={2} xs="auto" display={{ xs: 'none', md: 'block' }}>
                  <Row flexWrap="nowrap" gutter={0.5}>
                    <Col flex="0 1 auto" gutter={0.5}>
                      <Heading white level={3}>
                        <Ellipsis>{classDocument?.title}</Ellipsis>
                      </Heading>
                    </Col>
                    <Col gutter={0.5}>
                      <Rating rating={classScore} numberOfStars={3} />
                    </Col>
                  </Row>
                </Col>
                <Col gutter={2}>
                  <Row>
                    <Col>
                      <StudyButton
                        lessonsId={(lessons || []).map(
                          lesson => lesson._data.id,
                        )}
                        label={t('button.playClass')}
                      />
                    </Col>
                    {!classDocument?._data?.shared && !isFetching && (
                      <Col>
                        <NewLesson
                          isFirst={!lessons.length}
                          classId={classId}
                        />
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12} gutterY={{ xs: 1, sm: 1.5 }}>
              <Card style={{ marginBottom: 24 }}>
                <WindowScroller>
                  {({ height, isScrolling, scrollTop }) => (
                    <AutoSizer disableHeight>
                      {({ width }) => (
                        <List
                          className="List"
                          autoHeight
                          height={height}
                          width={width}
                          rowCount={lessons.length || 0}
                          rowHeight={cache.rowHeight}
                          rowRenderer={rowRenderer}
                          scrollTop={scrollTop}
                        />
                      )}
                    </AutoSizer>
                  )}
                </WindowScroller>
              </Card>
            </Col>
          </Row>
        </Container>
      </PageWrapper>
      <EditLesson lesson={editDocument} onClose={onEditClose} />
      <DeleteLesson lesson={deleteDocument} onClose={onDeleteClose} />
    </>
  );
}
