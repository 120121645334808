import { useRxCollection } from 'rxdb-hooks';
import { useAsync, useNetworkState } from 'react-use';
import { AxiosResponse } from 'axios';

import { MediaDocType } from '../../database/schemas/media';
import { CollectionName } from '../../database/types';
import { api } from '../../services/api';
import { File } from '../../types/Media';

interface Props {
  path: string;
}

export function useMediaFile(props: Props) {
  const { path } = props;
  const { online } = useNetworkState();

  const collection = useRxCollection<MediaDocType>(CollectionName.Media);

  const {
    value: src,
    loading,
    error,
  } = useAsync(async () => {
    const result = await collection?.findOne(path).exec();

    if (result?.data) {
      return result?.data;
    }

    return path;
  }, [online, collection, path]);

  return {
    src,
    loading,
    error,
  };
}
