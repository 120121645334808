import React from 'react';
import styled from 'styled-components';
import OriginAsyncCreatableSelect from 'react-select/async-creatable';
import { useTranslation } from 'react-i18next';

import { multiSelectStyles } from './styles';
import {
  ClearIndicator,
  DropdownIndicator,
  MultiValueRemove,
} from './components';

export const AsyncCreatableSelect = props => {
  const { t } = useTranslation();

  const {
    options = [],
    value,
    onChange,
    name,
    loadOptions,
    isLoading,
    components,
    placeholder,
    invalid,
    onCreateOption,
    defaultOptions,
    formatCreateLabel,
    isMulti,
  } = props;

  return (
    <StyledAsyncCreatableSelect
      classNamePrefix="react-select"
      options={options}
      value={value}
      placeholder={placeholder}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator,
        ClearIndicator,
        MultiValueRemove,
        ...components,
      }}
      onChange={onChange}
      loadOptions={loadOptions}
      onCreateOption={onCreateOption}
      isLoading={isLoading}
      defaultOptions={defaultOptions}
      formatCreateLabel={formatCreateLabel}
      noOptionsMessage={({ inputValue }) => {
        return inputValue ? t('message.noOptions') : t('message.typeToSearch');
      }}
      invalid={invalid}
      isMulti={isMulti}
    />
  );
};

const StyledAsyncCreatableSelect = styled(OriginAsyncCreatableSelect)<{
  border?: boolean;
  invalid?: boolean;
}>`
  ${multiSelectStyles}
`;
