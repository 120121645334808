import { useCallback, useEffect, useRef, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';
import { useDropzone } from 'react-dropzone';
import _groupBy from 'lodash/groupBy';
import _mapValues from 'lodash/mapValues';
import { v4 as uuidv4 } from 'uuid';
import Papa from 'papaparse';
import { RxDocument } from 'rxdb';
import { useNavigate } from 'react-router-dom';

import { exportFormatToFlashcard } from '../../utils/flashcard-export';
import { CollectionName } from '../../database/types';
import { ClassDocType } from '../../database/schemas/class';
import { LessonDocType } from '../../database/schemas/lesson';
import { FlashCardDocType } from '../../database/schemas/flashcard';
import {
  AnswerType,
  FlashcardExport,
  QuestionType,
} from '../../types/FlashCard';
import { useFlashcardsLimit } from './useFlashcardsLimit';
import { useTranslation } from 'react-i18next';

interface Props {
  classDocument?: RxDocument<ClassDocType>;
}

const flashcardIsValid = (flashcard: FlashcardExport) => {
  const { class_name, lesson_name } = flashcard;

  const { question_type, question_value, answer_type, answer_value } =
    exportFormatToFlashcard(flashcard);

  const classNameInvalid = !class_name;
  const lessonNameInvalid = !lesson_name;
  const questionTypeInvalid =
    !Object.values(QuestionType).includes(question_type);
  const questionValueInvalid = !question_value;
  const answerTypeInvalid =
    question_type !== QuestionType.Spelling &&
    !Object.values(AnswerType).includes(answer_type);
  const answerValueInvalid =
    (answer_type === AnswerType.Text && !answer_value) ||
    (answer_value === AnswerType.MultipleChoice &&
      typeof answer_value !== 'object');

  if (
    classNameInvalid ||
    lessonNameInvalid ||
    questionTypeInvalid ||
    questionValueInvalid ||
    answerTypeInvalid ||
    answerValueInvalid
  ) {
    return false;
  }

  return true;
};

export function useClassImport(props: Props = {}) {
  const { classDocument } = props;
  const [loading, setLoading] = useState(false);
  const classCollection = useRxCollection<ClassDocType>(CollectionName.Classes);
  const lessonCollection = useRxCollection<LessonDocType>(
    CollectionName.Lessons,
  );
  const flashcardCollection = useRxCollection<FlashCardDocType>(
    CollectionName.FlashCards,
  );
  const reader = useRef(new FileReader());
  const { limit, getCount } = useFlashcardsLimit();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onFileRead = useCallback(
    async event => {
      setLoading(true);

      try {
        const count = await getCount();
        const dataParsed: FlashcardExport[] = Papa.parse(event.target.result, {
          header: true,
        }).data;

        const validFlashcards = dataParsed.filter(flashcardIsValid);
        const invalidFlashcardsCount =
          dataParsed.length - validFlashcards.length;

        if (count && limit && count + validFlashcards?.length >= limit) {
          return navigate(`/pricing?message=planLimitReached`);
        }

        if (
          invalidFlashcardsCount &&
          !window.confirm(t('message.flashcardsImportError'))
        ) {
          return;
        }

        const groupedByClass = classDocument
          ? {
              [classDocument?._data?.title]: validFlashcards,
            }
          : _groupBy(validFlashcards, 'class_name');
        const groupedByClassAndLesson = _mapValues(groupedByClass, lessons =>
          _groupBy(lessons, 'lesson_name'),
        );

        const parsedFlashcards = _mapValues(groupedByClassAndLesson, classes =>
          _mapValues(classes, lessons => lessons.map(exportFormatToFlashcard)),
        );

        for (const className in parsedFlashcards) {
          const { class_author, class_language } = groupedByClass[className][0];

          const newClass =
            classDocument ||
            (await classCollection?.insert({
              title: className,
              author: class_author,
              language: class_language,
              id: uuidv4(),
              shared: false,
            }));

          if (!newClass) return;

          const lessons = parsedFlashcards[className];

          for (const lessonName in lessons) {
            const lessonId = uuidv4();

            await lessonCollection?.insert({
              title: lessonName,
              class_id: newClass.id,
              id: lessonId,
            });

            const flashcards = parsedFlashcards[className][lessonName].map(
              flashcard => ({
                ...flashcard,
                lesson_id: lessonId,
                id: uuidv4(),
              }),
            );

            await flashcardCollection?.bulkInsert(flashcards);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [
      classCollection,
      lessonCollection,
      flashcardCollection,
      classDocument,
      limit,
    ],
  );

  useEffect(() => {
    reader.current.addEventListener('load', onFileRead);
    return () => {
      reader.current.removeEventListener('load', onFileRead);
    };
  }, [onFileRead]);

  const onDrop = (acceptedFiles, fileRejections) => {
    if (fileRejections?.length) {
      if (fileRejections[0]?.file?.size > 1048576) {
        alert('The file you are uploading is too large!');
      } else {
        alert('Sorry, something went wrong. Please try again later.');
      }
    } else {
      reader.current.readAsText(acceptedFiles[0]);
    }
  };

  const { open } = useDropzone({
    accept: {
      'text/csv': ['.csv'],
    },
    multiple: false,
    onDrop,
    maxSize: 1048576,
  });

  return { importClass: open, loading };
}
