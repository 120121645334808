import omitBy from 'lodash/omitBy';
import has from 'lodash/has';
import includes from 'lodash/includes';

const safeProps = ['children'];

export const filterProps = (props, filterOut) =>
  omitBy(
    props,
    (value, key) => has(filterOut, `${key}`) && !includes(safeProps, key),
  );

export default filterProps;
