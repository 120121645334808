import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components/macro';

import { Icon, IconName } from '../Icon';
import { Tooltip } from '../Tooltip';

interface QuestionProps {
  children: string;
  iconName?: IconName;
}

export function Question(props: QuestionProps) {
  const { children, iconName } = props;
  const id = uuidv4();

  return (
    <Wrapper data-tooltip-id={id}>
      <Icon $size={20} name={iconName || 'question-1'} />
      <Tooltip id={id}>{children}</Tooltip>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  line-height: 0;
  text-transform: none;
  width: 20px;
  height: 20px;
`;
