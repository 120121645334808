import React, {
  DetailedHTMLProps,
  HTMLAttributes,
  PropsWithChildren,
} from 'react';
import styled, { keyframes } from 'styled-components/macro';

import { Loader } from '../Loader';
import { Col, Row } from '../Grid';
import { Heading } from '../Heading';
import { media } from '../../../styles/media';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';

export interface PageWrapperProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  loading?: boolean;
  loadingMessage?: string;
  withSidebar?: boolean;
}

const defaultProps = {
  loading: false,
};

export function PageWrapper(
  props: PropsWithChildren<PageWrapperProps> & typeof defaultProps,
) {
  const { loading, children, loadingMessage, style, withSidebar } = props;

  return (
    <Wrapper style={style} $withSidebar={withSidebar}>
      {children}
      {loading ? (
        <LoaderBackground>
          <LoaderWrapper>
            <Row gutter={0.75} alignItems="center" flexWrap="nowrap">
              <Col gutter={0.75}>
                <Loader />
              </Col>
              {loadingMessage ? (
                <Col xs="auto" gutter={0.75}>
                  <LoaderMessage>{`${loadingMessage}...`}</LoaderMessage>
                </Col>
              ) : null}
            </Row>
          </LoaderWrapper>
        </LoaderBackground>
      ) : null}
    </Wrapper>
  );
}

PageWrapper.defaultProps = defaultProps;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Wrapper = styled.div<{ $withSidebar?: boolean }>`
  display: flex;
  flex: 1;
  width: 100%;

  ${ifProp(
    `$withSidebar`,
    css`
      padding: 4.5rem 0 1rem;

      ${media.md} {
        padding: 6.25rem 0 1.5rem;
      }

      ${media.lg} {
        padding: 1rem 0 1rem 20rem;
      }
    `,

    css`
      padding: 4.5rem 0 1rem;

      ${media.md} {
        padding: 6.25rem 0 1.5rem;
      }
    `,
  )}
`;

const LoaderBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(var(--blue-200-rgb), 0.7);
  animation: ${fadeIn} 0.2s ease-out;
`;

const LoaderWrapper = styled.div`
  background: var(--white);
  padding: 1rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1.75rem;
  color: var(--blue-100);
  max-width: none;
  width: auto;

  ${Loader} {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;

    &:after {
      width: 1.5rem;
      height: 1.5rem;
      border-color: rgba(var(--blue-100-rgb), 0.2);
      border-top-color: var(--blue-100);
      margin: -0.75rem 0 0 -0.75rem;
    }
  }
`;

const LoaderMessage = styled(Heading)`
  font-weight: 500;
  font-family: var(--font-secondary);
  font-size: 20px;
  line-height: 24px;
  position: relative;
  top: 2px;
  margin-right: 0.25rem;
  white-space: nowrap;
`;
