import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { Provider as ProviderOrigin } from 'rxdb-hooks';
import { useAsyncFn } from 'react-use';
import { useTranslation } from 'react-i18next';

import {
  addCollections,
  addReplications,
  createDatabase,
} from './configureDatabase';
import { AuthContext } from '../app/context/AuthContext';
import { PageWrapper } from '../app/components/PageWrapper';

export function DatabaseProvider(props: PropsWithChildren) {
  const { t } = useTranslation();
  const { children } = props;
  const { isAuthenticated, user } = useContext(AuthContext);

  const [{ loading, error, value: db }, initDatabase] = useAsyncFn(
    async user => {
      const db = await createDatabase(user);
      await addCollections(db);
      await addReplications(db);

      return db;
    },
  );

  if (error) {
    console.error(error);
  }

  useEffect(() => {
    if (isAuthenticated && !db) {
      initDatabase(user);
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) return <>{children}</>;

  if (loading)
    return (
      <PageWrapper loading loadingMessage={t('message.synchronizingData')} />
    );

  return <ProviderOrigin db={db}>{children}</ProviderOrigin>;
}
