import styled from 'styled-components/macro';
import { Col, Row } from '../Grid';
import { Icon } from '../Icon';
import React from 'react';

export const RotateDevice = () => {
  return (
    <Wrapper>
      <Row alignItems="center">
        <Col>
          <Icon name="rotate-device" />
        </Col>
        <Col>
          <div>Turn your device to portrait mode</div>
        </Col>
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  color: white;
  display: flex;
  height: 100%;
  align-items: center;

  svg {
    width: 3rem;
    height: 3rem;
  }
`;
