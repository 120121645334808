import React from 'react';
import { RxDocument } from 'rxdb';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import styled from 'styled-components/macro';
import { useRxData } from 'rxdb-hooks';

import { FlashCardDocType } from '../../../../../database/schemas/flashcard';
import { AudioButton } from '../../../../components/AudioButton';
import { api } from '../../../../../services/api';
import { blobToBase64 } from '../../../../../utils/file-manipulatiion';
import { Loader } from '../../../../components/Loader';
import { ClassDocType } from '../../../../../database/schemas/class';
import { CollectionName } from '../../../../../database/types';
import { LessonDocType } from '../../../../../database/schemas/lesson';
import { Col, Row } from '../../../../components/Grid';

interface Props {
  flashcard: RxDocument<FlashCardDocType>;
}

export const TextToSpeech = (props: Props) => {
  const { flashcard } = props;
  const text = flashcard.question_value;
  const { i18n } = useTranslation();

  const {
    result: [lesson],
    isFetching: lessonIsFetching,
  } = useRxData<LessonDocType>(CollectionName.Lessons, collection =>
    collection.findByIds([flashcard.lesson_id]),
  );

  const {
    result: [classDocument],
    isFetching: classIsFetching,
  } = useRxData<ClassDocType>(CollectionName.Classes, collection =>
    collection.findByIds([lesson?.class_id]),
  );

  const language = classDocument?._data.language || i18n.language;

  const { value: src, loading } = useAsync(async () => {
    if (!lessonIsFetching && !classIsFetching) {
      const response = await api.get(
        `/voice/text-to-speech?text=${text}&language=${language}`,
        {
          responseType: 'blob',
        },
      );

      return await blobToBase64(response.data);
    }
  }, [text, lessonIsFetching, classIsFetching]);

  return (
    <Row justifyContent="center">
      <Col>
        {loading && <LoaderWrapper color="var(--blue-200-rgb)" />}
        {!loading && src && <AudioButton src={src} />}
      </Col>
    </Row>
  );
};

const LoaderWrapper = styled(Loader)`
  position: relative;
  width: 2rem;
  height: 2rem;
`;
