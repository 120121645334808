import { RxDocument } from 'rxdb';
import styled from 'styled-components/macro';
import { v4 as uuidv4 } from 'uuid';
import { useCallback, useMemo } from 'react';
import { OutputData } from '@editorjs/editorjs';
import { useRxCollection, useRxQuery } from 'rxdb-hooks';
import { useParams } from 'react-router-dom';

import { BoardDocType } from '../../../../database/schemas/board';
import { Editor } from '../../../components/Editor';
import { media } from '../../../../styles/media';
import { BoardImage } from '../../../../types/Board';
import { MediaDocType } from '../../../../database/schemas/media';
import { CollectionName } from '../../../../database/types';

interface Props {
  board?: RxDocument<BoardDocType>;
}

export function Board(props: Props) {
  const { board } = props;
  const { lessonId } = useParams<{ lessonId: string }>();
  const boardsCollection = useRxCollection<BoardDocType>(CollectionName.Boards);
  const mediaCollection = useRxCollection<MediaDocType>(CollectionName.Media);

  const imagesPath = useMemo(() => {
    if (!board?.data?.blocks) return [];

    return board.data?.blocks
      .filter(({ type }) => type === 'image')
      .map<string>(block => (block?.data as BoardImage)?.file?.mediaPath);
  }, [board]);

  const mediaQuery = useMemo(
    () =>
      mediaCollection?.find({
        selector: {
          path: {
            $in: imagesPath,
          },
        },
      }),
    [mediaCollection, board?._data.id],
  );

  const { result: medias, isFetching: mediasIsFetching } =
    useRxQuery(mediaQuery);

  const defaultValue = useMemo(() => {
    if (!board?._data?.data) return undefined;

    const data = { ...board?._data?.data } as OutputData;

    data.blocks = data.blocks?.map(block => {
      if (block.type === 'image') {
        const data = block.data as BoardImage;
        const media = medias.find(({ path }) => path === data.file.mediaPath);

        return {
          ...block,
          data: {
            ...data,
            file: {
              mediaPath: media?._data?.path || data.file.mediaPath,
              url: media?._data?.data || data.file.mediaPath,
            },
          },
        };
      }

      return block;
    });

    return data;
  }, [board?._data._rev, mediasIsFetching]);

  const parseFile = (file: File) => {
    return new Promise<string>(resolve => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(file);
    });
  };

  const uploadByFile = async file => {
    const data = await parseFile(file);

    const media = await mediaCollection?.insert({
      mime: file.type,
      data,
    });

    return {
      success: 1,
      file: {
        mediaPath: media?._data.path as string,
        url: media?._data.data as string,
      },
    };
  };

  const onSave = useCallback(
    async (value: OutputData | undefined) => {
      if (boardsCollection) {
        try {
          const data = {
            ...value,
            blocks: value?.blocks.map(block => {
              if (block.type === 'image') {
                return {
                  ...block,
                  data: {
                    ...block.data,
                    file: {
                      mediaPath: block.data.file?.mediaPath,
                    },
                  },
                };
              }

              return block;
            }),
          };

          // TODO: Удалять неиспользуемые картинки
          if (board) {
            await board.incrementalUpdate({
              $set: {
                data,
              },
            });
          } else {
            await boardsCollection?.insert({
              id: uuidv4(),
              lesson_id: lessonId as string,
              data,
            });
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    [board?._data._rev, boardsCollection],
  );

  if (mediasIsFetching) return null;

  return (
    <EditorContainer>
      <Editor
        uploadByFile={uploadByFile}
        onSave={onSave}
        defaultValue={defaultValue}
        readOnly={board?._data?.shared}
      />
    </EditorContainer>
  );
}

const EditorContainer = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
  background: var(--white);
  margin: 0 auto;
  overflow: auto;
  padding: 1rem 1.5rem;

  ${media.md} {
    padding: 2rem 1rem;
  }
`;
