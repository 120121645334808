import SliderOrigin from 'rc-slider';
import styled from 'styled-components/macro';

export const Slider = styled(SliderOrigin)`
  position: relative;

  .rc-slider-rail {
    height: 4px;
    background: var(--grey-150);
    border-radius: 2px;
  }

  .rc-slider-track {
    background: var(--red);
    height: 4px;
    border-radius: 2px;
    position: absolute;
    top: 0;
  }
`;
