import React, { useMemo } from 'react';
import { useRxData } from 'rxdb-hooks';
import { FlashCardDocType } from '../../../../database/schemas/flashcard';
import { CollectionName } from '../../../../database/types';
import { Span } from '../../../components/Span';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useTheme } from 'styled-components/macro';
import { Dot } from '../../../components/Dot';

interface Props {
  lessonId: string;
  score?: number;
}

export function LessonStatus(props: Props) {
  const { lessonId, score } = props;
  const { t } = useTranslation();
  const isMd = useMediaQuery(useTheme()?.breakpoints.up('md'));

  const { result: flashcards } = useRxData<FlashCardDocType>(
    CollectionName.FlashCards,
    collection =>
      collection.findOne({
        selector: {
          lesson_id: lessonId,
        },
      }),
  );

  const status = useMemo(() => {
    if (typeof score === 'number') {
      if (isMd) return <Span color="green">{t('label.played')}</Span>;
      return <Dot color="green" />;
    }

    if (flashcards.length) {
      if (isMd) return <Span color="yellow">{t('label.notPlayed')}</Span>;
      return <Dot color="yellow" />;
    }

    if (isMd) return <Span color="red">{t('label.empty')}</Span>;
    return <Dot color="red" />;
  }, [flashcards.length, isMd, score]);

  return <div style={{ overflow: 'hidden', height: '1.25rem' }}>{status}</div>;
}
