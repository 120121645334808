import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSwiper } from 'swiper/react';

import { Col, Row } from '../../../../../components/Grid';
import { StyledButton } from '../../../../../components/Button';
import { LessonCardContext } from '../../../../../components/LessonCard';

export function GameNextButton(props) {
  const { tabIndex } = props;
  const { t } = useTranslation();
  const { onFlip, flippable } = useContext(LessonCardContext);
  const swiper = useSwiper();
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (tabIndex === -1) {
      buttonRef.current?.focus();
    }
  }, [tabIndex]);

  if (!flippable) return null;

  const onClick = () => {
    swiper.slideNext();
  };

  return (
    <Row justifyContent="center">
      <Col>
        <StyledButton blue onClick={onClick} type="button" ref={buttonRef}>
          {t('button.next')}
        </StyledButton>
      </Col>
    </Row>
  );
}
