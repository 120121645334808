import { replicateRxCollection } from 'rxdb/plugins/replication';
import { ReplicationPullHandlerResult } from 'rxdb/dist/types/types/plugins/replication';

import { FlashCardDocType } from './schema';
import { api } from '../../../services/api';
import { FlashCardDto } from '../../../types/FlashCard';

type Checkpoint = Pick<FlashCardDocType, 'updated_at'>;

export async function sharedFlashCardReplication(collection) {
  return replicateRxCollection<FlashCardDocType, Checkpoint>({
    collection,
    replicationIdentifier: `${process.env.REACT_APP_API_URL}/shared-flashcard`,
    autoStart: true,
    live: true,
    deletedField: 'deleted_at',
    pull: {
      batchSize: 1000,
      async handler(
        lastCheckpoint,
        batchSize,
      ): Promise<ReplicationPullHandlerResult<FlashCardDto, Checkpoint>> {
        const minTimestamp = new Date(0);

        const response = await api.get(`/flashcard/sync`, {
          params: {
            last_checkpoint: minTimestamp,
            batchSize,
            show_shared_entities: true,
          },
        });

        const documents = response.data || [];

        return {
          documents,
          checkpoint: {
            updated_at: new Date(0).toISOString(),
          },
        };
      },
      modifier: doc => {
        let question_value = doc.question_value;
        let answer_value = doc.answer_value;

        try {
          question_value = JSON.parse(question_value);
        } catch (error) {}

        try {
          answer_value = JSON.parse(answer_value);
        } catch (error) {}

        return {
          ...doc,
          question_value,
          answer_value,
          shared: true,
        };
      },
    },
  });
}
