import React, { useCallback, useRef, useState } from 'react';
import { Col, Row } from '../Grid';
import styled from 'styled-components/macro';
import { Slider } from './Slider';
import { ButtonIcon } from '../ButtonIcon';
import { Icon } from '../Icon';

interface Props {
  src: string;
}

function timeFormatted(s) {
  const minutes = Math.floor(s / 60);
  const seconds = Math.floor(s % 60);

  return `${minutes >= 10 ? minutes : '0' + minutes}:${
    seconds >= 10 ? seconds : '0' + seconds
  }`;
}

export function AudioPlayer({ src }: Props) {
  const [playing, setPlaying] = useState(false);
  const ref = useRef<HTMLAudioElement>(null);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [draggind, setDraggind] = useState(false);

  const onTimeUpdate = () => {
    if (ref.current) {
      if (ref.current.duration !== Infinity && loading) {
        setLoading(false);
        ref.current.currentTime = 0;
      }

      if (!loading) {
        setProgress(ref.current.currentTime / ref.current.duration);
      }
    }
  };

  const onLoadedMetadata = () => {
    if (
      ref.current &&
      (ref.current.duration === Infinity || isNaN(Number(ref.current.duration)))
    ) {
      setLoading(true);
      ref.current.currentTime = 1e101;
    }
  };

  const onPlay = useCallback(() => {
    setPlaying(true);
  }, []);

  const onPause = useCallback(() => {
    setPlaying(false);
  }, []);

  const onEnded = useCallback(() => {
    setPlaying(false);
  }, [ref.current]);

  const play = () => {
    if (ref.current && progress === 1) {
      ref.current.currentTime = 0;
    }

    ref.current?.play();
  };

  const pause = () => {
    ref.current?.pause();
  };

  const onChange = value => {
    setDraggind(true);

    if (ref.current) {
      ref.current.currentTime = value;
    }
  };

  return (
    <Row alignItems="center" flexWrap="nowrap">
      <Col>
        <PlayButton onClick={playing ? pause : play} block>
          <Icon name={playing ? 'pause' : 'play'} />
        </PlayButton>
      </Col>
      <Col>{timeFormatted(ref.current?.currentTime || 0)}</Col>
      <Col xs="auto">
        <Slider
          step={0.01}
          min={0}
          max={ref.current?.duration || 0}
          value={ref.current?.currentTime}
          onChange={onChange}
        />
      </Col>
      <Col>{timeFormatted(ref.current?.duration || 0)}</Col>
      <Audio
        ref={ref}
        src={src}
        onPlay={onPlay}
        onPause={onPause}
        onEnded={onEnded}
        onTimeUpdate={onTimeUpdate}
        onLoadedMetadata={onLoadedMetadata}
      />
    </Row>
  );
}

const Audio = styled.audio`
  display: none;
`;

const PlayButton = styled(ButtonIcon)`
  color: var(--red) !important;
`;
