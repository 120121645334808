import React from 'react';
import { useTheme } from 'styled-components/macro';
import { useRxData } from 'rxdb-hooks';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';

import { Col, Row } from '../../../../components/Grid';
import { Container } from '../../../../components/Container';
import { Heading } from '../../../../components/Heading';
import { ClassDocType } from '../../../../../database/schemas/class';
import { CollectionName } from '../../../../../database/types';
import { Ellipsis } from '../../../../components/Ellipsis';
import { Block } from '../../../../components/Block';
import { Icon } from '../../../../components/Icon';
import { Header } from '../../../../components/Header';
import { BackButton } from '../../../../components/BackButton';

type Props = {
  title?: string;
  classId: string;
};

export function LessonHeader(props: Props) {
  const { title, classId } = props;
  const isMd = useMediaQuery(useTheme()?.breakpoints.up('md'));
  const isLg = useMediaQuery(useTheme()?.breakpoints.up('lg'));

  const {
    result: [classDocument],
  } = useRxData<ClassDocType>(CollectionName.Classes, collection =>
    collection.findOne(classId),
  );

  if (isLg) return null;

  return (
    <Header>
      <Container>
        <Row
          justifyContent="space-between"
          alignItems="center"
          gutter={{ xs: 1.5, md: 1 }}
        >
          <Col md="auto">
            <BackButton to={`/class/${classId}`} block withPadding>
              <Row alignItems="center" gutter={0.5}>
                <Col gutter={0.5}>
                  <Icon name="chevron-left-1" />
                </Col>
                <Col xs="auto" gutter={0.5}>
                  <Heading level={3}>
                    <Ellipsis>{isMd ? classDocument?.title : ''}</Ellipsis>
                  </Heading>
                </Col>
              </Row>
            </BackButton>
          </Col>
          <Col xs="auto" gutter={2}>
            <Block textCenter>
              <Heading level={3}>
                <Ellipsis>{title}</Ellipsis>
              </Heading>
            </Block>
          </Col>
          <Col xs="3.625rem" md="auto" />
        </Row>
      </Container>
    </Header>
  );
}
