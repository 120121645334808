import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '../../../components/Modal';
import { Col, Row } from '../../../components/Grid';
import { Heading } from '../../../components/Heading';
import { ClassForm, ClassValues } from '../ClassForm';
import { ClassDocType } from '../../../../database/schemas/class';
import { RxDocument } from 'rxdb';
import { translationOptions } from '../../../../locales/i18n';
import { Block } from '../../../components/Block';

interface Props {
  document: RxDocument<ClassDocType> | null;
  onClose: () => void;
}

export function EditClass(props: Props) {
  const { document, onClose } = props;
  const { t, i18n } = useTranslation();

  if (!document) return null;

  const initialValues: ClassValues = {
    id: document.id,
    title: document?.title || '',
    author: document?.author || '',
    language: translationOptions.find(
      option => option.value === (document?.language || i18n.language),
    ),
  };

  const onSubmit = async (values: ClassValues) => {
    try {
      await document.update({
        $set: {
          ...values,
          language: values.language?.value,
        },
      });
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal isOpen onBackgroundClick={onClose} onEscapeKeydown={onClose}>
      <Row justifyContent="center">
        <Col xs={12}>
          <Block textCenter>
            <Heading level={3}>{t('title.editClass')}</Heading>
          </Block>
        </Col>
        <Col xs={12} gutterY={2}>
          <ClassForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            onCancel={onClose}
          />
        </Col>
      </Row>
    </Modal>
  );
}
