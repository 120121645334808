import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ModalProvider } from 'styled-react-modal';
import { BrowserRouter } from 'react-router-dom';

import { ModalBackground } from './app/components/Modal';
import { AuthProvider } from './app/context/AuthContext';
import { ThemeProvider } from './styles/ThemeProvider';

import 'sanitize.css/assets.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/sanitize.css';

import { App } from 'app';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { HelmetProvider } from 'react-helmet-async';

import { configureAppStore } from 'store/configureStore';

import reportWebVitals from 'reportWebVitals';

import './locales/i18n';

const store = configureAppStore();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={store}>
    <ThemeProvider>
      <HelmetProvider>
        <BrowserRouter>
          <AuthProvider>
            <ModalProvider backgroundComponent={ModalBackground}>
              <App />
            </ModalProvider>
          </AuthProvider>
        </BrowserRouter>
      </HelmetProvider>
    </ThemeProvider>
  </Provider>,
);

if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {});
}

serviceWorkerRegistration.unregister();
reportWebVitals();
