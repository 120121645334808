import React, { ReactNode, useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../context/AuthContext';
import { PageWrapper } from '../components/PageWrapper';

interface Props {
  children?: ReactNode;
}

export const PrivateRoute = (props: Props) => {
  const { t } = useTranslation();
  const { children } = props;
  const { isAuthenticated, loadingUserData } = useContext(AuthContext);

  if (loadingUserData) {
    return (
      <PageWrapper loading loadingMessage={t('message.gettingUserData')} />
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" replace />;
  }

  return children ? <>{children}</> : <Outlet />;
};
