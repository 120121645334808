import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { useAudioRecorder } from '../../../../hooks/useAudioRecorder';
import { Icon } from '../../../../components/Icon';
import { PulseIconButton } from '../../../../components/PulseIconButton';
import { Col, Row } from '../../../../components/Grid';
import { Button } from '../../../../components/Button';
import { Modal } from '../../../../components/Modal';
import { AudioButton } from '../../../../components/AudioButton';
import { base64MimeType } from '../../../../../utils/file-manipulatiion';
import { Span } from '../../../../components/Span';

const reader = new FileReader();

interface Props {
  onUpload: ({ type, data }: { type: string; data: string }) => void;
}

export function AudioRecord(props: Props) {
  const { onUpload } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<string>();

  const onFileRead = event => {
    setData(event.target.result);
  };

  useEffect(() => {
    reader.addEventListener('load', onFileRead);
    return () => {
      reader.removeEventListener('load', onFileRead);
    };
  }, []);

  const onRecordingComplete = (blob: Blob) => {
    reader.addEventListener('load', onFileRead);
    reader.readAsDataURL(blob);
  };

  const { startRecording, stopRecording, isRecording } = useAudioRecorder({
    onRecordingComplete,
  });

  const onCancel = () => {
    setIsOpen(false);
    setData(undefined);
  };

  const onOpen = () => {
    setIsOpen(true);
  };

  const onSave = () => {
    if (data) {
      onUpload({ type: base64MimeType(data), data });
      setIsOpen(false);
    }

    setData(undefined);
  };

  return (
    <>
      <RecordButton white block onClick={onOpen}>
        <Row gutter={0.5}>
          <Col gutter={0.5}>
            <Span color="red">
              <Icon name="microphone-solid" />
            </Span>
          </Col>
          <Col gutter={0.5} display={{ xs: 'none', md: 'block' }}>
            <Span color="red">{t('button.record')}</Span>
          </Col>
        </Row>
      </RecordButton>
      <Modal
        isOpen={isOpen}
        onBackgroundClick={onCancel}
        onEscapeKeydown={onCancel}
      >
        <Row justifyContent="center">
          <Col>
            <Wrapper>
              {data ? (
                <AudioButton src={data} color="--red-rgb" />
              ) : (
                <PulseIconButton
                  withPadding
                  $active={isRecording}
                  onClick={isRecording ? stopRecording : startRecording}
                  color="--red-rgb"
                >
                  <Icon name="microphone" />
                </PulseIconButton>
              )}
            </Wrapper>
          </Col>
          <Col xs={12} gutterY={1.5}>
            <Row justifyContent="center">
              <Col>
                <Button border onClick={onCancel}>
                  {t('button.cancel')}
                </Button>
              </Col>
              <Col>
                <Button yellow onClick={onSave}>
                  {t('button.save')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

const Wrapper = styled.div`
  padding: 8rem 0;
`;

const RecordButton = styled(Button)`
  color: var(--red);
`;
