import React, { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { Col, Row } from '../Grid';
import { Icon } from '../Icon';
import { ButtonIcon } from '../ButtonIcon';
import { Heading } from '../Heading';
import { media } from '../../../styles/media';
import { DeleteButton } from '../DeleteButton';

interface Props {
  title?: string;
  onDelete?: () => Promise<void>;
  onEdit?: () => void;
  onCreate?: () => void;
}

export function LessonCardHeader(props: PropsWithChildren<Props>) {
  const { title, children, onDelete, onEdit } = props;
  const { t } = useTranslation();

  if (children) {
    return <Wrapper>{children}</Wrapper>;
  }

  return (
    <Wrapper>
      <Row alignItems="center" height="44px" gutter={0}>
        <Col xs="auto" gutter={0}>
          <Heading level={4}>{title}</Heading>
        </Col>
        {onEdit ? (
          <Col gutter={0}>
            <ButtonIcon block withPadding onClick={onEdit}>
              <Icon name="pencil-8" />
            </ButtonIcon>
          </Col>
        ) : null}
        {onDelete ? (
          <Col gutter={0}>
            <DeleteButton
              onDelete={onDelete}
              name={t('label.flashcard', { count: 1 })}
            />
          </Col>
        ) : null}
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 6px 1rem;
  position: relative;

  ${media.md} {
    padding: 14px 1.5rem;
  }

  &:before {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 1rem;
    right: 1rem;
    border-bottom: 1px solid var(--grey-150);
    transform: translateZ(0);

    ${media.md} {
      left: 1.5rem;
      right: 1.5rem;
    }
  }
`;
