import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { Col, Row } from '../../../components/Grid';
import { InputField } from '../../../components/Fields';
import { Button } from '../../../components/Button';
import {
  stringSchema,
  stringSchemaOptional,
} from '../../../../utils/validation-schemas';

const validationSchema = Yup.object({
  id: stringSchemaOptional,
  title: stringSchema,
});

export type LessonValues = Yup.InferType<typeof validationSchema>;

interface Props {
  initialValues: LessonValues;
  onSubmit: (values: LessonValues) => Promise<void>;
  onCancel: () => void;
  submitLabel?: string;
}

export function LessonForm(props: Props) {
  const { t } = useTranslation();
  const { initialValues, onSubmit, onCancel, submitLabel } = props;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <InputField name="title" label={t('label.name')} autoFocus />
              </Col>
              <Col xs={12} gutterY={3}>
                <Row justifyContent="center">
                  <Col>
                    <Button border onClick={onCancel}>
                      {t('button.cancel')}
                    </Button>
                  </Col>
                  <Col>
                    <Button yellow type="submit" $loading={isSubmitting}>
                      {submitLabel ||
                        (initialValues.id
                          ? t('button.save')
                          : t('button.create'))}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
        );
      }}
    </Formik>
  );
}
