import React, { useContext, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSwiperSlide } from 'swiper/react';
import { RxDocument } from 'rxdb';
import styled from 'styled-components';
import { useRxCollection, useRxData } from 'rxdb-hooks';

import { Col, Row } from '../../../../../components/Grid';
import Rating from '../../../../../components/Rating';
import { media } from '../../../../../../styles/media';
import { GameContext } from '../../../GameContext';
import { FlashCardDocType } from '../../../../../../database/schemas/flashcard';
import { ScoreDocType } from '../../../../../../database/schemas/score';
import { LessonDocType } from '../../../../../../database/schemas/lesson';
import { CollectionName } from '../../../../../../database/types';
import { GameType } from '../../../../../../types/Game';

interface Props {
  flashcards: RxDocument<FlashCardDocType>[];
  lesson: RxDocument<LessonDocType>;
  gameType: GameType;
}

export const Result = (props: Props) => {
  const { flashcards, lesson, gameType } = props;
  const { correctAnswers, isRetry, setIsRetry } = useContext(GameContext);
  const { isVisible } = useSwiperSlide();
  const scoreCollection = useRxCollection<ScoreDocType>(CollectionName.Score);

  const {
    result: [score],
  } = useRxData<ScoreDocType>(CollectionName.Score, collection =>
    collection.findOne({
      selector: {
        lesson_id: lesson.id,
        type: gameType,
      },
      sort: [{ created_at: 'desc' }, { id: 'desc' }],
    }),
  );

  const { percent, rating } = useMemo(() => {
    const lessonFlashcards = flashcards.filter(
      flashcard => flashcard.lesson_id === lesson.id,
    );
    const correctFlashcards = lessonFlashcards.filter(
      flashcard => correctAnswers[flashcard.id],
    );

    const percent =
      Math.floor((correctFlashcards.length / lessonFlashcards.length) * 100) ||
      0;
    const rating = (correctFlashcards.length / lessonFlashcards.length) * 3;

    return { percent, rating };
  }, [isRetry]);

  useEffect(() => {
    if (isVisible) {
      if (score) {
        score.incrementalUpdate({
          $set: {
            score: percent,
          },
        });
      } else {
        scoreCollection?.insert({
          id: uuidv4(),
          lesson_id: lesson.id,
          type: gameType,
          score: percent,
        });
      }
    }
  }, [percent, score, scoreCollection, isVisible]);

  useEffect(() => {
    if (!isRetry && isVisible) {
      setIsRetry(true);
    }
  }, [isVisible]);

  return (
    <Col xs={12} alignSelf="center" gutterY={0.5} gutter={{ xs: 1, md: 2 }}>
      <LessonName>
        <Row>
          <Col xs="auto">
            <b>{lesson.title}</b>
          </Col>
          <Col>
            <b>{percent}%</b>
          </Col>
          <Col>
            <Rating rating={rating || 0} numberOfStars={3} />
          </Col>
        </Row>
      </LessonName>
    </Col>
  );
};

const LessonName = styled.div`
  padding: 0.5rem 0.25rem;
  ${media.md} {
    padding: 1rem 0.5rem;
  }
`;
