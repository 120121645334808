import { useAsync } from 'react-use';
import { useRxCollection } from 'rxdb-hooks';
import { FlashCardDocType } from '../../database/schemas/flashcard';
import { CollectionName } from '../../database/types';
import { LessonDocType } from '../../database/schemas/lesson';

type Props =
  | {
      classId: string;
      lessonId?: string;
    }
  | {
      classId?: string;
      lessonId: string;
    };

export function useBoardCount(props: Props) {
  const { classId, lessonId } = props;

  const boardsCollection = useRxCollection<FlashCardDocType>(
    CollectionName.Boards,
  );

  const lessonCollection = useRxCollection<LessonDocType>(
    CollectionName.Lessons,
  );

  const { value: count } = useAsync(async () => {
    let lessonsId = lessonId ? [lessonId] : [];

    if (classId && !lessonId && lessonCollection) {
      const lessonsQuery = await lessonCollection.find({
        selector: {
          class_id: classId,
        },
      });

      const lessons = await lessonsQuery.exec();

      lessonsId = lessons.map(lesson => lesson._data.id);
    }

    if (boardsCollection) {
      const query = boardsCollection.count({
        selector: {
          lesson_id: {
            $in: lessonsId,
          },
        },
      });

      const count = await query.exec();

      return count;
    }

    return undefined;
  }, [boardsCollection, lessonCollection, classId, lessonId]);

  return count || 0;
}
