import {
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  toTypedRxJsonSchema,
} from 'rxdb';

export const audioSchemaLiteral = {
  title: 'Audio Schema',
  description: '',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    lesson_id: {
      type: 'string',
      maxLength: 36,
    },
    src: {
      type: 'string',
      maxLength: 36,
    },
    title: {
      type: 'string',
    },
    shared: {
      type: 'boolean',
    },
    created_at: {
      type: 'string',
    },
    updated_at: {
      type: 'string',
    },
    deleted_at: {
      type: 'string',
    },
  },
  required: ['id', 'lesson_id', 'src', 'title'],
  indexes: ['lesson_id'],
} as const;

const schemaTyped = toTypedRxJsonSchema(audioSchemaLiteral);
export type AudioDocType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof schemaTyped
>;
export const audioSchema: RxJsonSchema<AudioDocType> = audioSchemaLiteral;
