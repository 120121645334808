import React, { useContext } from 'react';
import { useSwiper } from 'swiper/react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../../components/Button';
import { GameContext } from '../../../GameContext';

export const GameRetryButton = () => {
  const swiper = useSwiper();
  const { hideCorrectAnswers } = useContext(GameContext);
  const { t } = useTranslation();

  const onClick = () => {
    hideCorrectAnswers();

    swiper.slideTo(0);
  };

  return (
    <Button type="button" onClick={onClick} blue>
      {t('button.retryWrongCards')}
    </Button>
  );
};
