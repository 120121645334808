export enum QuestionType {
  Audio = 'audio',
  WebImage = 'web',
  Text = 'text',
  Spelling = 'spelling',
  Photo = 'photo',
}

export enum AnswerType {
  Text = 'text',
  MultipleChoice = 'multiplechoice',
}

export interface LessonAudio {
  id: number;
  title: string;
  src: string;
  lesson_id: number;
  lesson: Lesson;
  created_at: string;
  updated_at: string;
}

export interface LessonBoard {
  id: number;
  title: string;
  value: string;
  lesson_id: number;
  lesson: Lesson;
  created_at: string;
  updated_at: string;
}

export interface FlashcardFile {
  type: string;
  data: string;
}

export interface LessonFlashCard {
  id: number;
  lesson_id: number;
  lesson: Lesson;
  question_type: QuestionType;
  question_value: string | FlashcardFile;
  answer_type: AnswerType;
  answer_value: string | MultipleChoiceAnswer;
  created_at: string;
  updated_at: string;
}

export interface Lesson {
  id: string;
  title: string;
  class_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  score: number;
}

export interface MultipleChoiceAnswer {
  options: string[];
  correctIndex: number | number[];
}
