import React, { InputHTMLAttributes, Ref, useState } from 'react';
import { useField } from 'formik';
import { buttonIconStyles } from '../ButtonIcon';
import { Icon, IconWrapper } from '../Icon';
import styled, { css } from 'styled-components/macro';
import { useDropzone } from 'react-dropzone';
import { ifProp, prop } from 'styled-tools';
import { api } from '../../../services/api';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  inputRef?: Ref<HTMLInputElement>;
  background?: any;
}

export const FileField = (props: Props) => {
  const { name, label, inputRef } = props;
  const [field, meta, helpers] = useField(name);
  const [isLoading, setIsLoading] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'image/*': [],
    },
    onDrop: async acceptedFiles => {
      try {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('file', acceptedFiles[0], acceptedFiles[0].name);
        const response = await api.post('/file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data?.path) {
          helpers.setValue(response.data?.path);
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    },
  });

  return (
    <Button
      {...getRootProps()}
      backgroundImage={field.value}
      $loading={isLoading}
    >
      <input {...getInputProps()} />
      <Icon name="plus-1" />
    </Button>
  );
};

const Button = styled.div<{ backgroundImage?: string; $loading?: boolean }>`
  ${buttonIconStyles};
  padding: 1rem;
  border-radius: 100px;
  background: url(${prop('backgroundImage')});
  background-size: cover;
  background-position: center center;
  background-color: var(--grey-100);

  ${ifProp(
    'backgroundImage',
    css`
      ${IconWrapper} {
        opacity: 0;
        visibility: hidden;
      }
    `,
  )}

  ${ifProp(
    '$loading',
    css`
      background-image: none;
    `,
  )}
`;
