import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonIcon } from '../../../../components/ButtonIcon';
import { Block } from '../../../../components/Block';
import { Icon } from '../../../../components/Icon';
import { Modal } from '../../../../components/Modal';
import { Col, Row } from '../../../../components/Grid';
import { Heading } from '../../../../components/Heading';
import { Button } from '../../../../components/Button';

interface Props {
  onDelete: () => Promise<void>;
  title: string;
}

export function DeleteAudio(props: Props) {
  const { t } = useTranslation();
  const { onDelete, title } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onOpen = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    setIsOpen(true);
  }, []);

  const onCancel = useCallback(event => {
    event?.preventDefault();
    event?.stopPropagation();
    setIsOpen(false);
  }, []);

  const onConfirm = useCallback(async () => {
    setLoading(true);
    await onDelete();
    setLoading(false);
  }, []);

  return (
    <>
      <ButtonIcon dangerous onClick={onOpen} withPadding block>
        <Icon name="trash-4" />
      </ButtonIcon>
      <Modal
        width={20 * 16}
        isOpen={isOpen}
        onBackgroundClick={onCancel}
        onEscapeKeydown={onCancel}
      >
        <Row justifyContent="center">
          <Col xs={12}>
            <Heading level={3}>
              <Block textCenter>{t('confirm.delete', { name: title })}</Block>
            </Heading>
          </Col>
          <Col xs={12} gutterY={1.5}>
            <Row justifyContent="center">
              <Col>
                <Button border onClick={onCancel}>
                  {t('button.cancel')}
                </Button>
              </Col>
              <Col>
                <Button red $loading={loading} onClick={onConfirm}>
                  {t('button.delete')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
