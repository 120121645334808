import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../components/Button';
import { Col, Row } from '../../../components/Grid';
import { Icon } from '../../../components/Icon';
import { useClassImport } from '../../../hooks/useClassImport';

export function ImportClass() {
  const { t } = useTranslation();
  const { importClass, loading } = useClassImport();

  return (
    <Button white onClick={importClass} $loading={loading}>
      <Row gutter={0.5}>
        <Col gutter={0.5}>
          <Icon name="cloud-upload" />
        </Col>
        <Col gutter={0.5} display={{ xs: 'none', sm: 'block' }}>
          {t('button.upload')}
        </Col>
      </Row>
    </Button>
  );
}
