import {
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  toTypedRxJsonSchema,
} from 'rxdb';

export const mediaSchemaLiteral = {
  version: 0,
  title: 'Media Schema',
  description: '',
  primaryKey: 'path',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    mime: {
      type: 'string',
      maxLength: 36,
    },
    data: {
      type: 'string',
    },
    path: {
      type: 'string',
      maxLength: 255,
    },
    created_at: {
      type: 'string',
    },
    updated_at: {
      type: 'string',
    },
    deleted_at: {
      type: 'string',
    },
  },
  required: ['mime', 'data'],
  indexes: [],
} as const;

const schemaTyped = toTypedRxJsonSchema(mediaSchemaLiteral);
export type MediaDocType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof schemaTyped
>;
export const mediaSchema: RxJsonSchema<MediaDocType> = mediaSchemaLiteral;
