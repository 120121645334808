import {
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  toTypedRxJsonSchema,
} from 'rxdb';

export const boardSchemaLiteral = {
  version: 0,
  title: 'Board Schema',
  description: '',
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    lesson_id: {
      type: 'string',
      maxLength: 36,
    },
    shared: {
      type: 'boolean',
    },
    created_at: {
      type: 'string',
    },
    updated_at: {
      type: 'string',
    },
    deleted_at: {
      type: 'string',
    },
    data: {
      type: 'object',
      properties: {
        blocks: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              id: {
                type: 'string',
              },
              type: {
                type: 'string',
              },
              data: {
                type: 'object',
              },
            },
          },
        },
        time: {
          type: 'number',
        },
        version: {
          type: 'string',
        },
      },
    },
  },
  required: ['id', 'lesson_id', 'data'],
  indexes: [],
} as const;

const schemaTyped = toTypedRxJsonSchema(boardSchemaLiteral);
export type BoardDocType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof schemaTyped
>;
export const boardSchema: RxJsonSchema<BoardDocType> = boardSchemaLiteral;
