import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SwiperSlide } from 'swiper/react';
import { useRxCollection, useRxQuery } from 'rxdb-hooks';
import { RxDocument } from 'rxdb';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useTheme } from 'styled-components/macro';
import type { Swiper as SwiperClass } from 'swiper/types';

import { Col, Row } from '../../../components/Grid';
import { Swiper } from '../../../components/Swiper';
import { Navigation } from '../components/Navigation';
import { Button } from '../../../components/Button';
import { Icon } from '../../../components/Icon';
import { CollectionName } from '../../../../database/types';
import { FlashCardDocType } from '../../../../database/schemas/flashcard';
import { FlashCard } from './FlashCard';
import { RotateDevice } from '../../../components/RotateDevice';
import { SharedBlock } from '../../../components/SharedBlock';
import { Span } from '../../../components/Span';
import { StudyButton } from '../../../components/StudyButton';
import { FlashCardForm } from './FlashCardForm';
import { LimitReached } from './LimitReached';
import { useFlashcardsLimit } from '../../../hooks/useFlashcardsLimit';

export function FlashCardsPage() {
  const { t } = useTranslation();
  const { lessonId } = useParams<{ lessonId: string }>();
  const isLandscapeMd = useMediaQuery(
    useTheme()?.breakpoints.down('lg', 'landscape'),
  );
  const [activeIndex, setActiveIndex] = useState(0);
  const [newFlashcard, setNewFlashcard] = useState(false);
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [limitReached, setLimitReached] = useState(false);
  const { limit, checkIfLimitReached } = useFlashcardsLimit();

  const flashCardCollection = useRxCollection<FlashCardDocType>(
    CollectionName.FlashCards,
  );
  const flashcardsQuery = flashCardCollection?.find({
    selector: {
      lesson_id: lessonId,
    },
    sort: [{ created_at: 'desc' }, { id: 'desc' }],
  });

  const { result: flashcards, isFetching } =
    useRxQuery<FlashCardDocType>(flashcardsQuery);

  useEffect(() => {
    if (!isFetching && !flashcards?.length) {
      setNewFlashcard(true);
    }
  }, [isFetching]);

  const onDelete = useCallback((document: RxDocument<FlashCardDocType>) => {
    document?.remove();
  }, []);

  const onCreate = useCallback(async () => {
    const limitReached = await checkIfLimitReached();

    if (limitReached) {
      setLimitReached(true);
    } else {
      setNewFlashcard(true);
    }

    swiper?.slideTo(0);
  }, [!!swiper, flashCardCollection, limit]);

  const closeLimitModal = useCallback(() => {
    setLimitReached(false);
  }, []);

  const onCreateCancel = useCallback(() => {
    setNewFlashcard(false);
  }, []);

  const onSlideChange = (swiper: SwiperClass) => {
    setActiveIndex(swiper.activeIndex);
  };

  const onSwiper = (swiper: SwiperClass) => {
    setSwiper(swiper);
  };

  return (
    <>
      <Row flexDirection="column" height="100%">
        <Col>
          <Row gutter={2} alignItems="center">
            <Col xs="auto" gutter={2}>
              <Navigation />
            </Col>
            <Col gutter={2}>
              <Row>
                <Col>
                  <StudyButton
                    lessonsId={lessonId ? [lessonId] : undefined}
                    label={t('button.playLesson')}
                  />
                </Col>
                <Col>
                  <SharedBlock shared={flashcards[0]?.shared}>
                    <Button white block onClick={onCreate}>
                      <Row gutter={0.5}>
                        <Col gutter={0.5}>
                          <Span color="yellow">
                            <Icon name="plus-solid" />
                          </Span>
                        </Col>
                        <Col gutter={0.5} display={{ xs: 'none', sm: 'block' }}>
                          {t('button.newFlashcard')}
                        </Col>
                      </Row>
                    </Button>
                  </SharedBlock>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs="auto" gutterY={1}>
          <Row height="100%" flexDirection="column" alignContent="center">
            <Col xs={12} style={{ position: 'relative' }}>
              {isLandscapeMd ? (
                <RotateDevice />
              ) : (
                <Swiper onSlideChange={onSlideChange} onSwiper={onSwiper}>
                  {newFlashcard && (
                    <SwiperSlide>
                      <FlashCardForm onCancel={onCreateCancel} />
                    </SwiperSlide>
                  )}
                  {flashcards.map((flashcard, index) => {
                    const visible =
                      index >= activeIndex - 5 && index <= activeIndex + 5;

                    return (
                      <SwiperSlide key={flashcard.id}>
                        {visible && (
                          <FlashCard
                            flashcard={flashcard}
                            onDelete={onDelete}
                          />
                        )}
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {limitReached && (
        <SwiperSlide>
          <LimitReached onClose={closeLimitModal} />
        </SwiperSlide>
      )}
    </>
  );
}
