import React, { ChangeEvent, forwardRef, KeyboardEvent, useState } from 'react';
import AutosizeInput from 'react-input-autosize';
import styled from 'styled-components/macro';
import { useField } from 'formik';
import { ifProp } from 'styled-tools';

interface Props {
  name: string;
  placeholder: string;
  onFilled: (event: ChangeEvent<HTMLInputElement>) => void;
  onCleaned: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export const AnswerInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { placeholder, onFilled, onCleaned, name, onKeyDown, disabled } = props;
  const [field, meta, helpers] = useField(name);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

  const onChange = event => {
    const keyCode = event?.target?.value?.charCodeAt(
      event?.target?.value?.length - 1,
    );

    if (keyCode === 32) {
      event.preventDefault();
      onFilled(event);
    }

    !event.defaultPrevented && helpers.setValue(event.target.value);
  };

  const onKeyDownHandler = event => {
    let keyCode = event.keyCode || event.which;
    if (keyCode === 0 || keyCode === 229) {
      keyCode = event?.target?.value?.charCodeAt(
        event?.target?.value?.length - 1,
      );
    }

    if (keyCode === 8 && field.value.length <= 0) {
      onCleaned(event);
    }

    onKeyDown && onKeyDown(event);
  };

  const onFocus = () => {
    setIsInputFocused(true);
  };

  const onBlur = () => {
    setIsInputFocused(false);
  };

  return (
    <StyledInput
      autocapitalize="off"
      autocomplete="off"
      spellcheck="false"
      autocorrect="off"
      inputmode="text"
      value={field.value}
      placeholder={placeholder}
      onChange={onChange}
      inputRef={ref}
      onKeyDown={onKeyDownHandler}
      disabled={disabled}
      onFocus={onFocus}
      onBlur={onBlur}
      $isInputFocused={isInputFocused}
      placeholderIsMinWidth
    />
  );
});

const StyledInput = styled(AutosizeInput)<{ $isInputFocused: boolean }>`
  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background: ${ifProp('$isInputFocused', 'var(--yellow-100)', '#fff')};
  }

  input {
    position: relative;
    padding: 0;
    max-width: 300px;
    color: #fff;
    background: none;
    font-size: 24px;
    line-height: 36px;
    border: none;
    outline: none;
    font-family: var(--font-monospace);
    font-weight: normal;
    letter-spacing: 3px;
    text-align: center;

    &::placeholder {
      color: transparent;
      opacity: 0;
      visibility: hidden;
    }
  }
`;
