import { replicateRxCollection } from 'rxdb/plugins/replication';
import axios from 'axios';

import { MediaDocType } from './schema';
import { api } from '../../../services/api';
import { Media } from '../../../types/Media';
import { ReplicationPullHandlerResult } from 'rxdb/dist/types/types/plugins/replication';
import {
  base64ToBlob,
  blobToBase64,
  blobToFormData,
} from '../../../utils/file-manipulatiion';
import { generateMediaName } from '../../../utils/path-generation';

type Checkpoint = Pick<MediaDocType, 'id' | 'updated_at'>;

export async function mediaReplication(collection) {
  return replicateRxCollection<MediaDocType, Checkpoint>({
    collection,
    replicationIdentifier: `${process.env.REACT_APP_API_URL}/media`,
    autoStart: true,
    live: true,
    deletedField: 'deleted_at',
    push: {
      async handler(docs) {
        try {
          const { id, data, mime } = docs[0].newDocumentState;
          const blob = await base64ToBlob(data, mime);

          const mediaFormData = await blobToFormData(
            blob,
            generateMediaName(id as string, mime),
          );
          mediaFormData.append('id', id as string);

          await api.post<Media>('/file', mediaFormData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          return [];
        } catch (error) {
          console.error(error);
        }

        throw new Error('ERROR');

        return [];
      },
      batchSize: 1,
    },
    pull: {
      async handler(
        lastCheckpoint,
        batchSize,
      ): Promise<ReplicationPullHandlerResult<MediaDocType, Checkpoint>> {
        const minTimestamp = lastCheckpoint
          ? lastCheckpoint?.updated_at || new Date(0)
          : new Date(0);

        const response = await api.get(`/file/sync`, {
          params: {
            last_checkpoint: minTimestamp,
            batchSize,
          },
        });
        const document = response.data[0];

        if (!document) {
          return {
            documents: [],
            checkpoint: lastCheckpoint,
          };
        }

        const checkpoint = {
          id: document.id,
          updated_at: document.updated_at,
        };

        try {
          const file = await axios.get(document.path, {
            responseType: 'blob',
          });

          const base64Data = await blobToBase64(file.data);

          const documents = [
            {
              ...document,
              path: document.path,
              type: document.path.split(/[#?]/)[0].split('.').pop().trim(),
              data: base64Data,
            },
          ];

          return {
            documents,
            checkpoint,
          };
        } catch (error) {
          return {
            documents: [
              {
                ...document,
                deleted_at: new Date().toISOString(),
              },
            ],
            checkpoint,
          };
        }
      },
      modifier: document => ({
        ...document,
        shared: false,
      }),
      batchSize: 1,
    },
  });
}
