import React, { PropsWithChildren, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useRxData } from 'rxdb-hooks';
import { Outlet, useParams } from 'react-router-dom';

import { CollectionName } from '../../../../database/types';
import { LessonDocType } from '../../../../database/schemas/lesson';
import { LessonHeader } from './LessonHeader';
import { PageWrapper } from '../../../components/PageWrapper';
import { Container } from '../../../components/Container';
import { LessonSidebar } from './LessonSidebar';

export function LessonLayout(props: PropsWithChildren) {
  const { lessonId } = useParams<{ lessonId: string }>();

  const query = useMemo(
    () => collection => collection.findOne(lessonId),
    [lessonId],
  );

  const {
    result: [lesson],
  } = useRxData<LessonDocType>(CollectionName.Lessons, query);

  return (
    <>
      <Helmet>
        <title>{lesson?.title}</title>
      </Helmet>
      <LessonHeader title={lesson?.title} classId={lesson?.class_id} />
      <LessonSidebar lesson={lesson} />
      <PageWrapper style={{ overflow: 'hidden' }} withSidebar>
        <Container>
          <Outlet />
        </Container>
      </PageWrapper>
    </>
  );
}
