import {
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  toTypedRxJsonSchema,
} from 'rxdb';
import { AnswerType, QuestionType } from '../../../types/Lesson';

export const flashcardSchemaLiteral = {
  title: 'Flashcard Schema',
  description: '',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    lesson_id: {
      type: 'string',
      maxLength: 36,
    },
    question_type: {
      type: 'string',
      enum: [
        QuestionType.Audio,
        QuestionType.WebImage,
        QuestionType.Text,
        QuestionType.Spelling,
        QuestionType.Photo,
      ],
    },
    question_value: {
      type: 'string',
    },
    answer_type: {
      type: 'string',
      enum: [AnswerType.Text, AnswerType.MultipleChoice],
    },
    answer_value: {
      type: 'string',
    },
    shared: {
      type: 'boolean',
    },
    created_at: {
      type: 'string',
    },
    updated_at: {
      type: 'string',
    },
    deleted_at: {
      type: 'string',
    },
  },
  required: [
    'id',
    'lesson_id',
    'question_type',
    'question_value',
    'answer_type',
    'answer_value',
  ],
  indexes: ['lesson_id'],
} as const;

const schemaTyped = toTypedRxJsonSchema(flashcardSchemaLiteral);
export type FlashCardDocType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof schemaTyped
>;
export const flashcardSchema: RxJsonSchema<FlashCardDocType> =
  flashcardSchemaLiteral;
