import { Swiper as SwiperOrigin } from 'swiper/react';
import styled from 'styled-components/macro';
import { EffectCards, Keyboard, Navigation, Pagination } from 'swiper/modules';
import { PropsWithChildren } from 'react';
import type { Swiper as SwiperClass } from 'swiper/types';

import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { media } from '../../../styles/media';

interface Props {
  onSlideChange?: (swiper: SwiperClass) => void;
  onSwiper?: (swiper: SwiperClass) => void;
}

export function Swiper(props: PropsWithChildren<Props>) {
  const { children, onSlideChange, onSwiper } = props;

  return (
    <Wrapper>
      <svg viewBox="0 0 50 70" height="100%" />
      <SwiperOrigin
        onSwiper={onSwiper}
        effect="cards"
        modules={[Navigation, Pagination, EffectCards, Keyboard]}
        cardsEffect={{
          perSlideRotate: 2,
          perSlideOffset: 4,
        }}
        keyboard={{
          enabled: true,
        }}
        navigation
        pagination={{ type: 'fraction' }}
        onSlideChange={onSlideChange}
      >
        {children}
      </SwiperOrigin>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: calc(100% - 2rem);
  position: relative;

  ${media.md} {
    height: calc(100% - 2rem);
  }

  .swiper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .swiper-slide {
    left: 0 !important;
  }

  .swiper-slide-shadow {
    border-radius: 0.5rem;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    color: var(--white);
    display: none;

    ${media.md} {
      display: flex;
    }

    &:hover {
      color: var(--yellow-100);
    }

    &:after {
      font-size: 2rem;
    }

    &.swiper-button-disabled {
      display: none;
    }
  }

  .swiper-button-prev {
    left: -6rem;
  }

  .swiper-button-next {
    right: -6rem;
  }

  .swiper-pagination {
    top: 100%;
    color: var(--white);
    margin-top: 1rem;
    line-height: 1rem;

    ${media.md} {
      margin-top: 1.25rem;
    }
  }
`;
