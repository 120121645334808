export function downloadCsv(csv, filename) {
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', filename);

  // Append anchor to body and trigger click
  document.body.appendChild(a);
  a.click();

  // Clean up
  document.body.removeChild(a);
}
