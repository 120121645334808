import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components/macro';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';

import { Col, Row } from '../../../components/Grid';
import { Heading } from '../../../components/Heading';
import { Label } from '../../../components/Label';
import { Select } from '../../../components/Select';
import { translationOptions } from '../../../../locales/i18n';
import { Card } from '../../../components/Card';
import { media } from '../../../../styles/media';

export const SettingsCard = () => {
  const isMd = useMediaQuery(useTheme()?.breakpoints.up('md'));
  const { t, i18n } = useTranslation();

  const value = translationOptions.find(({ value }) => value === i18n.language);

  const handleChange = selectedOption => {
    i18n.changeLanguage(selectedOption.value);
  };

  return (
    <Card padding={isMd ? 2 : 1.5}>
      <Row>
        <Col xs={12}>
          <Heading level={3}>{t('title.settings')}</Heading>
        </Col>
        <Col xs={12} gutterY={1.5}>
          <Settings>
            <div>
              <Label htmlFor="name">{t('label.language')}</Label>
            </div>
            <div>
              <Select
                options={translationOptions}
                onChange={handleChange}
                value={value}
              />
            </div>
          </Settings>
        </Col>
      </Row>
    </Card>
  );
};

const Settings = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto auto;
  gap: 0 1rem;
  grid-template-areas:
    '. .'
    '. .'
    '. .';

  ${media.md} {
    gap: 0 1.5rem;
  }

  & > div {
    align-self: center;
  }
`;
