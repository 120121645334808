import React from 'react';
import { Route, Routes as RoutesOrigin } from 'react-router-dom';

import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';

import { AuthPage } from '../pages/AuthPage';
import { HomePage } from '../pages/HomePage';
import { NotFoundPage } from '../pages/NotFoundPage';
import { AccountPage } from '../pages/AccountPage';
import { ClassPage } from '../pages/ClassPage';
import { LessonLayout } from '../pages/Lesson/LessonLayout';
import { FlashCardsPage } from '../pages/Lesson/FlashCardsPage';
import { AudioPage } from '../pages/Lesson/AudioPage';
import { BoardsPage } from '../pages/Lesson/BoardsPage';
import { TempPage } from '../pages/Temp';
import { GamesPage } from '../pages/GamesPage';
import { GamePage } from '../pages/GamePage';
import { SharePage } from '../pages/SharePage';
import { PricingPage } from '../pages/PricingPage';

export const Routes = () => {
  return (
    <RoutesOrigin>
      <Route element={<PublicRoute />}>
        <Route path="/auth/*" element={<AuthPage />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route index element={<HomePage />} />
        <Route path="class/:classId" element={<ClassPage />} />
        <Route path="play" element={<GamesPage />} />
        <Route path="play/:gameType/:lessonsId" element={<GamePage />} />
        <Route path="lesson/:lessonId" element={<LessonLayout />}>
          <Route path="flashcards" element={<FlashCardsPage />} />
          <Route path="audio" element={<AudioPage />} />
          <Route path="boards" element={<BoardsPage />} />
        </Route>
        <Route path="temp" element={<TempPage />} />
        <Route path="/share/:entry/:token" element={<SharePage />} />
        <Route path="account" element={<AccountPage />} />
        <Route path={'/pricing'} element={<PricingPage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </RoutesOrigin>
  );
};
