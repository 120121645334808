import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { Header } from '../Header';
import { media } from '../../../styles/media';

export const Container = styled.div<{ $fixed?: boolean }>`
  margin: 0 auto;
  padding: 0 1rem;
  width: 100%;

  &:after {
    content: '';
    display: block;
    height: 1rem;

    ${media.md} {
      height: 2rem;
    }
  }

  ${Header} &:after {
    content: none;
  }

  ${ifProp(
    '$fixed',
    css`
      max-width: 82rem;
    `,
  )}
`;
