import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import FontFaceObserver from 'fontfaceobserver';
import { useSearchParams } from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';

import { Routes } from './routes';
import { DatabaseProvider } from '../database/Provider';
import { useEffect } from 'react';

const fontObserver = new FontFaceObserver('Inter', {});

fontObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

export function App() {
  const { i18n } = useTranslation();
  let [searchParams] = useSearchParams();

  useEffect(() => {
    const lang = searchParams.get('lang');

    if (lang && i18n.language !== lang) {
      i18n.changeLanguage(lang);
    }
  }, []);

  return (
    <>
      <Helmet
        titleTemplate="%s – ClassMaster.io"
        defaultTitle="ClassMaster.io allows you to record classes, make flashcards and notes all in the same place"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta
          name="description"
          content="Master each lesson as you study towards top grades and results. Drop the paper and pen and use the brand new learning tool today."
        />
      </Helmet>
      <DatabaseProvider>
        <Routes />
      </DatabaseProvider>
      <GlobalStyle />
    </>
  );
}
