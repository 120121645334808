import React, { useContext, useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import styled from 'styled-components/macro';

import { Col, Row } from '../../../components/Grid';
import { InputField } from '../../../components/Fields';
import { Button } from '../../../components/Button';
import { ReactComponent as GoogleLogo } from './assets/google.svg';
import { AuthContext } from '../../../context/AuthContext';
import { ErrorMessage } from '../../../components/ErrorMessage';
import { Heading } from '../../../components/Heading';
import { Logo } from '../../../components/Logo';
import { media } from '../../../../styles/media';

const LoginSchema = Yup.object().shape({
  email: Yup.string().required().email(),
});

const initialValues = {
  email: '',
};

export function Login(props) {
  const { t } = useTranslation();
  const { sendSignInLinkToEmail } = useContext(AuthContext);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<null | string>(null);

  const onSubmit = async ({ email }) => {
    try {
      await sendSignInLinkToEmail(email);
      setSuccess(true);
    } catch (error) {
      setError(t('message.getWrong'));
    }
  };

  return (
    <Wrapper gutter={0}>
      <Col gutter={0} xs={12} lg={undefined}>
        <Hero>
          <Row
            height="100%"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Col>
              <Logo />
            </Col>
            <Col gutterY={{ xs: 4, lg: 2 }}>
              <Row>
                <Col xs={12}>
                  <HeroTitle>{t('title.greeting')}</HeroTitle>
                </Col>
                <Col xs={12} gutterY={{ xs: 1.5, lg: 2.5 }}>
                  <HeroMessage>{t('message.greeting')}</HeroMessage>
                </Col>
              </Row>
            </Col>
            <Col gutterY={{ xs: 4, lg: 4 }} />
          </Row>
        </Hero>
      </Col>
      <Col gutter={0} xs={12} lg="auto">
        <Row height="100%" justifyContent="center" alignItems="center">
          <Col>
            <FormWrapper>
              {success ? (
                <SuccessMessage level={2} weight={500}>
                  {t('message.loginEmailSent')}
                </SuccessMessage>
              ) : (
                <Formik
                  onSubmit={onSubmit}
                  initialValues={initialValues}
                  validationSchema={LoginSchema}
                >
                  {({ handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <Col xs={12}>
                          <InputField
                            type="email"
                            name="email"
                            placeholder={t('label.email')}
                            autoFocus
                          />
                        </Col>
                        <Col xs={12} gutterY={1.5}>
                          <Button
                            type="submit"
                            yellow
                            block
                            $loading={isSubmitting}
                          >
                            {t('button.sendSignInLink')}
                          </Button>
                        </Col>
                        <Col xs={12} gutterY={1.25}>
                          <Or>{t('label.or')}</Or>
                        </Col>
                        <Col xs={12} gutterY={1.25}>
                          <GoogleLink
                            href={`${process.env.REACT_APP_API_URL}/auth/google`}
                            block
                            iconStart
                          >
                            <GoogleLogo />
                            {t('button.signInWithGoogle')}
                          </GoogleLink>
                        </Col>
                        <Col xs={12} gutterY={1.25}>
                          <ErrorMessage textAlign="center">
                            {error}
                          </ErrorMessage>
                        </Col>
                      </Row>
                    </form>
                  )}
                </Formik>
              )}
            </FormWrapper>
          </Col>
        </Row>
      </Col>
    </Wrapper>
  );
}

const Or = styled.div`
  text-align: center;
  text-transform: lowercase;
`;

const GoogleLink = styled(Button)`
  color: var(--black);
  border: 2px solid var(--black);

  &[disabled] {
    opacity: 0.2;
  }
`;

const SuccessMessage = styled(Heading)`
  color: var(--blue-100);
  text-align: center;
`;

const Wrapper = styled(Row)`
  background: var(--white);
  height: 100vh;
  overflow-x: hidden;
  width: 100%;

  /*& > ${Col} {
    min-height: 50vh;
  }*/
`;

const Hero = styled.div`
  background-color: var(--blue-100);
  color: var(--white);
  text-align: center;
  padding: 1rem;
  height: 100%;

  ${media.lg} {
    padding: 2rem;
    height: 100vh;
    max-width: 30rem;
  }

  ${media.xl} {
    max-width: 40rem;
  }
`;

const HeroTitle = styled.div`
  font-size: 3rem;
  line-height: 3rem;
  font-family: var(--font-secondary);
  font-weight: bold;
  white-space: pre-wrap;

  ${media.md} {
    font-size: 4rem;
    line-height: 4rem;
  }

  ${media.xl} {
    font-size: 5rem;
    line-height: 5rem;
  }
`;

const HeroMessage = styled.div`
  font-size: 1.25rem;
  line-height: 1.5rem;
`;

const FormWrapper = styled.div`
  width: 24rem;
  max-width: 100%;
  padding: 2rem;
`;
