import styled, { css } from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import { keyframes } from 'styled-components';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface ButtonIconProps {
  $loading?: boolean;
  block?: boolean;
  to?: string;
  href?: string;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent) => any;
  type?: 'button' | 'reset' | 'submit';
  disabled?: boolean;
  dangerous?: boolean;
  target?: string;
  withPadding?: boolean;
}

const spinKeyframe = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const defaultProps = {
  withPadding: false,
};

export const ButtonIcon = (props: ButtonIconProps & typeof defaultProps) => {
  const { to, href } = props;

  if (to) return <StyledLinkIcon {...props} />;
  if (href) return <StyledAnchorIcon rel="noreferrer" {...props} />;
  return <StyledButtonIcon {...props} />;
};

ButtonIcon.defaultProps = defaultProps;

export const buttonIconStyles = css`
  border: none;
  cursor: pointer;
  padding: ${ifProp('withPadding', '10px', 0)};
  margin: 0;
  line-height: 0;
  color: ${ifProp('$loading', 'transparent', 'var(--grey-300)')};
  pointer-events: ${ifProp('$loading', 'none', 'auto')};
  position: relative;
  display: ${ifProp('block', 'block', 'inline-block')};

  &:hover {
    color: var(--blue-400);
    //transition: color 0.2s;
  }

  ${ifProp(
    'disabled',
    css`
      color: var(--grey-150);
      pointer-events: none;
    `,
    '',
  )}

  ${ifProp(
    'dangerous',
    css`
      &:hover {
        color: var(--red);
      }
    `,
  )}

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  &:after {
    ${ifProp(
      '$loading',
      css`
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: solid 2px rgba(var(--grey-300-rgb), 0.2);
        border-top-color: var(--grey-300);
        animation: ${spinKeyframe} 1s infinite linear;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -10px 0 0 -10px;
      `,
    )}
  }
`;

export const StyledLinkIcon = styled(({ disabled, height, ...props }) => (
  <Link {...props} />
))`
  ${buttonIconStyles}
`;

export const StyledAnchorIcon = styled.a`
  ${buttonIconStyles}
`;

export const StyledButtonIcon = styled.button<ButtonIconProps>`
  ${buttonIconStyles}
`;
