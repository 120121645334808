import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { FieldConfig, useField } from 'formik';
import styled from 'styled-components/macro';
import { FieldArrayRenderProps } from 'formik/dist/FieldArray';

import { media } from '../../../../../../../styles/media';
import { CheckboxField } from '../../../../../../components/Fields';
import { Col, Row } from '../../../../../../components/Grid';
import { useTranslation } from 'react-i18next';
import { ButtonIcon } from '../../../../../../components/ButtonIcon';
import { Icon } from '../../../../../../components/Icon';
import { TextAreaField } from '../../../../../../components/Fields/TextAreaField';
import { Textarea } from '../../../../../../components/Textarea';

interface Props extends FieldConfig, FieldArrayRenderProps {
  disabled?: boolean;
  index: number;
}

export const MultipleChoiceItemField = (props: Props) => {
  const { disabled, name, index, form, push, remove } = props;
  const { t } = useTranslation();
  const [field, meta, helpers] = useField<{
    correctIndex: number[];
    options: string[];
  }>(name);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const focusImmediately = useMemo(
    () => document.activeElement?.tagName === 'TEXTAREA',
    [],
  );

  useEffect(() => {
    if (inputRef?.current) {
      setTimeout(
        () => {
          inputRef?.current?.focus();
        },
        focusImmediately ? 0 : 250,
      );
    }
  }, []);

  const onInputFieldKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        push('');
      }
    },
    [],
  );

  const onDeleteClick = useCallback(
    (event: React.MouseEvent) => {
      remove(index);
    },
    [index],
  );

  return (
    <Wrapper>
      <Row gutter={0.5}>
        <Col gutter={0.5} gutterY={{ xs: 0.375, md: 0.875 }}>
          <CheckboxField name={`${name}.correctIndex`} value={index} />
        </Col>
        <Col xs="auto" gutter={0}>
          <TextAreaField
            ref={inputRef}
            name={`${name}.options[${index}]`}
            placeholder={t('label.answerOption')}
            onKeyDown={onInputFieldKeyDown}
          />
        </Col>
        <Col gutter={0.5} gutterY={{ xs: 0.275, md: 0.875 }}>
          <ButtonIcon
            withPadding
            dangerous
            type="button"
            onClick={onDeleteClick}
            disabled={meta.value.options.length < 2}
          >
            <Icon name="trash-4" />
          </ButtonIcon>
        </Col>
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: block;
  position: relative;
  cursor: pointer;
  margin-top: -1px;
  padding: 0 0.5rem;

  ${media.md} {
    padding: 0 1rem;
  }

  &:before {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 1rem;
    right: 1rem;
    border-bottom: 1px solid var(--grey-150);
    transform: translateZ(0);

    ${media.md} {
      left: 1.5rem;
      right: 1.5rem;
    }
  }

  ${Textarea} {
    background: none !important;
    border: none !important;
    border-radius: 0;
    padding: 1rem 0;
    resize: none;

    ${media.md} {
      padding: 1.5rem 0;
    }
  }
`;
